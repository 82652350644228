import React, {useState} from "react";
import styles from "../Company.module.scss";
import close from '../../../../assets/img/close.svg';
import AddCompanyForm from "./AddCompanyForm";

interface AddCompanyProps {
    showAddCompany: boolean
    setShowAddCompany: (setShowAddCompany: boolean) => void
}


const AddCompany = ({showAddCompany, setShowAddCompany}: AddCompanyProps) => {

    showAddCompany ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    return (
        <div className={styles.addCompanyWrapper}>
            <div className={styles.addCompany}>
                <button type='button' className={styles.addCompany__closer}
                        onClick={() => setShowAddCompany(false)}>
                    <img src={close} alt=""/>
                </button>
                <h3>Add Company</h3>
                <AddCompanyForm />
            </div>
        </div>
    )
}

export default AddCompany;