import styles from "./ReviewSlider.module.scss";
import React from "react";
import {review} from "../../../../store/main-slice.types";
import quotes from '../../../../assets/img/Quotes.svg'
import './ReviewSlide.css'


const ReviewSlide = ({photo, name, text, date}: review) => {
    return (
        <div className={styles.slideWrapper}>
            <div className={`${styles.slideBox} slideBox`}>
                <img className={styles.slideBox__photo} src={photo} alt=""/>
                <img className={styles.slideBox__quotes} src={quotes} alt=""/>
                <p className={styles.slideBox__text}>{text}</p>
                <h5 className={styles.slideBox__name}>{name}</h5>
                <span className={styles.slideBox__subText}>Content manage at Avivi</span>
            </div>
        </div>
    )
}

export default ReviewSlide;