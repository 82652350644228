import { RootState } from './store';
import { createSelector } from '@reduxjs/toolkit';

// export const getBlogById = createSelector(
//     [(state: RootState) => state.blogs.blogs, (state, id: string | undefined) => id],
//     (blogs, id) => blogs.find((blog) => id && blog.code === id)
// );



export const getPostById = createSelector(
    [(state: RootState) => state.posts.posts, (state, id: number) => id],
    (posts, id) => posts.find((post) => id && post.id === id)
);

export const getNavigation = (state: RootState) => state.main.navigation;
export const getSocials = (state: RootState) => state.main.socialLinks;
export const getSocialNetworks = (state: RootState) => state.main.socialNetworks;
export const getMySocialNetworks = (state: RootState) => state.main.mySocialNetworks;
export const getSocialRating = (state: RootState) => state.main.socRatingPosts;
export const getTariffs = (state: RootState) => state.main.tariffs;
export const getReviews = (state: RootState) => state.main.reviews;
export const getIsAuth = (state: RootState) => state.main.isAuth;
export const getIsNotification = (state: RootState) => state.main.notification;
export const getIsTwoFactorAuthentication = (state: RootState) => state.main.twoFactorAuthentication;
export const getConnectedSocialNetworks = (state: RootState) => state.main.connectedNetworks;
export const getStats = (state: RootState) => state.main.stats;

export const getBlogs = (state: RootState) => state.blogs.blogs;
export const getBlog = (state: RootState) => state.blogs.blogs;
export const getHomeBlogs = (state: RootState) => state.blogs.homeBlogs;
export const getPostBlogs = (state: RootState) => state.blogs.postBlogs;
export const getSize = (state: RootState) => state.main.size;
export const getPostsDay = (state: RootState) => state.main.postsDay;
export const getPostsWeek = (state: RootState) => state.main.postsWeek;
export const getPostsMonth = (state: RootState) => state.main.postsMonth;
export const getPostsYear = (state: RootState) => state.main.postsYear;
export const getTimeZones = (state: RootState) => state.main.timeZones;
export const getCompanies = (state: RootState) => state.main.companies;
export const getAllPosts = (state: RootState) => state.posts.posts;
export const getCalendar = (state: RootState) => state.calendar.calendar;
export const getCalendarDate = (state: RootState) => state.calendar.date;
export const getDateForPost = (state: RootState) => state.calendar.dateForPost;

export const getBasePictureSrc = 'https://dev.api.socialposting.pp.ua'