import React from "react";
import styles from './ContentInfo.module.scss'
import logo from "../../../../../assets/img/Logo.svg";
import {useNavigate} from "react-router-dom";
import Logo from "../../../Header/Navigation/Logo";


const ContentInfo = () => {
    const text = 'There should be a short two-line slogan'

    return (
        <div className={styles.infoWrapper}>
            <Logo />
            <p className={styles.text}>{text}</p>
        </div>
    )
}

export default ContentInfo;