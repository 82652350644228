import React from "react";
import './ReviewsContent.css';
import reviewsBG from '../../../../assets/img/reviewBG.svg'
import Review from "./Review/Review";
import {useAppSelector} from "../../../../hooks/redux";
import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/scss';
import {getReviews} from "../../../../store/selectors";

const ReviewsContent = () => {
    const reviews = useAppSelector(getReviews);
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    return (
        <div style={{ backgroundImage: `url(${reviewsBG})` }} className='contentWrapper review-slider'>
            <div className="slide-wrapper">
                <Swiper
                    modules={[Autoplay, Navigation]}
                    spaceBetween={0}
                    slidesPerView={2}
                    style={{
                        "padding": "28px 0 0 16px",
                        "maxWidth": '616px',
                        "height": "100%",
                    }}
                    autoplay={{
                        delay: 50000,
                        disableOnInteraction: false
                    }}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper: any) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                >
                    {reviews.map((review, index) => <SwiperSlide key={index}>
                        <Review photo={review.photo} name={review.name} date={review.date} text={review.text}/>
                    </SwiperSlide>)}
                    <button className='buttonPrev' ref={navigationPrevRef} />
                    <button className='buttonNext' ref={navigationNextRef} />
                </Swiper>
            </div>
        </div>
    )
}

export default ReviewsContent;