import styles from './ConnectsAccounts.module.scss'
import React, {useState} from "react";
import close from "../../../../assets/img/close.svg";
import {useFormik} from "formik";
import {connectTelegram, getConnectedNetworks, implementTelegram2FA} from "../../../../api/api";
import {getAccessToken} from "../../../../api/localStorage";
import {useAppDispatch} from "../../../../hooks/redux";
import {setSocialNetworks} from '../../../../store/main-slice';

interface TwitterConnectProps {
    setIsCreate: (setIsCreate: boolean) => void,
    setConnectedNetwork: (setConnectedNetwork: string | null) => void,
    setSocialsSelected: (setSocialsSelected: string) => void
}

const TelegramConnect = ({setConnectedNetwork, setIsCreate, setSocialsSelected}: TwitterConnectProps) => {
    const dispatch = useAppDispatch();
    const [showCode, setShowCode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [disabledCode, setDisabledCode] = useState(false);
    const [connectionError, setConnectionError] = useState({message: ""});
    const [incorrectNumber, setIncorrectNumber] = useState(false);


    const {setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm} = useFormik({
        initialValues: {
            name: '',
            phone: '+380',
            code: '',
            password: ''
        },

        onSubmit: (values) => {
            if (showCode) {
                getAccessToken().then((accessToken) => {
                    if (accessToken) {
                        if (!showPassword) {
                            console.log('stage 2 - telegram code')
                            connectTelegram(accessToken, values.name, values.phone, values.code).then(({status, data}) => {
                                if (data) {
                                    console.log(data);
                                    if (status === 201) {
                                        console.log('1FA');
                                        getConnectedNetworks(accessToken).then((response) => {
                                            dispatch(setSocialNetworks(response));
                                            setSocialsSelected('');
                                        })
                                    } else if (status === 200) {
                                        console.log('2FA');
                                        setShowPassword(true);
                                    } else {
                                        values.code = '';
                                        if (status === 400 && data.result === 'Invalid code.') {
                                            setDisabledCode(true);
                                        } else if (status === 401 && data.result === 'Such a Telegram account already exists') {
                                            setConnectionError({message: "Sorry, such a Telegram account already exists"});
                                        } else {
                                            setConnectionError({message: "Sorry, a connection error occurred!"});
                                        }
                                        console.log('status' + status);
                                    }
                                }
                            })
                        } else {
                            console.log('stage 3 - only for 2FA - telegram password');
                            implementTelegram2FA(accessToken, values.name, values.phone, values.password).then(({status, data}) => {
                                if (data) {
                                    console.log(data);
                                    if (status === 200 || status === 201) {getConnectedNetworks(accessToken).then((response) => {
                                        dispatch(setSocialNetworks(response));
                                        setSocialsSelected('');
                                    })
                                } else {
                                        setConnectionError({message: "Sorry, a connection error occurred!"});
                                    }
                                }
                            })
                        }
                    }
                })
            } else if (!showCode) {
                console.log('stage 1 - name & phone')
                getAccessToken().then((accessToken) => {
                    if (accessToken) {
                        connectTelegram(accessToken, values.name, values.phone, null).then(({data, status}) => {
                            if (data) {
                                console.log(data);
                                if (status === 200 || status === 201) {
                                    setShowCode(true);
                                } else if (status === 401 && data.result === 'Such a Telegram account already exists') {
                                    setConnectionError({message: "Such a Telegram account already exists"});
                                } else if (status === 400 && data.result === 'Invalid phone number provided.') {
                                    setIncorrectNumber(true);
                                } else {
                                    setConnectionError({message: "Sorry, a connection error occurred!"});
                                }
                            }
                        })
                    }
                })
            }
        }
    });

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (disabledCode) {
            setDisabledCode(false);
        }
        if (connectionError.message) {
            setConnectionError({message: ""});
        }
        handleChange(event);
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (incorrectNumber) {
            setIncorrectNumber(false);
        }
        if (connectionError.message) {
            setConnectionError({message: ""});
        }
        let value = event.target.value;
        if (/^[0-9+\s]*$/.test(value) || value === "") {
            handleChange(event);
        }
    };

    return (
        <div className={styles.twitterConnect}>
            <button type='button' className={styles.closer}
                    onClick={() => setIsCreate(false)}>
                <img src={close} alt=""/>
            </button>
            <h2 className={styles.twitterConnect__title}>Let's connect your profile</h2>
            <p className={styles.twitterConnect__subtitle}>Some text for connect in Telegram</p>
            <form className={styles.twitterConnect__form} onSubmit={handleSubmit}>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`name`}>Name</label>
                    <input
                        className={styles.field__input}
                        id="name"
                        name="name"
                        type="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder={'Name'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`consumer_key`}>Phone</label>
                    <input
                        className={`${styles.field__input} ${incorrectNumber ? styles.disabledField : ''}`}
                        id="phone"
                        name="phone"
                        type="phone"
                        onChange={handleNumberChange}
                        value={values.phone}
                        placeholder={'Phone'}
                    />
                </div>
                {showCode && (
                    <div className={styles.field}>
                        <label className={styles.field__label} htmlFor={`consumer_key`}>Code</label>
                        <input
                            className={`${styles.field__input} ${disabledCode ? styles.disabledField : ''}`}
                            id="code"
                            name="code"
                            type="number"
                            onChange={handleCodeChange}
                            value={values.code}
                            placeholder={'Code'}
                        />
                    </div>
                )}
                {connectionError.message &&
                    <div className={`${styles.field} ${styles.warning}`}><p>{connectionError.message}</p></div>}
                {showPassword && (
                    <div className={styles.field}>
                        <label className={styles.field__label} htmlFor={`consumer_key`}>Password</label>
                        <input
                            className={styles.field__input}
                            id="password"
                            name="password"
                            type="text"
                            onChange={handleChange}
                            value={values.password}
                            placeholder={'Telegram password'}
                        />
                    </div>
                )}
                <div className={styles.actions}>
                    <button className={styles.continue} type="submit"
                            disabled={values.phone.length < 10 || (!values.name) || (!values.code && showCode) || (!values.password && showPassword)}>
                        <span>Continue</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default TelegramConnect;