import React, {useState} from "react";
import {useFormik} from "formik";
import styles from "../Company.module.scss";
import emptyPicture from "../../../../assets/img/emptyPicture.svg";
import Picture from "../../Create/Picture";


const AddCompanyForm = () => {
    const [picture, setPicture] = useState<any>('');
    const [imgData, setImgData] = useState<any>('');

    const {handleSubmit, handleChange, isSubmitting, values, resetForm} = useFormik({
    initialValues: {
        description: '',
        title: '',
    },
    onSubmit: (values) => {
        const newValues = {...values, picture,}
        console.log(newValues);
        resetForm();
    },

});

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.picture}>
                <img className={styles.picture__img} src={imgData ? imgData : emptyPicture} alt=""/>
                <Picture
                    picture={picture}
                    setPicture={setPicture}
                    setImgData={setImgData}
                    label={'Logo'}
                    placeholder={'Upload logo your company'}
                />
            </div>
            <div className={styles.textField}>
                <label className={styles.label} htmlFor={`title`}>Company Name</label>
                <input
                    className={styles.textInput}
                    type="text"
                    name="title"
                    id='title'
                    onChange={handleChange}
                    value={values.title}
                    placeholder={'Name'}
                />
            </div>
            <div>
                <label className={styles.label} htmlFor={`description`}>Description</label>
                <div className={styles.textareaBox}>
                        <textarea
                            className={styles.textTextarea}
                            name="description"
                            id='description'
                            onChange={handleChange}
                            value={values.description}
                            placeholder={'Write a short description of what your company does'}
                        />
                </div>
            </div>
            <div className={styles.addCompany__actions}>
                <button disabled={isSubmitting || values.title === ''} type='submit'>Add company</button>
            </div>
        </form>
    )
}

export default AddCompanyForm;