import React, {useEffect, useState} from "react";
import styles from "./PostHeader.module.scss";
import {useLocation} from "react-router";


const PostHeaderTitle = () => {
    const location = useLocation().pathname;
    const [title, setTitle] = useState('Home')

    useEffect(() => {
        switch (location) {
            case '/' :
                setTitle('Home');
                break;
            case '/create/' :
                setTitle('Create');
                break;
            case '/create/add' :
                setTitle('Create');
                break;
            case '/history/' :
                setTitle('History');
                break;
            case '/calendar/' :
                setTitle('Calendar');
                break;
            case '/company/' :
                setTitle('Company');
                break;
            case '/settings/' :
                setTitle('Settings');
                break;
            case '/faq/' :
                setTitle('FAQ');
                break;
            case '/settings/profile/' :
                setTitle('Profile');
                break;
            default:
                setTitle('Home');
                break;
        }
    }, [location])

    return (
        <h2 className={styles.title}>{title}</h2>
    )
}

export default PostHeaderTitle;