import React from "react";
import styles from '../FAQ.module.scss';
import YouTube, { YouTubeProps } from 'react-youtube';

const TipsForUsing = () => {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        event.target.pauseVideo();
    }

    const opts: YouTubeProps['opts'] = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
        },
    };

    return (
        <div>
            <h3 className={styles.faq__title}>Video</h3>
            <p className={styles.faq__text}>Watch the video and get acquainted with all the possibilities of our
                platform.
            </p>
            <div className={styles.faq__video}>
                <YouTube videoId="2w4iKIB_pio" opts={opts} onReady={onPlayerReady} />
            </div>
        </div>
    )
}

export default TipsForUsing;