import React from "react";
import styles from './PreviewSocials.module.scss';
import emptyPhoto from '../../../../assets/img/defaultPhoto.png';
import likeInstagram from '../../../../assets/img/likeInsta.svg';
import chatInstagram from '../../../../assets/img/chatInsta.svg';
import sendInstagram from '../../../../assets/img/sendInsta.svg';
import favoriteInstagram from '../../../../assets/img/favoriteInsta.svg';

interface InstagramProps {
    picture: any;
    description: string
}

const Instagram = ({picture, description}: InstagramProps) => {
    const user = {name: 'blogaboutme', photo: ''}
    return (
        <>
            {picture || description.length
                ? <div className={styles.instagram}>
                    <div className={styles.instagram__header}>
                        <div className={styles.instagram__user}>
                            <img className={styles.instagram__userImage} src={emptyPhoto} alt=""/>
                            <p className={styles.instagram__userName}>{user.name}</p>
                            <span className={styles.instagram__dot} />
                            <span className={styles.instagram__userDate}>now</span>
                        </div>
                        <div className={styles.instagram__headerSettings}>
                            <span className={styles.instagram__headerDot}/>
                            <span className={styles.instagram__headerDot}/>
                            <span className={styles.instagram__headerDot}/>
                        </div>
                    </div>
                    {picture && (
                        <img className={styles.instagram__picture} src={picture} alt=""/>
                    )}
                    <div className={styles.instagram__icons}>
                        <div className={styles.instagram__iconsBox}>
                            <img src={likeInstagram} alt=""/>
                            <img src={chatInstagram} alt=""/>
                            <img src={sendInstagram} alt=""/>
                        </div>
                        <img src={favoriteInstagram} alt=""/>
                    </div>
                    {description && (
                        <p className={styles.instagram__description}>{description}</p>
                    )}
                    <span className={styles.instagram__subText}>more</span>
                    <span className={styles.instagram__subText}>Leave a comment...</span>
                </div> : null}
        </>

    )
}

export default Instagram;