import React from "react";
import styles from './AdvertisingBox.module.scss';
import dashboard from '../../../assets/img/DashBoard.jpg'
import AdvertisingIcons from "./AdvertisingIcons/AdvertisingIcons";

const AdvertisingBox = () => {
    return (
        <div className={styles.Advertising}>
            <p className={styles.Advertising__text}>
                <b>BePosted</b> combines all the popular resources
                where you publish your materials.
            </p>
            <img  className={styles.Advertising__image} src={dashboard} alt=""/>
            <AdvertisingIcons />
        </div>
    )
}

export default AdvertisingBox;