import React from "react";
import styles from "./CreateCompanyInfo.module.scss";


const CompanyChooseOptions = ({setOptions, name}:{setOptions: (setOptions: string) => void, name: string}) => {
    return (
        <div className={styles.companyInfo__chooseOptions}>
            <p className={styles.companyInfo__chooseTitle}>How many social profile do you {name}</p>
            <div className={styles.companyInfo__chooseInputs}>
                <label>
                    <input onChange={(e) => setOptions(e.target.value)} id={name} name={name} type="radio" value="1-3"/>
                    <span>1-3</span>
                </label>
                <label>
                    <input onChange={(e) => setOptions(e.target.value)} id={name} name={name} type="radio" value="3-5"/>
                    <span>3-5</span>
                </label>
                <label>
                    <input onChange={(e) => setOptions(e.target.value)} id={name} name={name} type="radio" value="5-10"/>
                    <span>5-10</span>
                </label>
                <label>
                    <input onChange={(e) => setOptions(e.target.value)} id={'manage'} name={'manage'} type="radio" value=">10"/>
                    <span>{">"}10</span>
                </label>
            </div>
        </div>
    )
}

export default CompanyChooseOptions;