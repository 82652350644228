import styles from "./Create.module.scss";
import close from "../../../assets/img/close.svg";
import EventsList from "./EventsList";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../hooks/redux";
import {getTimeZones} from "../../../store/selectors";

interface TimePopupProps {
    showTimePopup: boolean;
    closerTimePopup: () => void;
    setTimeZone: (value: string) => void;
    dateForPost: string;
    add: boolean;
}

const TimePopup = ({showTimePopup, closerTimePopup, setTimeZone, dateForPost, add}: TimePopupProps) => {
    const [date, setDate] = useState(add ? dateForPost : '');
    const [time, setTime] = useState('');
    const [timeZoneName, setTimeZoneName] = useState('');
    const [timeZoneValue, setTimeZoneValue] = useState('');

    const timeZones = useAppSelector(getTimeZones);

    const SendTime = (value: string) => {
        setTimeZone(value);
    }

    const getTimeZoneCode = () => {
        let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
        return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    }

    const handleChangeZone = (event: SelectChangeEvent) => {
        setTimeZoneName(event.target.value as string);
    };

    const createDateValue = async () => {
        if (timeZoneValue === '') {
            const zone = getTimeZoneCode();
            return (`${date}T${time}:00${zone}`)
        } else {
            return (`${date}T${time}:00${timeZoneValue}`);
        }
    }

    const changeTimeZone = (e: any, value: string) => {
        handleChangeZone(e);
        setTimeZoneValue(value)
    }

    const removeTime = () => {
        setTime('');
        setDate('');
        getTimeZone();
        closerTimePopup()
    }

    useEffect(() => {
        getTimeZone();
    }, []);

    const getTimeZone = () => {
        // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // @ts-ignore
        const timeZone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1];
        // const timeZone = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
        setTimeZoneName(timeZone)
    };


    return (
        <div className={showTimePopup ? `${styles.timePopup} hide-scroll` : styles.timePopupHide}>
            <div className={`${styles.timePopup__box} timePopupBox`}>
                <button className={styles.timePopup__close} type='button'
                        onClick={() => removeTime()}>
                    <img src={close} alt="close"/>
                </button>
                <h3 className={styles.timePopup__title}>Choose the desired date for publication</h3>
                <div className={styles.timePopup__content}>
                    <div className={styles.timePopup__events}>
                        <h4 className={styles.timePopup__eventsTitle}>8 November 2023</h4>
                        <EventsList/>
                    </div>
                    <div className={styles.timePopup__dateTime}>
                        <Box>
                            <label className={styles.label} htmlFor={`demo-simple-select`}>Time zone</label>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={timeZoneName}
                                    onChange={handleChangeZone}
                                >
                                    {timeZones && (
                                        timeZones.map((item, index) => <MenuItem
                                            key={index}
                                            onClick={(e) => {
                                                changeTimeZone(e, item.value);
                                            }}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <label className={styles.label} htmlFor={`title`}>Date</label>
                        <input
                            className={styles.textInput}
                            type="date"
                            name="date"
                            id='date'
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                        />
                        <label className={styles.label} htmlFor={`time`}>Time</label>
                        <input
                            className={styles.textInput}
                            type="time"
                            name="time"
                            id='time'
                            onChange={(e) => setTime(e.target.value)}
                            value={time}
                        />
                        <div className={styles.timePopup__button}>
                            <button onClick={() => {
                                createDateValue().then((response) => SendTime(response));
                                closerTimePopup();
                            }} type='button'>Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimePopup;