import React from "react";
import styles from './CalendarPopups.module.scss';
import left from "../../../../assets/img/left.svg";
import close from "../../../../assets/img/close.svg";
import {Event} from "../../../../store/calendar.types";
import EventItem from "./EventItem";

interface EventsPopupProps {
    post: Event[],
    hidePopup: (hidePopup: string) => void,
    day: number,
    date: string,
    getPostId: (getPostId: number) => void,
}

const EventsPopup = ({post, hidePopup, day, date, getPostId}: EventsPopupProps) => {
    return (
        <div className={styles.eventsPopupWrapper}>
            <div className={styles.eventsPopup}>
                <div className={styles.eventsPopup__closers}>
                    <button onClick={() => hidePopup('close')}><img src={left} alt=""/><span>Back</span></button>
                    <button onClick={() => hidePopup('close')}><img src={close} alt=""/></button>
                </div>
                <div className={styles.eventsPopup__title}>
                    <h3>Publications of this day</h3>
                    <p className={styles.eventsPopup__date}>{day} {date}</p>
                </div>
                <ul className={styles.eventsPopup__content}>
                    {post.map((post, index) =>
                        <EventItem key={index}
                                   socialIcon={post.socialIcon}
                                   status={post.status}
                                   author={post.author}
                                   time={post.time}
                                   id={post.id}
                                   getPostId={getPostId}/>
                        )}
                </ul>
            </div>
        </div>
    )
}

export default EventsPopup;