import React from "react";
import {tariff} from '../../../../../store/main-slice.types';
import styles from './Tariff.module.scss';
import ProfilesUsers from "./ProfilesUsers";


const Tariff: React.FC<tariff> = ({styleSmall= false, title, text, price, profiles, users, posts, analytics, socialInbox, monitoring}) => {
    return (
        <div className={styleSmall ? styles.tariffSmall : styles.tariff}>
            <h4 className={styleSmall ? styles.tariffSmall__title : styles.tariff__title}>{title}</h4>
            <p className={styleSmall ? styles.tariffSmall__subtext : styles.tariff__subtext}>{text}</p>
            <p className={styleSmall ? styles.tariffSmall__price : styles.tariff__price}>$<span>{price}</span>/month</p>
            <ProfilesUsers styleSmall={styleSmall} value={profiles} content={'Social Profiles'}/>
            <ProfilesUsers styleSmall={styleSmall} value={users} content={'Users'} />
            <p className={styleSmall ? styles.tariffSmall__text : styles.tariff__text}><span className={styles.bold}>{posts}</span> Posts</p>
            <p className={styleSmall ? styles.tariffSmall__text : styles.tariff__text}>
                <span className={analytics ? styles.support : styles.notSupport}/>Analytics
            </p>
            <p className={styleSmall ? styles.tariffSmall__text : styles.tariff__text}>
                <span className={socialInbox ? styles.support : styles.notSupport}/>SocialInbox
            </p>
            <p className={styleSmall ? `${styles.tariffSmall__text} ${styles.tariffSmall__textLast}` : styles.tariff__subtext}>
                <span className={monitoring ? styles.support : styles.notSupport}/>Monitoring
            </p>
            <div className={styleSmall ? styles.tariffSmall__actions : styles.tariff__actions}>
                <button>{styleSmall ? 'Buy' : 'Try it now'}</button>
            </div>
        </div>
    )
}

export default Tariff;