import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from './Settings.module.scss';


const LeftMenuTabs = ({setContent}: { setContent: (setContent: string) => void }) => {
    const [value, setValue] = useState('Privacy & Security');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setContent(newValue);
    };
    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
            >
                <Tab
                    className={styles.customTab}
                    label={<div>
                        <h4>Privacy & Security</h4>
                        <p className={styles.tab__subText}>Set your password or change it, two-factor authentication</p>
                    </div>}
                     value={'Privacy & Security'}/>
                <Tab
                    className={styles.customTab}
                    label={<div>
                            <h4>Notification</h4>
                            <p className={styles.tab__subText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                        </div>}
                    value={'Notification'}/>
            </Tabs>
        </Box>
    )
}

export default LeftMenuTabs;