import React from "react";
import styles from './Create.module.scss';

interface dayEventsProps {
    time: string,
    title: string
}

const EventsList = () => {
    const dayEvents = [
        {time: '08:30', title: 'Post number 1'},
        {time: '10:15', title: 'Post number 2'},
        {time: '11:10', title: 'Post number 3'},
        {time: '13:00', title: 'Post number 4'},
        {time: '13:30', title: 'Post number 5'},
        {time: '13:45', title: 'Post number 6'},
        {time: '14:40', title: 'Post number 7'},
        {time: '14:50', title: 'Post number 8'},
        {time: '15:00', title: 'Post number 9'},
        {time: '15:15', title: 'Post number 10'},
        {time: '16:30', title: 'Post number 11'},
        {time: '17:00', title: 'Post number 12'},
        {time: '17:30', title: 'Post number 13'},
        {time: '18:00', title: 'Post number 14'},
        {time: '18:40', title: 'Post number 15'},
        {time: '19:00', title: 'Post number 16'},
    ]
    return (
        <ul className={styles.timePopup__eventList}>
            {dayEvents && (
                dayEvents.map((post: dayEventsProps, index: number) =>
                    <li key={index} className={styles.timePopup__eventPost}>
                        <span className={styles.timePopup__eventTime}>{post.time}</span>{post.title}
                    </li>
                )
            )}
        </ul>
    )
}

export default EventsList;