import React, {useState} from "react";
import styles from './Settings.module.scss';
import LeftMenuTabs from "./LeftMenuTabs";
import Notification from "./Notification/Notification";
import './Settings.css';
import Privacy from "./Privacy/Privacy";


const Settings = () => {
    const [content, setContent] = useState('Privacy & Security')

    return (
        <div className={`${styles.settings} settings`}>
            <div className={styles.settings__menu}>
                <LeftMenuTabs setContent={setContent} />
            </div>
            <div className={styles.settings__content}>
                {content === 'Privacy & Security' && (
                    <Privacy />
                )}
                {content === 'Notification' && (
                    <Notification />
                )}
            </div>
        </div>
    )
}

export default Settings;