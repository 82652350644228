import React from "react";
import styles from "./Create.module.scss";
import remove from "../../../assets/img/remove.svg";

interface file {
    name: string,
    size: string,
}

interface FilesListProps {
    filesData: file[],
    removeFile: (index: number) => void
}

const FilesList = ({filesData, removeFile}:FilesListProps) => {
    return (
        <div>
            <div className={styles.filesName}>
                            <span
                                className={styles.filesName__textTitle}>The maximum number of files to download is 10</span>
                <span className={styles.filesName__text}>Attached files</span>
                <ul>
                    {filesData.map((item, index) =>
                        <li key={index}>
                            {item.name}
                            <button type='button' onClick={() => removeFile(index)}>
                                <img src={remove} alt="remove"/>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default FilesList;