import React from "react";
import SocialItem from "./SocialItem/SocialItem";
import styles from './IntegrationContent.module.scss';
import {useAppSelector} from "../../../../hooks/redux";
import {getSocialNetworks} from "../../../../store/selectors";


const IntegrationContent = () => {

    const socials = useAppSelector(getSocialNetworks);

    return (
        <div className={styles.integrationContent}>
            {socials && (
                socials.map((item, index) => <SocialItem
                    key={index}
                    title={item.title}
                    text={item.text}
                    icon={item.icon}
                />)
            )}
        </div>
    )
}

export default IntegrationContent;