import React from "react";
import styles from "./Calendar.module.scss";
import arrLeft from "../../../assets/img/arrowLeft.svg";
import arrRight from "../../../assets/img/arrowRight.svg";


const ChangeDate = ({date}: {date: string}) => {
    return (
        <div className={styles.changeDate}>
            <button><img src={arrLeft} alt=""/></button>
            <p>{date}</p>
            <button><img src={arrRight} alt=""/></button>
        </div>
    )
}

export default ChangeDate;