import React from "react";
import styles from './AboutContent.module.scss'
import Button from "../../../Common/Button/Button";

import YouTube, { YouTubeProps } from 'react-youtube';

const AboutContent = () => {

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const opts: YouTubeProps['opts'] = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
        },
    };

    return (
        <div className={styles.about}>
            <div className={styles.about__info}>
                <p className={styles.about__infoText}>
                    BePosted - a service for content managers, bloggers, mass media workers and generally everyone who
                    is actively engaged in publishing various types of media content. The BeFund team has developed its
                    own product with each of you in mind, because we know how difficult it is to keep track of each
                    resource individually.<br/><br/>

                    BePosted combines all the popular resources where you publish your materials. Now all this can be
                    downloaded from a single account on our platform and regardless of the limitations of browser or
                    mobile applications. We made sure that you could not only instantly post content on account pages
                    and channels, but also schedule the release of delayed publications.<br/><br/>

                    Use all offers from BePosted or choose only the publishing services you need, find the optimal
                    tariff plan and use the free trial version.
                </p>
                <Button text={'Try for free now'} color={'red'}/>
            </div>
            <div className={styles.about__video}>
                <YouTube videoId="2w4iKIB_pio" opts={opts} onReady={onPlayerReady} />
            </div>
        </div>
    )
}

export default AboutContent;