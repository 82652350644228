import React from "react";
import styles from './Create.module.scss';
import success from '../../../assets/img/success.svg'
import failed from '../../../assets/img/failed.svg'
import close from '../../../assets/img/close.svg'

interface MessageEventProps {
    event: null | string,
    setEvent: (setEvent: null) => void,
}

const MessageEvent = ({event, setEvent}:MessageEventProps) => {
    return (
        <div className={styles.event}>
            <div className={styles.event__info}>
                <img src={event === 'failed' ? failed : success} alt=""/>
                <div className={styles.event__text}>
                    <p>{event === 'failed' ? 'Failed' : 'Successfully'}</p>
                    <span>{event === 'failed' ? `Failed` : `Your post has been successfully ${event}!`}</span>
                </div>
            </div>
            <button onClick={() => setEvent(null)}>
                <img src={close} alt=""/>
            </button>
        </div>
    )
}

export default MessageEvent;