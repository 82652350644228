import React from "react";
import styles from "./Calendar.module.scss";


const Days = () => {
    return (
        <div className={styles.days}>
            <p>Monday</p>
            <p>Tuesday</p>
            <p>Wednesday</p>
            <p>Thursday</p>
            <p>Friday</p>
            <p>Saturday</p>
            <p>Sunday</p>
        </div>
    )
}

export default Days;