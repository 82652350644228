import React, {useEffect, useState} from "react";
import {Post} from "../../../store/posts-slice.types";
import styles from './History.module.scss';
import PostTable from "./PostTable";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import CalendarDays from '../../../assets/img/CalendarDays.svg'
import {getAllHistoryPosts, updateAccessToken} from "../../../api/api";
import {getAccessToken, getAccessTokenTimer, getRefreshToken, setNewAccessToken} from "../../../api/localStorage";


const History = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [status, setStatus] = useState('All');
    const [social, setSocial] = useState('All');
    const [postsWithStatus, setPostsWithStatus] = useState<Post[]>([]);
    const [postsWithSocial, setPostsWithSocial] = useState<Post[]>([]);
    const [author, setAuthor] = useState('All');
    const [dates, setDates] = useState<Dates>([new Date(), new Date()]);
    const [postsWithFilter, setPostsWithFilter] = useState<Post[]>([]);


    type ValuePiece = Date | null;
    type Dates = ValuePiece | [ValuePiece, ValuePiece];

    useEffect(() => {
        getAccessTokenTimer().then((result) => {
            if (result && Math.round(new Date().getTime() / 1000 - 3500) > Number(result)) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("accessTokenTimer");
                getRefreshToken().then((token) => {
                    if (token) {
                        updateAccessToken(token).then((res) => {
                            if (res && res.access) {
                                setNewAccessToken(res.access);
                            }
                        })
                    }
                })
            }
        })
        getAccessToken().then((token) => {
            if (token) {
                getAllHistoryPosts(token).then(result => {
                    if (result && result.posts) {
                        setPosts(result.posts);
                        if (postsWithFilter.length < 1) {
                            setPostsWithFilter(result.posts);
                        }
                        if (postsWithStatus.length < 1) {
                            setPostsWithStatus(result.posts);
                        }
                        if (postsWithSocial.length < 1) {
                            setPostsWithSocial(result.posts);
                        }
                    }
                })
            }
        });
    },[])

    console.log(posts)


    const handleChange = (event: SelectChangeEvent | any, value: string) => {

        if (value === 'setStatus' && event.target.value !== status) {
            setStatus(event.target.value);
            const data = {status: event.target.value, social: social, author: author, dates: dates};

            switch (event.target.value) {
                case 'Published' :
                    const publishedPosts = postsWithSocial.filter((item) => item.status === 1);
                    setPostsWithStatus(publishedPosts);
                    setPostsWithFilter(publishedPosts);
                    break;
                case 'Postponed' :
                    const postponedPosts = postsWithSocial.filter((item) => item.status === 3);
                    setPostsWithStatus(postponedPosts);
                    setPostsWithFilter(postponedPosts);
                    break;
                case 'Draft' :
                    const draftedPosts = postsWithSocial.filter((item) => item.status === 2);
                    setPostsWithStatus(draftedPosts);
                    setPostsWithFilter(draftedPosts);
                    break;
                case 'Deleted' :
                    const deletedPosts = postsWithSocial.filter((item) => item.status === 4);
                    setPostsWithStatus(deletedPosts);
                    setPostsWithFilter(deletedPosts);
                    break;
                default:
                    setPostsWithStatus(posts);
                    setPostsWithFilter(postsWithSocial);
                    break;
            }

        }
        if (value === 'setSocial' && event.target.value !== social) {
            setSocial(event.target.value);
            const data = {status: status, social: event.target.value, author: author, dates: dates};

            switch (event.target.value) {
                case 'Telegram' :
                    const telegramPosts = postsWithStatus.filter((item) => item.socialNetwork === event.target.value);
                    setPostsWithSocial(telegramPosts);
                    setPostsWithFilter(telegramPosts);
                    break;
                case 'Discord' :
                    const discordPosts = postsWithStatus.filter((item) => item.socialNetwork === event.target.value);
                    setPostsWithSocial(discordPosts);
                    setPostsWithFilter(discordPosts);
                    break;
                case 'Twitter' :
                    const twitterPosts = postsWithStatus.filter((item) => item.socialNetwork === event.target.value);
                    setPostsWithSocial(twitterPosts);
                    setPostsWithFilter(twitterPosts);
                    break;
                case 'Instagram' :
                    const instagramPosts = postsWithStatus.filter((item) => item.socialNetwork === event.target.value);
                    setPostsWithSocial(instagramPosts);
                    setPostsWithFilter(instagramPosts);
                    break;
                case 'Slack' :
                    const slackPosts = postsWithStatus.filter((item) => item.socialNetwork === event.target.value);
                    setPostsWithSocial(slackPosts);
                    setPostsWithFilter(slackPosts);
                    break;
                default:
                    setPostsWithSocial(posts)
                    setPostsWithFilter(postsWithStatus);
                    break;
            }
        }
        if (value === 'setAuthor' && event.target.value !== author) {
            setAuthor(event.target.value);
            const data = {status: status, social: social, author: event.target.value, dates: dates};
            console.log(data);
        }

        if (value === 'setDates' && event !== dates) {
            setDates(event);
            const data = {status: status, social: social, author: author, dates: event};
            console.log(data);
        }
    };

    return (
        <div>
            <div className={`${styles.filters} post-filters`}>
                <Box sx={{width: 196}}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            onChange={(e) => handleChange(e, 'setStatus')}
                        >
                            <MenuItem value={'Postponed'}>Postponed</MenuItem>
                            <MenuItem value={'Published'}>Published</MenuItem>
                            <MenuItem value={'Draft'}>Draft</MenuItem>
                            <MenuItem value={'Deleted'}>Deleted</MenuItem>
                            <MenuItem value={'All'}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{width: 196}}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={social}
                            onChange={(e) => handleChange(e, 'setSocial')}
                        >
                            <MenuItem value={'Telegram'}>Telegram</MenuItem>
                            <MenuItem value={'Discord'}>Discord</MenuItem>
                            <MenuItem value={'Twitter'}>Twitter</MenuItem>
                            <MenuItem value={'Instagram'}>Instagram</MenuItem>
                            <MenuItem value={'Slack'}>Slack</MenuItem>
                            <MenuItem value={'All'}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/*<Box sx={{width: 196}}>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <Select*/}
                {/*            labelId="demo-simple-select-label"*/}
                {/*            id="demo-simple-select"*/}
                {/*            value={author}*/}
                {/*            onChange={(e) => handleChange(e, 'setAuthor')}*/}
                {/*        >*/}
                {/*            <MenuItem value={'Jaylon Vaccaro'}>Jaylon Vaccaro</MenuItem>*/}
                {/*            <MenuItem value={'Bill Bon'}>Bill Bon</MenuItem>*/}
                {/*            <MenuItem value={'All'}>All</MenuItem>*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Box>*/}
                <div className={styles.customCalendar}>
                    <img className={styles.calendarIcon} src={CalendarDays} alt=""/>
                    <DateRangePicker onChange={(e) => handleChange(e, 'setDates')} value={dates}/>
                </div>
            </div>
            <div className={styles.postsContainer}>
                <PostTable setPosts={setPosts} posts={postsWithFilter}/>
            </div>
        </div>
    )
}

export default History;