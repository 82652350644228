import React from "react";
import {ButtonProps} from './Button.types';
import styles from './Button.module.scss'
import {useNavigate} from 'react-router';

const Button = ({ text, color, login = false, register = false }:ButtonProps) => {
    const style = `button__${color}`
    const navigate = useNavigate();
    const redirect = (login:boolean, register:boolean) => {
        if (login || register ) {
            login ? navigate('/login') : navigate('/register')
        }
    }

    return (
        <button onClick={() => redirect(login, register)} className={`${styles['button']} ${styles[style]}`}>
            {text}
        </button>
    )
}

export default Button;

