import React, {useState} from "react";
import styles from './ChooseTariff.module.scss';
import {useAppSelector} from "../../../../hooks/redux";
import {getTariffs} from "../../../../store/selectors";
import Tariff from "../../../HomePage/HomeTariffs/TariffsContent/Tariff/Tariff";


const ChooseTariff = ({setStep}:{setStep: (setStep: number) => void}) => {
    const tariffs = useAppSelector(getTariffs);
    const [tariffSelected, setTariffSelected] = useState('')

    const onSubmit = () => {
        console.log(tariffSelected);
    }

    return (
        <div className={styles.chooseTariff}>
            <div className={styles.chooseTariff__text}>
                <h3 className={styles.chooseTariff__title}>
                    Let's choose a tariff
                </h3>
                <p className={styles.chooseTariff__subTitle}>
                    We have selected for you the most optimal tariff that suits the needs of your company, but you can
                    change it.
                </p>
            </div>
            <div className={styles.chooseTariff__tariffsWrapper}>
                {tariffs && (
                    tariffs.map((item, index) => <Tariff
                        styleSmall
                        key={index}
                        title={item.title}
                        text={item.text}
                        price={item.price}
                        profiles={item.profiles}
                        users={item.users}
                        posts={item.posts}
                        analytics={item.analytics}
                        socialInbox={item.socialInbox}
                        monitoring={item.monitoring}
                    />)
                )}
            </div>
            <div className={styles.chooseTariff__actions}>
                <button
                    type='button'
                    onChange={() => setStep(2)}
                    className={styles.chooseTariff__back}>
                    Back
                </button>
                <button className={styles.chooseTariff__next}
                        disabled={tariffSelected === ''}
                        onClick={() => onSubmit()}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default ChooseTariff;