import React, {useState} from "react";
import {useFormik} from "formik";
import styles from './Create.module.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getConnectedSocialNetworks, getDateForPost, getMySocialNetworks} from "../../../store/selectors";
import Preview from "./PreviewSocials/Preview";
import emptyPicture from '../../../assets/img/emptyPicture.svg';
import smile from '../../../assets/img/smile.svg';
import smileActive from '../../../assets/img/smileActive.svg';
import addFile from '../../../assets/img/addFile.svg';
import addFileActive from '../../../assets/img/addFileActive.svg';
import EmojiPicker, {EmojiStyle, Theme, EmojiClickData,} from "emoji-picker-react";
import Picture from "./Picture";
import TimePopup from "./TimePopup";
import FilePopup from "./FilePopup";
import SocialNetCheckboxes from "../../Common/SocialNetCheckboxes/SocialNetCheckboxes";
import {
    createDiscordPost,
    createInstagramPost,
    createSlackPost,
    createTelegramPost,
    createTwitterPost, getMainStatistic
} from "../../../api/api";
import {getAccessToken} from "../../../api/localStorage";
import DiscordChangers from "./DiscordChangers";
import TwitterChangers from "./TwitterChangers";
import InstagramChangers from "./InstagramChangers";
import SlackChangers from "./SlackChangers";
import TelegramChangers from "./TelegramChangers";
import MessageEvent from "./MessageEvent";
import {setStatistic} from "../../../store/main-slice";

const Create = ({add = false}) => {
    const dateForPost = useAppSelector(getDateForPost);
    const MySocials = useAppSelector(getMySocialNetworks);
    const MyNetworks = useAppSelector(getConnectedSocialNetworks);
    const dispatch = useAppDispatch();

    const [socialSelected, setSocialSelected] = useState('');
    const [picture, setPicture] = useState<any>(null);
    const [imgData, setImgData] = useState<any>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [showEmoji, setShowEmoji] = useState(false);
    const [showFilePopup, setShowFilePopup] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const [filesData, setFilesData] = useState<any[]>([]);
    const [showTimePopup, setShowTimePopup] = useState(false);
    const [dateValue, setDateValue] = useState(add ? dateForPost : '');
    const [twitterAccount, setTwitterAccount] = useState<number>(0);
    const [discordAccount, setDiscordAccount] = useState<number>(0);
    const [slackAccount, setSlackAccount] = useState<number>(0);
    const [telegramAccount, setTelegramAccount] = useState<number>(0);
    const [instagramAccount, setInstagramAccount] = useState<number>(0);
    const [chanel, setChanel] = useState<number[]>([]);
    const [slackChanel, setSlackChanel] = useState<number[]>([]);
    const [telegramChanel, setTelegramChanel] = useState<number[]>([]);
    const [messageEvent, setMessageEvent] = useState<null | string>(null);

    showFilePopup || showTimePopup ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    const updateStats = (token: string) => {
        getMainStatistic(token).then(r => dispatch(setStatistic(r)))
    }

    const closerTimePopup = () => {
        setShowTimePopup(false)
    }

    const closerFilePopup = () => {
        setShowFilePopup(false)
    }

    const setTimeZone = (value: string) => {
        setDateValue(value)
    }

    const setFilesValue = (files: any, fileData: any) => {
        setFiles(files);
        setFilesData(fileData);
    }

    function onClick(emojiData: EmojiClickData, event: MouseEvent) {
        setInputValue(
            (inputValue) =>
                inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
        );
    }

    const addSocial = (value: string) => {
        // if (socials.length) {
        //     const isItemValue = socials.find((item) => item === value);
        //
        //     if (isItemValue) {
        //         const newArray = socials.filter((item) => item !== value);
        //         setSocials([...newArray]);
        //     } else {
        //         setSocials([...socials, value])
        //     }
        // } else {
        //     const newArray = [`${value}`];
        //     setSocials(newArray)
        // }
        setSocialSelected(value)
    }

    const onDraft = (title: string) => {
        if (socialSelected === 'Twitter') {
            getAccessToken().then((token) => {
                if (token) {
                    createTwitterPost(token, '2', twitterAccount, title, inputValue, null).then(response => {
                        if (response) {
                            setMessageEvent('saved as a draft');
                        }
                    })
                }
            });

        }
        if (socialSelected === 'Discord') {
            getAccessToken().then((token) => {
                if (token) {
                    createDiscordPost(token, 2, discordAccount, values.title, inputValue, null, chanel, picture).then(r => {
                        if (r && r.created_date) {
                            setMessageEvent('saved as a draft');
                        }
                    })
                }
            });
        }
        if (socialSelected === 'Instagram') {
            getAccessToken().then((token) => {
                if (token) {
                    createInstagramPost(token, 2, instagramAccount, values.title, inputValue, '', picture).then(r => {
                        if (r && r.created_date) {
                            setMessageEvent('saved as a draft');
                        }
                    })
                }
            });
        }
        if (socialSelected === 'Slack') {
            getAccessToken().then((token) => {
                if (token) {
                    createSlackPost(token, 2, slackAccount, values.title, inputValue, dateValue, slackChanel, picture).then(r => {
                        if (r && r.current_status === 'draft') {
                            setMessageEvent('saved as a draft');
                        }
                    })
                }
            });
        }

        setInputValue('');
    }


    const {handleSubmit, handleChange, isSubmitting, values, resetForm} = useFormik({
        initialValues: {
            title: '',
        },

        onSubmit: (values) => {
            // const newValues = {...values, socials, picture, inputValue, files, dateValue};
            if (socialSelected === 'Twitter' && twitterAccount !== 0) {
                getAccessToken().then((token) => {
                    if (token) {
                        dateValue.length < 16
                            ? createTwitterPost(token, '1', twitterAccount, values.title, inputValue, null).then(r => {
                                if (r) {
                                    setMessageEvent('published');
                                    updateStats(token);
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                            : createTwitterPost(token, '3', twitterAccount, values.title, inputValue, dateValue).then(r => {
                                if (r) {
                                    setMessageEvent('saved');
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                    }
                });

            }
            if (socialSelected === 'Discord' && discordAccount !== 0 && chanel[0] !== 0) {
                getAccessToken().then((token) => {
                    if (token) {
                        dateValue.length < 16
                            ? createDiscordPost(token, 1, discordAccount, values.title, inputValue, null, chanel, picture).then(r => {
                                if (r && r.created_date) {
                                    setMessageEvent('published');
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                            : createDiscordPost(token, 3, discordAccount, values.title, inputValue, dateValue, chanel, picture).then(r => {
                                if (r && r.published_date) {
                                    setMessageEvent('saved');
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                    }
                });
            }
            if (socialSelected === 'Slack' && slackAccount !== 0 && slackChanel[0] !== 0) {
                getAccessToken().then((token) => {
                    if (token) {
                        dateValue.length < 16
                            ? createSlackPost(token, 1, slackAccount, values.title, inputValue, null, slackChanel, picture).then(r => {
                                if (r && r.current_status === 'published') {
                                    setMessageEvent(r.current_status);
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                            : createSlackPost(token, 3, slackAccount, values.title, inputValue, dateValue, slackChanel, picture).then(r => {
                                if (r && r.current_status === 'planned') {
                                    setMessageEvent(r.current_status);
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                    }
                });
            }
            if (socialSelected === 'Instagram' && instagramAccount !== 0 && picture) {
                getAccessToken().then((token) => {
                    if (token) {
                        dateValue.length < 16
                            ? createInstagramPost(token, 1, instagramAccount, values.title, inputValue, '', picture).then(r => {
                                if (r && r.created_date) {
                                    setMessageEvent('published');
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                            : createInstagramPost(token, 3, instagramAccount, values.title, inputValue, dateValue, picture).then(r => {
                                if (r && r.published_date) {
                                    setMessageEvent('saved');
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                    }
                });
            }
            if (socialSelected === 'Telegram' && telegramAccount !== 0) {
                getAccessToken().then((token) => {
                    if (token) {
                        dateValue.length < 16
                            ? createTelegramPost(token, 1, telegramAccount, values.title, inputValue, null, telegramChanel, picture).then(r => {
                                if (r && r.current_status === 'published') {
                                    setMessageEvent(r.current_status);
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                            : createTelegramPost(token, 3, telegramAccount, values.title, inputValue, dateValue, telegramChanel, picture).then(r => {
                                if (r && r.current_status) {
                                    setMessageEvent(r.current_status);
                                    updateStats(token)
                                } else {
                                    setMessageEvent('failed');
                                    updateStats(token)
                                }
                            })
                    }
                });
            }
            setInputValue('');
            resetForm();
        },
    });

    return (
        <div className={styles.createWrapper}>
            <form className={styles.form} onSubmit={handleSubmit}>
                {messageEvent && (
                    <MessageEvent event={messageEvent} setEvent={setMessageEvent} />
                )}
                <label className={styles.label} htmlFor={`socials`}>Select the social network where the post will be
                    published</label>
                <SocialNetCheckboxes socials={MySocials} addSocial={addSocial}/>
                {socialSelected === 'Discord' && (
                    <DiscordChangers account={discordAccount} setAccount={setDiscordAccount} chanel={chanel}
                                     setChanel={setChanel}/>
                )}
                {socialSelected === 'Twitter' && (
                    <TwitterChangers account={twitterAccount} setAccount={setTwitterAccount} />
                )}
                {socialSelected === 'Instagram' && (
                    <InstagramChangers account={instagramAccount} setAccount={setInstagramAccount}/>
                )}
                {socialSelected === 'Slack' && (
                    <SlackChangers account={slackAccount} setAccount={setSlackAccount} chanel={slackChanel}
                                     setChanel={setSlackChanel}/>
                )}
                {socialSelected === 'Telegram' && (
                    <TelegramChangers account={telegramAccount} setAccount={setTelegramAccount} chanel={telegramChanel}
                                      setChanel={setTelegramChanel} />
                )}
                <div className={styles.picture}>
                    <img className={styles.picture__img} src={imgData ? imgData : emptyPicture} alt=""/>
                    <Picture files={files} picture={picture} setPicture={setPicture} setImgData={setImgData}
                             label={'Picture'}/>
                </div>
                <div className={styles.textField}>
                    <label className={styles.label} htmlFor={`title`}>Title</label>
                    <input
                        className={styles.textInput}
                        type="text"
                        name="title"
                        id='title'
                        onChange={handleChange}
                        value={values.title}
                    />
                </div>
                <div className={styles.textField}>
                    <label className={styles.label} htmlFor={`description`}>Description</label>
                    <div className={styles.textareaBox}>
                        <textarea
                            className={styles.textTextarea}
                            name="description"
                            id='description'
                            onChange={(e) => setInputValue(e.target.value)}
                            value={inputValue}
                        />
                        <div className={styles.textTextarea__footer}>
                            {imgData
                                ? <button type='button' disabled={!!imgData}
                                          className={styles.textTextarea__fileDisable}>
                                    <img src={showFilePopup ? addFileActive : addFile} alt="add file"/>
                                </button>
                                : <button type='button' onClick={() => setShowFilePopup(true)}
                                          className={showFilePopup ? styles.textTextarea__fileActive : styles.textTextarea__file}>
                                    <img src={showFilePopup ? addFileActive : addFile} alt="add file"/>
                                </button>
                            }
                            <button type='button' onClick={() => {
                                setShowEmoji(!showEmoji)
                            }}
                                    className={showEmoji ? styles.textTextarea__emojiActive : styles.textTextarea__emoji}>
                                <img src={showEmoji ? smileActive : smile} alt="add emoji"/>
                            </button>
                        </div>
                        <div className={showEmoji ? styles.emojiBox : styles.emojiBoxHide}>
                            <EmojiPicker
                                onEmojiClick={onClick}
                                autoFocusSearch={true}
                                emojiStyle={EmojiStyle.NATIVE}
                                searchDisabled
                                theme={Theme.LIGHT}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <button disabled={values.title === '' || socialSelected === '' || inputValue === ''}
                            className={styles.actions__draft} type="button" onClick={() => onDraft(values.title)}>
                        <span>In draft</span>
                    </button>
                    <button onClick={() => setShowTimePopup(true)} className={styles.actions__time} type="button">
                        <span>Date & Time</span>
                    </button>
                    <button className={styles.actions__publish} type="submit"
                            disabled={isSubmitting || inputValue === '' || socialSelected === ''}>
                        <span>Publish</span>
                    </button>
                </div>
            </form>
            <Preview picture={imgData} description={inputValue} filesData={filesData}/>
            <FilePopup closerFilePopup={closerFilePopup} showFilePopup={showFilePopup} setFilesValue={setFilesValue}/>
            <TimePopup showTimePopup={showTimePopup} closerTimePopup={closerTimePopup} setTimeZone={setTimeZone}
                       dateForPost={dateForPost} add={add}/>
        </div>
    )
}

export default Create;