import React from "react";
import {useFormik} from "formik";
import styles from './FeetBackForm.module.scss'

const FeetBackForm = () => {

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },

        onSubmit: (values) => {
            console.log(values);
            setSubmitting(false);
            resetForm();
        },
    });

    return (
        <form className={styles.FeetBackForm} onSubmit={handleSubmit}>
            <label className={styles.FeetBackForm__label} htmlFor={`name`}>Your name</label>
            <input
                className={styles.FeetBackForm__input}
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                placeholder={'Enter you name'}
            />
            <label className={styles.FeetBackForm__label} htmlFor={`email`}>Your email</label>
            <input
                className={styles.FeetBackForm__input}
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                placeholder={'Enter you email'}
            />
            <label className={styles.FeetBackForm__label} htmlFor={`message`}>Message</label>
            <textarea
                className={styles.FeetBackForm__message}
                id="message"
                name="message"
                onChange={handleChange}
                value={values.message}
                placeholder={'Enter you message'}
            />
            <div className={styles.send}>
                <button
                    className={styles.send__button}
                    type="submit"
                    disabled={isSubmitting}
                >
                    <span className={styles.send__text}>Send message</span>
                </button>
            </div>
        </form>
    )
}

export default FeetBackForm;