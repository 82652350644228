import React from "react";
import styles from './FooterAuthorization.module.scss'
import Button from "../../../Common/Button/Button";

const FooterAuthorization = () => {
    return (
        <div className={styles.authWrapper}>
            <div className={styles.authText}>Welcome to your new digital reality</div>
            <div className={styles.authButtons}>
                <Button text={'Log in'} color={'white'} login />
                <Button text={'Sign up'} color={'blue'} register />
            </div>
        </div>
    )
}
export default FooterAuthorization;