import React from "react";
import styles from './ProgressUse.module.scss'
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";
import './ProgressUse.css';

const userName = 'Tony';
const result = 89.9

const options: ApexOptions = {
    plotOptions: {
        radialBar: {
            offsetY: -30,
            startAngle: 0,
            endAngle: 360,
            hollow: {
                margin: 0,
                size: '60%',
                background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                }
            },
            track: {
                background: '#F6F6F6',
                strokeWidth: '100%',
                margin: 0, // margin is in pixels
                dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 2,
                    blur: 3,
                    opacity: 0.35
                }
            },

            dataLabels: {
                show: true,
                name: {
                    offsetY: 20,
                    show: true,
                    color: '#4F4F4F',
                    fontSize: '16px'
                },
                value: {
                    show: true,
                    fontSize: '24px',
                    offsetY: -20,
                    fontFamily: undefined,
                    fontWeight: 400,
                    color: '#333',
                },
            }
        }
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            type: 'diagonal1',
            shadeIntensity: 1,
            gradientToColors: ['#E7E9FF', '#4856C1'],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
        }
    },
    stroke: {
        lineCap: 'round'
    },
    labels: ['Opportunities'],
}

const state = {
    series: [result],
}

const ProgressUse = () => {

    return (
        <div className={styles.progress}>
            <p className={styles.progress__title}>The possibilities of your tariff have been used</p>
            <div id="card" className={styles.progress__contentWrapper}>
                <div id="chart" className={styles.progress__radialBar}>
                    <ReactApexChart options={options} series={state.series} type="radialBar" height={320}/>
                </div>
                <div className={styles.progress__textContent}>
                    <p className={styles.progress__text}>
                        <span className={styles.progress__textUser}>{userName}, </span>
                        you use <span>{result}%</span> of all possibilities of the BePosted platform. To improve the
                        indicators you can:
                    </p>
                    <ul className={styles.progress__list}>
                        <li>Connect social networks</li>
                        <li>Fill out the profile</li>
                        <li>Fill in the company data</li>
                        <li>Add people to your team</li>
                        <li>Create from 5 posts/day</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProgressUse;