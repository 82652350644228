import React from "react";
import {Link} from 'react-router-dom';
import styles from './Navigation.module.scss'
import {useAppSelector} from "../../../../hooks/redux";
import {getNavigation} from "../../../../store/selectors";
import {useLocation} from "react-router";

const Navigation = () => {
    const navigation = useAppSelector(getNavigation);
    const {pathname} = useLocation();
    const currentUrl = pathname.substring(1, 6);

    return (
        <div className={styles.navigationWrapper}>
            {navigation && (
                <>
                    {navigation.map((link, index) =>
                        <Link
                            key={index}
                            className={link.url === '/blogs/' && currentUrl === 'blogs' ? styles.currentLink : styles.navigationLink}
                            to={link.url}
                        >
                            {link.title}
                        </Link>
                    )}
                </>
            )}
        </div>
    )
}

export default Navigation;