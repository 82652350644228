import React, {useState} from "react";
import {Event} from "../../../store/calendar.types";
import styles from "./Calendar.module.scss";
import add from '../../../assets/img/AddPlus.svg'
import EventsPopup from "./CalendarPopups/EventsPopup";
import EventViewPopup from "./CalendarPopups/EventViewPopup";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../../hooks/redux";
import { setDateForPost } from "../../../store/calendar-slice";

interface DayEventsProps {
    day: number,
    date: string,
    current: boolean
    posts: Event[],
}

const DayEvents = ({day, date, posts, current}: DayEventsProps) => {
    const [events, setEvents] = useState<Event[] | null>(null);
    const [eventId, setEventId] = useState<number | null>(null)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const showPosts = () => {
        setEvents([...posts])
    }

    const addPost = () => {
        const tab = document.getElementById('create');

        if (tab) {
            dispatch(setDateForPost(date));
            tab.click();
            navigate('/create/add')
        }
    }

    const now = new Date();
    const dateDay = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
    const currentDate = `${now.getFullYear()}-${now.getMonth() + 1}-${dateDay}`;

    const hidePopup = (value: string) => {
        if (value === 'close') {
            setEvents(null);
            setEventId(null);
        } else {
            setEventId(null);
        }
    }

    const getPostId = (id: number) => {
        setEventId(id)
    }

    events || eventId ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    return (
        <div className={
            current && date !== currentDate ? styles.dayEvent :
                !current ? styles.dayEventDisable :
                    date === currentDate ? styles.dayEventCurrent : ''}
        >
            <div className={styles.dayEvent__header}>
                <span className={styles.dayEvent__day}>{day}</span>
                {date > currentDate || date === currentDate ?
                    <button onClick={() => addPost()}>
                        <img src={add} alt=""/>
                    </button>
                    : null}
            </div>
            {posts.length > 0 && (
                <div className={styles.eventsContent} onClick={() => showPosts()}>
                    <ul className={styles.eventsContent__list}>
                        {posts.map((item, index) => <li key={index}>{index + 1}. {item.title}</li>)}
                    </ul>
                </div>
            )}
            {events && !eventId && (
                <EventsPopup post={events} hidePopup={hidePopup} day={day} date={date} getPostId={getPostId} />
            )}
            {eventId && (
                <EventViewPopup hidePopup={hidePopup} id={eventId} />
            )}
        </div>
    )
}

export default DayEvents;