import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from './Company.module.scss';


const CompanyTabs = ({setContentTab}:{setContentTab: (setContentTab: string) => void}) => {
    const [value, setValue] = useState('team');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setContentTab(newValue);
    };

    return (
        <Box sx={{ width: '100%', height: '91px',  borderColor: 'divider', borderBottom: '1px solid #F6F6F6'}}>
            <Tabs
                sx={{width: '100%', height: '91px'}}
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
            >
                <Tab
                    sx={{height: '91px', width: '33.3%', borderRight: '1px solid #F6F6F6'}}
                    label={'Team'}
                    value={'team'}
                    className={styles.customTub}
                />
                <Tab
                    sx={{height: '91px', width: '33.4%', borderRight: '1px solid #F6F6F6'}}
                    label={'Social network'}
                    value={'social'}
                    className={styles.customTub}
                />
                <Tab
                    sx={{height: '91px', width: '33.3%'}}
                    label={'Company'}
                    value={'company'}
                    className={styles.customTub}
                />
            </Tabs>
        </Box>
    )
}

export default CompanyTabs;