import React, {useEffect, useState} from "react";
import styles from "./Profile.module.scss";
import PersonalBlock from "./PersonalBlock";
import ContactBlock from "./ContactBlock";
import TariffBlock from "./TariffBlock";
import InfoBoxes from "../../Posting/Home/InfoBoxes/InfoBoxes";
import {getUserInfo, sendUserInfo} from "../../../api/api";
import {getAccessToken} from "../../../api/localStorage";


const Profile = () => {
    const datePlaceholder = '1970-01-01';
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [date, setDate] = useState(datePlaceholder);
    const [gender, setGender] = useState<string>('Other');
    const [dateFromServer, setDateFromServer] = useState(datePlaceholder);
    const [genderFromServer, setGenderFromServer] = useState('Other');
    const [phoneNumber, setPhoneNumber] = useState<string>('+380');
    const [phoneFromServer, setPhoneFromServer] = useState<string>('+380');
    const [email, setEmail] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [countryFromServer, setCountryFromServer] = useState<string>('');
    const [imgSrc, setImgSrc] = useState<string>('');
    const [userToken, setUserToken] = useState<string>('');

    useEffect(() => {
        getAccessToken().then((token) => {
            if (token) {
                getUserInfo(token).then((res) => {
                    console.log(res);
                    if (res) {
                        if (res.first_name) {setFirstName(res.first_name)}
                        if (res.last_name) {setLastName(res.last_name)}
                        if (res.date_of_birth) {setDate(res.date_of_birth); setDateFromServer(res.date_of_birth);}
                        if (res.gender) {
                            let capitalizedGender = res.gender.charAt(0).toUpperCase() + res.gender.slice(1);
                            setGender(capitalizedGender);
                            setGenderFromServer(capitalizedGender);
                        }
                        if (res.phone_number) {setPhoneNumber(res.phone_number); setPhoneFromServer(res.phone_number);}
                        if (res.email) {setEmail(res.email)}
                        if (res.country) {setCountry(res.country); setCountryFromServer(res.country);}
                        if (res.profile_picture) {setImgSrc(res.profile_picture)}
                    }
                })
                setUserToken(token);
            }
        });
    }, []);

    const changeDate = (value: string) => {
        setDate(value);
    }

    const changeGender = (value: string) => {
        setGender(value);
    }

    const changeNumber = (value:string) => {
        setPhoneNumber(value);
    }

    const changeCountry = (value:string) => {
        setCountry(value);
    }

    const savePersonalInfo = () => {
        let savedGender = gender !== genderFromServer? gender.toLowerCase() : null;
        let savedDate = date !== dateFromServer ? date : null;
        if (savedGender || savedDate) {
            sendUserInfo(userToken, savedDate, savedGender).then((result) => {
                console.log(result);
            })
        }
    }

    const saveContactInfo = () => {
        let savedPhone = phoneNumber !== phoneFromServer? phoneNumber : null;
        let savedCountry = country !== countryFromServer ? country : null;
        if (savedPhone !== null || savedCountry !== null) {
            sendUserInfo(userToken, null, null, savedPhone, savedCountry).then((result) => {
                console.log(result);
            })
        }
    }

    return (
        <div>
            <div className={styles.columnWrap}>
                <div className={styles.leftColumn}>
                    <PersonalBlock firstName={firstName} lastName={lastName} birthDate={date} changeBirthDate={changeDate} userGender={gender} changeUserGender={changeGender} profilePicture={imgSrc} savePersonalInfo={savePersonalInfo}/>
                    <ContactBlock userEmail={email} number={phoneNumber} setNumber={changeNumber} country={country} setCountry={changeCountry} saveContactInfo = {saveContactInfo}/>
                </div>
                <div className={styles.rightColumn}>
                    <TariffBlock/>
                </div>
            </div>
            <InfoBoxes/>
        </div>
    )
}

export default Profile;