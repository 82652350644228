import React from "react";
import styles from './Integration.module.scss'
import IntegrationContent from "./InegrationContent/IntegrationContent";


const Integrations = () => {
    return (
        <div id='integration' className={styles.wrapper}>
            <p className={styles.subtitle}>Supported social networks</p>
            <h2 className={styles.title}>Integration</h2>
            <IntegrationContent />
        </div>
    )
}

export default Integrations;