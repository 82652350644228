import styles from "./SendEmail.module.scss";
import React from "react";
import {useFormik} from "formik";
import {sendEmail} from "../../../api/api";


const SendEmail = ({textButton} : {textButton: string}) => {

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            emailStarted: '',
        },

        onSubmit: (values) => {
            console.log(values);
            setSubmitting(false);
            resetForm();
            // sendEmail(values)

            if (textButton === 'Subscribe') {
                sendEmail(values.emailStarted)
            }
        },
    });

    return (
        <form className={styles.fieldBox} onSubmit={handleSubmit}>
            <input
                className={styles.fieldBox__input}
                id="emailStarted"
                name="emailStarted"
                type="email"
                onChange={handleChange}
                value={values.emailStarted}
                placeholder={'Enter you email'}
            />
            <button
                className={styles.fieldBox__button}
                type="submit"
                disabled={isSubmitting}
            >
                {textButton}
            </button>
        </form>
    )
}

export default SendEmail;