import React from "react";
import styles from './createCompany.module.scss'

const Steps = ({step}:{step: number}) => {
    return (
        <div className={styles.steps}>
            <div className={styles.steps__stepBox}>
                <span>1</span>
                <div>
                    <h5>Step 1</h5>
                    <p>Company information</p>
                </div>
            </div>
            <div className={step > 1 ? styles.steps__stepBox : styles.steps__stepBoxDisable}>
                <span>2</span>
                <div>
                    <h5>Step 2</h5>
                    <p>Connecting social profiles</p>
                </div>
            </div>
            <div className={step > 2 ? styles.steps__stepBox : styles.steps__stepBoxDisable}>
                <span>3</span>
                <div>
                    <h5>Step 3</h5>
                    <p>Successful connection</p>
                </div>
            </div>
        </div>
    )
}

export default Steps;