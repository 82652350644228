import React, {useState} from "react";
import styles from "./LoginRegister.module.scss";
import {useFormik} from "formik";

import { InputMask } from '@react-input/mask';

import type { MaskEventDetail, MaskEvent, MaskEventHandler, Modify } from '@react-input/mask';


const SecondAuthentication = () => {
    const [detail, setDetail] = useState<MaskEventDetail | null>(null);
    const handleMask: MaskEventHandler = (event) => {
        setDetail(event.detail);
    };

    const modify: Modify = (input) => {
        return undefined;
    };

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            code: '',
        },

        onSubmit: (values) => {
            console.log(values);
            setSubmitting(false);
            resetForm();
        },
    });

    return (
        <form className={styles.authForm} onSubmit={handleSubmit}>
            <h2 className={styles.authForm__title}>Two factor authentication</h2>
            <p className={styles.authForm__text}>Check your email.
                A confirmation code has been sent to your email al****@gmail.con</p>
            <label className={styles.authForm__label} htmlFor={`name`}>Confirmation code</label>
            <InputMask mask="__-__-__" replacement="_"
                       modify={modify}
                       onMask={handleMask}
                       className={styles.authForm__input}
                       id="code"
                       name="code"
                       type="text"
                       onChange={handleChange}
                       value={values.code}
                       maxLength={8}
                       placeholder={'-- -- --'}
            />
            <div className={styles.remember}>
                <a className={styles.link} href="#">Resend code</a>
            </div>
            <div className={`${styles.send} ${styles.code}`}>
                <button
                    className={styles.send__buttonLogin}
                    type="submit"
                    disabled={isSubmitting}
                >
                    <span className={styles.send__textLogin}>Send</span>
                </button>
            </div>
        </form>
    )
}

export default SecondAuthentication;