import React from "react";
import styles from './FeedbackContent.module.scss';
import FeetBackForm from "./FeetBackForm/FeetBackForm";
import feetBackImg from '../../../../assets/img/feetbackImg.svg'

const FeedbackContent = () => {
    return (
        <div className={styles.FeedbackContent}>
            <FeetBackForm />
            <img src={feetBackImg} alt="image"/>
        </div>
    )
}

export default FeedbackContent;