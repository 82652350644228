import React from "react";
import styles from "./SocialNetCHeckboxes.module.scss";
import {SocialNetwork} from "../../../store/main-slice.types";

interface SocialNetCheckboxesProps {
    socials: SocialNetwork[],
    addSocial: (addSocial: string) => void,
    multiple?: boolean
}

const SocialNetCheckboxes = ({socials, addSocial, multiple = false}: SocialNetCheckboxesProps) => {
    return (
        <div className={styles.checkboxes}>
            {socials && (
                socials.map((item, index) => <div key={index} className={`${styles.socialItem} customStyle`}>
                    <img className={styles.socialItem__image} src={item.icon} alt={item.title}/>
                    <span className={styles.socialItem__title}>{item.title}</span>
                    <input
                        className={styles.custom_checkbox}
                        name="socials"
                        id='socials'
                        type={multiple ? 'checkbox' : 'radio'}
                        onChange={() => addSocial(item.title)}
                    />
                </div>)
            )}
        </div>
    )
}

export default SocialNetCheckboxes;