import React from "react";
import {styled} from "@mui/material/styles";
import Switch, {SwitchProps} from "@mui/material/Switch";
import styles from './SwitchBox.module.scss';
import FormControlLabel from "@mui/material/FormControlLabel";


interface SwitchBoxProps {
    isConnect: boolean,
    title: string,
    text: string,
    setIsConnect: (setIsConnect: boolean) => void
}

const SwitchBox = ({isConnect, title, text, setIsConnect} : SwitchBoxProps) => {


    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#4856C1' : '#4856C1',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    return (
        <div className={styles.notification}>
            <div className={styles.notification__text}>
                <h5>{title}</h5>
                <p>{text}</p>
            </div>
            <FormControlLabel
                onChange={() => setIsConnect(!isConnect)}
                control={<IOSSwitch sx={{ m: 1 }} defaultChecked={isConnect} />}
                label=''
            />
        </div>
    )
}

export default SwitchBox;