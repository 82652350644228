import React from "react";
import styles from './BlogBox.module.scss';
import {blog} from '../../../../../store/blogs-slice.types';
import { Link } from 'react-router-dom';


const BlogBox:React.FC<blog> = ({code, preview_image, title, short_description, created_at}) => {
    return (
        <div className={styles.blogBox}>
            <img className={styles.blogBox__image} src={preview_image} alt=""/>
            <div className={styles.blogBox__content}>
                <h5 className={styles.blogBox__title}>{title}</h5>
                <p className={styles.blogBox__text}>{short_description}</p>
                <div className={styles.blogBox__footer}>
                    <span className={styles.blogBox__date}>test</span>
                    <Link to={`blogs/${code}`} className={styles.blogBox__readMore}>
                        Read more
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default BlogBox;