import {createSlice} from '@reduxjs/toolkit';
import {blog, Blogs} from "./blogs-slice.types";
const initialState: Blogs = {
    blogs: [],
    homeBlogs: [],
    postBlogs: [],
};

export const blogsSlice = createSlice({
    name: 'blogsPage',
    initialState,
    reducers: {
        setBlogs(state, actions) {
            state.blogs = actions.payload;

            let homeArr = [];
            let postArr = [];
            if (actions.payload.length >= 5) {
                for (let x = 0; x <= 5; x++) {
                    homeArr.push(actions.payload[x])
                }
            } else homeArr = actions.payload;

            if (actions.payload.length >= 2) {
                for (let i = 0; i <= 2; i++) {
                    postArr.push(actions.payload[i])
                }
            } else postArr = actions.payload;

            state.homeBlogs = homeArr;
            state.postBlogs = postArr;
        },
        addBlogs(state, actions) {
            actions.payload.map((item: blog) => state.blogs.push(item))
        }
    },
});

export default blogsSlice.reducer;
export const {
    setBlogs,
    addBlogs,
} = blogsSlice.actions;
