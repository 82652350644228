import React from "react";
import styles from './BePostedContent.module.scss';
import image from '../../../../assets/img/bePosted.svg'
import ContentBoxes from "./ContentBoxes/ContentBoxes";

const BePostedContent = () => {
    return (
        <div className={styles.content}>
            <img src={image} alt=""/>
            <ContentBoxes />
        </div>
    )
}

export default BePostedContent;