import React, {useState} from "react";
import {Person} from "../../../../store/main-slice.types";
import styles from './CompanyInfo.module.scss';
import AddMembers from "../AddMembers/AddMembers";


const CompanyTeam = ({team}: { team: Person[] }) => {
    const [showInviteMembers, setShowInviteMembers] = useState(false)
    showInviteMembers ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    return (
        <div className={styles.team}>
            <div className={styles.team__header}>
                <div className={styles.team__headerInfo}>
                    <h5>Members</h5>
                    <p>{team.length} members in your team</p>
                </div>
                <button onClick={() => setShowInviteMembers(true)}>Invite Members</button>
            </div>
            <div className={styles.team__listLabel}>
                <span>Name</span>
                <span>Role</span>
                <span>Actions</span>
            </div>
            {team.length > 1
                ? team.map((person, index) =>
                    <div key={index} className={styles.team__user}>
                        <div className={styles.team__userInfo}>
                            <img src={person.photo} alt=""/>
                            <span>{person.name}</span>
                        </div>
                        <p>{person.role}</p>
                        <button>
                            <span className={styles.team__userButton} />
                        </button>
                    </div>)
                : null}
            {showInviteMembers && (
                <AddMembers setShowInviteMembers={setShowInviteMembers} />
            )}
        </div>
    )
}

export default CompanyTeam;