import React from "react";
import SocialMedia from "./SocialMedia/SocialMedia";
import StartedField from "./StartedField/StartedField";
import styles from './ContentSocial.module.scss';

const ContentSocial = () => {
    return (
        <div className={styles.socialWrapper}>
            <SocialMedia />
            <StartedField />
        </div>
    )
}

export default ContentSocial;