import React, {useState} from "react";
import {Account} from "../../../../store/main-slice.types";
import styles from './CompanyInfo.module.scss';
import AccountBox from "./AccountBox";
import AddAccount from "../AddAccount/AddAccount";
import {useAppSelector} from "../../../../hooks/redux";
import {getConnectedSocialNetworks} from "../../../../store/selectors";

interface accountProps {
    created_date: string,
    id: number,
    is_active: boolean,
    is_deleted: boolean,
    name: string,
    social_network: number,
    updated_date: string,
    user: number,
    socialName?: string,
    posts?: number | null,
    followers?: number | null,
    accountIcon?: string | null,
}


const CompanyAccounts = ({accounts}: { accounts: Account[] }) => {
    const [addAccountPopup, setAddAccountPopup] = useState(false);
    const networks = useAppSelector(getConnectedSocialNetworks);


    addAccountPopup ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    return (
        <div className={styles.accounts}>
            <div className={styles.accounts__header}>
                <div className={styles.accounts__headerInfo}>
                    <h5>Accounts</h5>
                    <p>{accounts.length} accounts and 3 social networks connected in your company</p>
                </div>
                <button onClick={() => setAddAccountPopup(true)}>Add account</button>
            </div>
            <div className={styles.accounts__content}>
                {networks && networks.length > 0
                    ? networks.map((account:accountProps , index) => account.is_active && !account.is_deleted ? <AccountBox
                        key={index}
                        socialName={account.socialName}
                        posts={account.posts}
                        followers={account.followers}
                        accountName={account.name}
                        accountId={account.id}
                        accountIcon={account.accountIcon}/> : null)
                    : null}
            </div>
            {addAccountPopup && (
                <AddAccount setAddAccountPopup={setAddAccountPopup}/>
            )}
        </div>
    )
}

export default CompanyAccounts;