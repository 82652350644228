import React from "react";
import styles from './FooterContent.module.scss'
import ContentInfo from "./ContentInfo/ContentInfo";
import ContentMenu from "./ContentMenu/ContentMenu";
import ContentSocial from "./ContentSocial/ContentSocial";

const FooterContent = () => {

    return (
        <div className={styles.content}>
            <ContentInfo />
            <ContentMenu />
            <ContentSocial />
        </div>
    )
}

export default FooterContent;