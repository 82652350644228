import React, {useEffect, useRef, useState} from "react";
import styles from './PostHeader.module.scss'
import notification from '../../../assets/img/notification.svg';
import message from '../../../assets/img/message.svg';
import user from '../../../assets/img/User.svg';
import userWhite from '../../../assets/img/UserWhite.svg';
import search from '../../../assets/img/Search.svg';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getSize} from "../../../store/selectors";
import PostHeaderTitle from "./PostHeaderTitle";
import {useLocation, useNavigate} from "react-router";
import {setSocialNetworks, resetUser} from "../../../store/main-slice";


const PostHeader = () => {
    const [notificationActive, seNotificationActive] = useState(false);
    const [messageActive, setMessageActive] = useState(true);
    const [dropdown, setDropdown] = useState(false);
    const sizeNavBar = useAppSelector(getSize);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();

    const styleSearch = {
        'backgroundImage': `url(${search})`,
        'backgroundRepeat': 'no-repeat',
        'backgroundPosition': '16px 50%',
    }

    const toggleDropdown = () => {
        setDropdown((prevState) => !prevState);
    }

    const handleLinkClick = (path: string, shouldLogout: boolean = false) => {
        setDropdown(false);
        if (shouldLogout) {
            dispatch(setSocialNetworks([]))
            localStorage.removeItem("accessToken");
            localStorage.removeItem("accessTokenTimer");
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshTokenTimer');

            setTimeout(() => {
                navigate('/');
                dispatch(resetUser(null));
            }, 100)
        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        function handleOutsideEvent(event: MouseEvent | TouchEvent) {
            const target = event.target as Node;

            if (dropdownRef.current && !dropdownRef.current.contains(target)) {
                setDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleOutsideEvent);
        document.addEventListener("touchstart", handleOutsideEvent);

        return () => {
            document.removeEventListener("mousedown", handleOutsideEvent);
            document.removeEventListener("touchstart", handleOutsideEvent);
        };

    }, [dropdownRef]);

    return (
        <div className={sizeNavBar ? styles.headerWrapperSize : styles.headerWrapper}>

            <PostHeaderTitle />
            <div className={styles.headerBar}>
                {/*<input style={styleSearch} className={styles.headerBar__search} type="search" placeholder='Search'/>*/}
                {/*<a className={notificationActive ? styles.headerBar__linkActive : styles.headerBar__link} href="#">*/}
                {/*    <img src={notification} alt="notification"/>*/}
                {/*</a>*/}
                {/*<a className={messageActive ? styles.headerBar__linkActive : styles.headerBar__link} href="#">*/}
                {/*    <img src={message} alt="message"/>*/}
                {/*</a>*/}
                <div className={styles.headerBar__linkWrap} ref={dropdownRef}>
                    <a className={pathname === '/settings/profile/' ? styles.headerBar__linkPage : styles.headerBar__link} href="#" onClick={() => toggleDropdown()}>
                        <img src={pathname === '/settings/profile/' ? userWhite : user} alt="user"/>
                    </a>
                    <div className={dropdown ? styles.dropdownActive : styles.dropdownInactive}>
                        <button onClick={() => handleLinkClick('/settings/profile/')}>Profile</button>
                        <button onClick={() => handleLinkClick('/', true)}>Log out</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostHeader;