import React, {useEffect} from "react";
import Header from "../Header/Header";
import styles from "../HomePage.module.scss";
import Footer from "../Footer/Footer";
import arrowLeft from '../../../assets/img/arrowLeft.svg'
import {useNavigate} from "react-router-dom";
import BlogList from "./BlogList";

const BlogPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <Header />
            <div className={styles.homePageWrapper}>
                <button className={styles.back} onClick={() => navigate(-1)}>
                    <img src={arrowLeft} alt=""/> <span>Back</span>
                </button>
                <BlogList />
            </div>
            <Footer />
        </>
    )
}

export default BlogPage;