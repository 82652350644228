import React, {useState} from "react";
import {useAppSelector} from "../../../../hooks/redux";
import {getSocialNetworks} from "../../../../store/selectors";
import styles from './ConnectSocial.module.scss';
import SocialNetCheckboxes from "../../../Common/SocialNetCheckboxes/SocialNetCheckboxes";


const ConnectSocial = ({setStep}:{setStep: (setStep: number) => void}) => {
    const socials = useAppSelector(getSocialNetworks);
    const [socialsSelected, setSocialsSelected] = useState<string[]>([]);

    const addSocial = (value: string) => {
        if (socials.length) {
            const isItemValue = socialsSelected.find((item) => item === value);

            if (isItemValue) {
                const newArray = socialsSelected.filter((item) => item !== value);
                setSocialsSelected([...newArray]);
            } else {
                setSocialsSelected([...socialsSelected, value])
            }
        } else {
            const newArray = [`${value}`];
            setSocialsSelected(newArray)
        }
    }

    const onSubmit = () => {
        console.log(socialsSelected);
        setStep(3);
    }

    return (
        <div className={styles.connectSocial}>
            <h3 className={styles.connectSocial__title}>
                Let's connect your social profile
            </h3>
            <p className={styles.connectSocial__subTitle}>
                You can add all social network if you want
            </p>
            <p className={styles.connectSocial__text}>
                Some text explaining that only professional or business accounts and groups can be connected.
            </p>
            <SocialNetCheckboxes socials={socials} addSocial={addSocial} />
            <div className={styles.connectSocial__actions}>
                <button
                    type='button'
                    onChange={() => setStep(1)}
                    className={styles.connectSocial__back}>
                    Back
                </button>
                <button className={styles.connectSocial__next}
                        disabled={socialsSelected.length === 0}
                        onClick={() => onSubmit()}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default ConnectSocial;