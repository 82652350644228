import React, {useState} from "react";
import styles from '../Company.module.scss'
import close from "../../../../assets/img/close.svg";
import {useAppSelector} from "../../../../hooks/redux";
import {getSocialNetworks} from "../../../../store/selectors";
import SocialNetCheckboxes from "../../../Common/SocialNetCheckboxes/SocialNetCheckboxes";
import TwitterConnect from "./TwitterConnect";
import DiscordConnect from "./DiscordConnect";
import InstagramConnect from "./InstagramConnect";
import SlackConnect from "./SlackConnect";
import TelegramConnect from "./TelegramConnect";


const AddAccount = ({setAddAccountPopup}: { setAddAccountPopup: (setAddAccountPopup: boolean) => void }) => {
    const socials = useAppSelector(getSocialNetworks);
    const [socialsSelected, setSocialsSelected] = useState('');
    const [isCreate, setIsCreate] = useState(false);
    const [connectedNetwork, setConnectedNetwork] = useState<string | null>(null)

    const addSocial = (value: string) => {
        setSocialsSelected(value);
    }

    const onSubmit = () => {
        if (socialsSelected !== '') {
            setIsCreate(true);
        }
    }

    return (
        <div className={styles.addAccountWrapper}>
            <div className={styles.addAccount}>
                <button type='button' className={styles.addAccount__closer}
                        onClick={() => setAddAccountPopup(false)}>
                    <img src={close} alt=""/>
                </button>
                <h3>Add Account</h3>
                <p className={styles.addAccount__subTitle}>Choose the social network</p>
                <p className={styles.addAccount__subText}>Choose the social network whose account you want to
                    connect</p>
                <SocialNetCheckboxes socials={socials} addSocial={addSocial}/>
                <div className={styles.addAccount__actions}>
                    <button disabled={socialsSelected.length === 0} onClick={() => onSubmit()}>Add social network
                    </button>
                </div>
            </div>
            {socialsSelected === 'X' && isCreate && (
                <TwitterConnect setConnectedNetwork={setConnectedNetwork} setIsCreate={setIsCreate}
                                setSocialsSelected={setSocialsSelected} />
            )}
            {socialsSelected === 'Discord' && isCreate && (
                <DiscordConnect setConnectedNetwork={setConnectedNetwork} setIsCreate={setIsCreate}
                                setSocialsSelected={setSocialsSelected} />
            )}
            {socialsSelected === 'Instagram' && isCreate && (
                <InstagramConnect setConnectedNetwork={setConnectedNetwork} setIsCreate={setIsCreate}
                                  setSocialsSelected={setSocialsSelected} />
            )}
            {socialsSelected === 'Slack' && isCreate && (
                <SlackConnect setConnectedNetwork={setConnectedNetwork} setIsCreate={setIsCreate}
                              setSocialsSelected={setSocialsSelected} />
            )}
            {socialsSelected === 'Telegram' && isCreate && (
                <TelegramConnect setConnectedNetwork={setConnectedNetwork} setIsCreate={setIsCreate}
                                 setSocialsSelected={setSocialsSelected} />
            )}
        </div>
    )
}

export default AddAccount;