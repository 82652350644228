import styles from "./History.module.scss";
import React from "react";
import {Post} from "../../../store/posts-slice.types";
import close from '../../../assets/img/close.svg'
import left from '../../../assets/img/left.svg'
import addFile from '../../../assets/img/addFile.svg'

interface ViewPopupProps {
    post: Post,
    hidePopup: (hidePopup: string) => void
}

const ViewPopup = ({post, hidePopup}: ViewPopupProps) => {
    const newDate = new Date(post.created_date).toLocaleDateString('en-US', {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    console.log(post)

    return (
        <div className={styles.viewWrapper}>
            <div className={styles.view}>
                <div className={styles.view__closers}>
                    <button onClick={() => hidePopup('back')}><img src={left} alt=""/><span>Back</span></button>
                    <button onClick={() => hidePopup('close')}><img src={close} alt=""/></button>
                </div>
                <h3>Detailed information on your publication</h3>
                <div className={styles.view__content}>
                    <div className={styles.view__contentInfo}>
                        <div className={styles.view__contentHeader}>
                            <span>Status: <span className={post.status === 1 ? styles.view__statusPub
                                : post.status === 2 ? styles.view__statusDraft
                                    : post.status === 3 ? styles.view__statusPost
                                        : post.status === 4 ? styles.view__statusDel : ''}>
                                {post.status === 1 ? 'Published'
                                    : post.status === 2 ? 'Draft'
                                        : post.status === 3 ? 'Postponed'
                                            : post.status === 4 ? 'Deleted' : ''}
                            </span></span>
                            <span>{newDate}</span>
                        </div>
                        <p className={styles.view__social}>Publication to: <span>{post.socialNetwork}</span></p>
                        <h5>{post.title}</h5>
                        <p className={styles.view__text}>{post.post}</p>
                        <ul className={styles.view__files}>
                            {post && post.files && post.files.length > 0
                                ? post.files.map((item, index) => <li key={index}><img src={addFile} alt=""/> {item}</li>)
                                : null}
                        </ul>
                    </div>
                    {post.picture && (
                        <div className={styles.view__contentImage}>
                            <img src={post.picture} alt=""/>
                        </div>
                    )}
                </div>
                <div className={styles.view__footer}>
                    <p>View: <span>{post.view}</span></p>
                    <p>Comments: <span>{post.comments}</span></p>
                    <p>Repost: <span>{post.repost}</span></p>
                </div>
                <div className={styles.view__actions}>
                    <button className={styles.view__actionsEdit}>Edit</button>
                    <button className={styles.view__actionsCopy}>Copy</button>
                    <button className={styles.view__actionsDelete}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default ViewPopup;