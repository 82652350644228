import React from "react";
import styles from "../FAQ.module.scss";


const PublicOfferContract = () => {
    return (
        <div className={styles.privacyPolicy}>
            <h3>Terms & Conditions</h3>
            <h3>Terms of Service</h3>
            <p>
                (Last updated: September 15th, 2023) Avivi, Inc. and/or its affiliates (“Avivi”, “we” or “us”) operate
                an online service (“Avivi”) located at www.Avivi from which you may purchase products and services.<br/>
                These Terms of Use apply to the content and functionality of the Avivi, and to the Avivi Products and
                Services.
            </p>
            <h3>1. Information that you provide to us</h3>
            <p>
                You may give us information about yourself when you visit the Avivi. We may need to send you email and
                text messages in order to, for example, verify your identity or provide you with important information.
                You authorize us to send those messages when you visit the Avivi platform and provide your contact
                details. Standard text or data charges may apply to text messages. Where offered, you may disable text
                message notifications by responding to any such message with “STOP”, or by following instructions
                provided in the message. However, by disabling text messaging, you may be disabling important security
                controls and may increase the risk of loss to your services.
            </p>
            <h3>2. Avivi IP</h3>
            <p>
                As between you and Avivi, Avivi and its licensors exclusively own all rights, title, and interest in the
                patents, copyrights (including rights in derivative works), moral rights, rights of publicity,
                trademarks or service marks, logos and designs, trade secrets, and other intellectual property embodied
                by, or contained in the Avivi (collectively, “Avivi IP”). Avivi IP is protected by copyright, trade
                secret, patent, and other intellectual property laws, and all rights in QuikNode IP not expressly
                granted to you in these Terms of Use are reserved.
                You may choose to or we may invite you to submit comments or ideas about improvements to the Avivi or
                our products or services (“Ideas”). If you submit an Idea to us, we will presume that your submission
                was voluntary, unsolicited by us, and delivered to us without any restrictions on our use of the Idea.
                You also agree that Avivi has no fiduciary or any other obligation to you in connection with any Idea
                you submit to us, and that we are free to use your Ideas without any attribution or compensation to
                you.
            </p>
        </div>
    )
}

export default PublicOfferContract;