import React from "react";
import styles from './PreviewSocials.module.scss';
import commentIcon from '../../../../assets/img/commentIcon.svg'
import telegramFile from '../../../../assets/img/telegramFile.svg'

interface TelegramProps {
    picture: any;
    description: string;
    filesData: any;
}

const Telegram = ({picture, description, filesData}: TelegramProps) => {
    const isComments = true;

    return (
        <> {picture || description.length || filesData.length ?
            <>
                {picture || (!picture && !filesData.length)
                    ? <div className={styles.telegram}>
                        {picture && (
                            <img src={picture} className={styles.telegram__picture} alt=""/>
                        )}
                        {description.length
                            ? <p className={styles.telegram__description}>{description}</p> : null}
                        {isComments && (
                            <p className={styles.telegram__comment}><img src={commentIcon} alt=""/><span>Comment</span></p>
                        )}
                    </div>
                    : <>
                        {filesData.length > 0 && (
                            filesData.map((item: any, index: number) =>
                                <div key={index} className={styles.telegramFiles}>
                                    <img src={telegramFile} alt=""/>
                                    <div className={styles.telegramFiles__content}>
                                        <p className={styles.telegramFiles__name}>{item.name}</p>
                                        <span className={styles.telegramFiles__name}>{item.size} - Download</span>
                                    </div>
                                </div>)
                        )}
                        {description.length ? <div className={styles.telegram}>
                            <p className={styles.telegram__description}>{description}</p>
                            {isComments && (
                                <p className={styles.telegram__comment}><img src={commentIcon} alt=""/><span>Comment</span></p>
                            )}
                        </div> : null}
                    </>}
            </> : null}
        </>
    )
}

export default Telegram;