import React from "react";
import styles from './SocialMedia.module.scss'
import {useAppSelector} from "../../../../../../hooks/redux";
import {getSocials} from "../../../../../../store/selectors";


const SocialMedia = () => {
    const socials = useAppSelector(getSocials);

    return (
        <div className={styles.social}>
            <p className={styles.text}>Social media</p>
            <div className={styles.icons}>
                {/*{socials && (*/}
                {/*    <>*/}
                {/*        {socials.map((item, index) =>*/}
                {/*            <a*/}
                {/*                key={index} rel="stylesheet"*/}
                {/*                href={item.url}*/}
                {/*                className={styles.socialLink}*/}
                {/*                target="_blank"*/}
                {/*            >*/}
                {/*                <img src={item.icon} alt={item.title} />*/}
                {/*            </a>)}*/}
                {/*    </>*/}
                {/*)}*/}
                <a
                    rel="stylesheet"
                    href={'https://www.facebook.com/'}
                    className={styles.socialLink}
                    target="_blank"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_866_3121)">
                            <path d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z" fill="#4F4F4F"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_866_3121">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a
                    rel="stylesheet"
                    href={'https://www.instagram.com/'}
                    className={styles.socialLink}
                    target="_blank"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_866_3123)">
                            <path d="M6.2918 18.1251C13.8371 18.1251 17.9652 11.8724 17.9652 6.45167C17.9652 6.27589 17.9613 6.0962 17.9535 5.92042C18.7566 5.33967 19.4496 4.62033 20 3.7962C19.2521 4.12896 18.458 4.34627 17.6449 4.44073C18.5011 3.92755 19.1421 3.12135 19.4492 2.17159C18.6438 2.64892 17.763 2.98563 16.8445 3.1673C16.2257 2.50976 15.4075 2.07439 14.5164 1.9285C13.6253 1.78261 12.711 1.93433 11.9148 2.3602C11.1186 2.78607 10.4848 3.46238 10.1115 4.28455C9.73825 5.10672 9.64619 6.02897 9.84961 6.9087C8.21874 6.82686 6.62328 6.40321 5.16665 5.6652C3.71002 4.9272 2.42474 3.89132 1.39414 2.62472C0.870333 3.52782 0.710047 4.59649 0.945859 5.61353C1.18167 6.63057 1.79589 7.51966 2.66367 8.10011C2.01219 8.07943 1.37498 7.90402 0.804688 7.58839V7.63917C0.804104 8.58691 1.13175 9.50561 1.73192 10.2391C2.3321 10.9726 3.16777 11.4756 4.09687 11.6626C3.49338 11.8277 2.85999 11.8518 2.2457 11.7329C2.50788 12.548 3.01798 13.2609 3.70481 13.7721C4.39164 14.2833 5.22093 14.5673 6.07695 14.5845C4.62369 15.726 2.82848 16.3452 0.980469 16.3423C0.652739 16.3418 0.325333 16.3217 0 16.2821C1.87738 17.4866 4.06128 18.1263 6.2918 18.1251Z" fill="#4F4F4F"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_866_3123">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a
                    rel="stylesheet"
                    href={'https://twitter.com/'}
                    className={styles.socialLink}
                    target="_blank"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_866_3125)">
                            <rect width="20" height="20" rx="5" fill="#4F4F4F"/>
                            <path d="M10 4.86328C7.16406 4.86328 4.86328 7.16406 4.86328 10C4.86328 12.8359 7.16406 15.1367 10 15.1367C12.8359 15.1367 15.1367 12.8359 15.1367 10C15.1367 7.16406 12.8359 4.86328 10 4.86328ZM10 13.332C8.16016 13.332 6.66797 11.8398 6.66797 10C6.66797 8.16016 8.16016 6.66797 10 6.66797C11.8398 6.66797 13.332 8.16016 13.332 10C13.332 11.8398 11.8398 13.332 10 13.332Z" fill="white"/>
                            <path d="M16.5391 4.66016C16.5391 5.32422 16 5.85938 15.3398 5.85938C14.6758 5.85938 14.1406 5.32031 14.1406 4.66016C14.1406 3.99609 14.6797 3.46094 15.3398 3.46094C16 3.46094 16.5391 4 16.5391 4.66016Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_866_3125">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a
                    rel="stylesheet"
                    href={'https://web.telegram.org/'}
                    className={styles.socialLink}
                    target="_blank"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.3749 8.74896C6.74359 6.20876 10.3236 4.5341 12.1148 3.72499C17.2292 1.41482 18.2919 1.01352 18.9846 1.00013C19.1369 0.997355 19.4776 1.03836 19.6982 1.23279C19.8845 1.39697 19.9358 1.61876 19.9603 1.77442C19.9848 1.93008 20.0154 2.28467 19.9911 2.56174C19.714 5.72419 18.5147 13.3986 17.9046 16.9406C17.6465 18.4394 17.1382 18.9419 16.6461 18.9911C15.5766 19.098 14.7645 18.2235 13.7287 17.4861C12.1078 16.3323 11.1921 15.614 9.61879 14.488C7.80053 13.1868 8.97923 12.4716 10.0154 11.3028C10.2866 10.9969 14.9987 6.34235 15.0899 5.92011C15.1013 5.8673 15.1119 5.67045 15.0042 5.56651C14.8965 5.46257 14.7376 5.49812 14.6229 5.52638C14.4604 5.56645 11.871 7.42509 6.85486 11.1023C6.11988 11.6504 5.45416 11.9174 4.8577 11.9035C4.20015 11.888 2.93528 11.4997 1.99498 11.1678C0.84166 10.7606 -0.0749729 10.5454 0.00484519 9.85392C0.0464194 9.49377 0.503103 9.12545 1.3749 8.74896Z" fill="#4F4F4F"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}

export default SocialMedia;