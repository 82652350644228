import React from "react";
import styles from "../Company.module.scss";
import member from "../../../../assets/img/member.jpg";
import client from "../../../../assets/img/client.jpg";


const ChangeRole = ({setRole}: {setRole: (setRole: string) => void}) => {
    return (
        <div className={styles.addMembers__role}>
            <div className={styles.addMembers__roleInput}>
                <input onChange={() => setRole('Member')} value={'Member'} name='member' id='member'
                       type="radio"/>
                <div className={styles.addMembers__customRadio}>
                    <img src={member} alt=""/>
                    <h5>Member</h5>
                    <p>Can do everything but no access to settings</p>
                </div>
            </div>
            <div className={styles.addMembers__roleInput}>
                <input onChange={() => setRole('Client')} value={'Client'} name='member' id='member'
                       type="radio"/>
                <div className={styles.addMembers__customRadio}>
                    <img src={client} alt=""/>
                    <h5>Client</h5>
                    <p>Can do everything but no access to settings</p>
                </div>
            </div>
        </div>
    )
}

export default ChangeRole;