import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {Company} from "../../../store/main-slice.types";
import styles from './Company.module.scss';
import emptyCompanyIcon from './../../../assets/img/emptyCompanyIcon.svg';

interface CompaniesProps {
    companies: Company[],
    setCompany: (setCompany: Company) => void,
    sizing: boolean
}

const Companies = ({companies, setCompany, sizing}: CompaniesProps) => {
    const [value, setValue] = useState(companies[0].name);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
            >
                {companies.length && (
                    companies.map((item, index) =>
                        <Tab
                            className={sizing ? styles.companySize : styles.company}
                            key={index}
                            label={<div className={styles.tabLabel}>
                                <h4>{item.name}</h4>
                                <p className={styles.tab__subText}>{item.about}</p>
                            </div>}
                            onClick={() => setCompany(item)}
                            value={item.name}
                            style={item.icon !== ''
                                ? {'backgroundImage': `url(${item.icon})`}
                                : {'backgroundImage': `url(${emptyCompanyIcon})`}}/>
                        )
                )}
            </Tabs>
        </Box>
    )
}

export default Companies;