import React, {useState} from "react";
import {useAppSelector} from "../../../../hooks/redux";
import {getIsNotification} from "../../../../store/selectors";
import styles from '../Settings.module.scss';
import SwitchBox from "../../../Common/SwitchBox/SwitchBox";

const Notification = () => {
    const isNotification = useAppSelector(getIsNotification);
    const [notification, setNotification] = useState(isNotification);

    const onSubmit = () => {
        console.log(notification);
    }

    return (
        <div className={styles.notificationBox}>
            <SwitchBox
                isConnect={notification}
                setIsConnect={setNotification}
                title={'Notification on a mail'}
                text={'Receive all notifications about changes, publications, news by mail'}
            />
            <div className={styles.actions}>
                <button disabled={notification === isNotification} onClick={() => onSubmit()}>Save</button>
            </div>
        </div>
    )
}

export default Notification;