import React from "react";
import styles from './Home.module.scss'
import InfoBoxes from "./InfoBoxes/InfoBoxes";
import Graphic from "./Graphic/Graphic";
import ScheduleTestNewLib from "./Schedule/Schedule";
import ProgressUse from "./ProgressUse/ProgressUse";
import Events from "./Events/Events";

const Home = () => {
    return (
        <div className={styles.hoveWrapper}>
            <InfoBoxes />
            <Graphic />
            <div className={styles.diagramsWrapper}>
                <ScheduleTestNewLib />
                <ProgressUse />
            </div>
            <Events />
        </div>
    )
}

export default Home;