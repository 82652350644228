import React, {useEffect, useState} from "react";
import styles from './Graphic.module.scss';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import './GraphicCustom.css';
import {useAppSelector} from "../../../../hooks/redux";
import {getPostsDay, getPostsWeek, getPostsMonth, getPostsYear} from "../../../../store/selectors";
import ButtonInterval from "./ButtonInterval";
import {posts} from "../../../../store/main-slice.types";

interface GraphicProps {
    name: string,
    graphic: posts[]
}

const Graphic = () => {
    const postsDay = useAppSelector(getPostsDay);
    const postsWeek = useAppSelector(getPostsWeek);
    const postsMonth = useAppSelector(getPostsMonth);
    const postsYear = useAppSelector(getPostsYear);
    const [timeInterval, setTimeInterval] = useState<posts[] | null>(null);

    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    const [timeIntervalArray, setTimeIntervalArray] = useState<GraphicProps[] | null>(null);

    useEffect(() => {
        if (postsWeek && postsWeek.length) {
            setTimeInterval(postsDay);
            setTimeIntervalArray([
                {name: 'Day', graphic: postsDay},
                {name: 'Week', graphic: postsWeek},
                {name: 'Month', graphic: postsMonth},
                {name: 'Year', graphic: postsYear},
            ])
        }
    }, [postsWeek]);


    return (
        <div className={styles.graphicWrapper}>
            <div className={styles.graphicHeader}>
                <h3 className={styles.graphicHeader__title}>The most publications</h3>
                <div className={styles.graphicHeader__buttons}>
                    {timeIntervalArray && timeIntervalArray.length && (
                        timeIntervalArray.map((item, index) =>
                            <ButtonInterval
                                name={item.name} graphic={item.graphic}
                                key={index} timeInterval={timeInterval}
                                setTimeInterval={setTimeInterval}/>
                            )
                    )}
                </div>
            </div>
            <p className={styles.month}>{month}</p>
            <LineChart width={1088} height={300} data={timeInterval ? timeInterval : []}>
                <Line type="monotone" dataKey="posts" stroke="#605BFF" />
                <CartesianGrid stroke="#ccc"/>
                <XAxis dataKey="name"/>
                <YAxis />
                <Tooltip />
            </LineChart>
        </div>
    )
}

export default Graphic;