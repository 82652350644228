import React, {useState} from "react";
import styles from './FAQ.module.scss'
import FAQMenuTabs from "./FAQMenuTabs/FAQMenuTabs";
import TipsForUsing from "./TipsForUsing/TipsForUsing";
import FAQList from "./FAQList/FAQList";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import PublicOfferContract from "./PublicOfferContract/PublicOfferContract";
import ContactUs from "./ContactUs/ContactUs";


const FAQ = () => {
    const [content, setContent] = useState('Tips for using')

    return (
        <div className={`${styles.faq} settings`}>
            <div className={styles.faq__menu}>
                <FAQMenuTabs setContent={setContent} />
            </div>
            <div className={styles.faq__content}>
                {content === 'Tips for using' && (
                    <TipsForUsing />
                )}
                {content === 'FAQ' && (
                    <FAQList />
                )}
                {content === 'Privacy policy' && (
                    <PrivacyPolicy />
                )}
                {content === 'Public offer contract' && (
                    <PublicOfferContract />
                )}
                {content === 'Contact us' && (
                    <ContactUs />
                )}
            </div>
        </div>
    )
}

export default FAQ;