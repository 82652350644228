import React from "react";
import styles from './InfoBoxes.module.scss'
import InfoBox from "./infoBox/infoBox";
import {useAppSelector} from "../../../../hooks/redux";
import {getStats} from "../../../../store/selectors";

const InfoBoxes = () => {
    const stats = useAppSelector(getStats)

    return (
        <div className={styles.InfoBoxList}>
            {stats && (
                stats.map((item, index) => <InfoBox
                    key={index} title={item.title} count={item.count} />)
            )}
        </div>
    )
}

export default InfoBoxes;