import React, {useState} from "react";
import styles from './LoginRegister.module.scss';
import {useFormik} from "formik";
import google from '../../assets/img/googleIcon.svg';
import {useNavigate} from "react-router";
import eye from '../../assets/img/eye.svg';
import eyeSlash from '../../assets/img/eye-slash.svg';
import {login} from "../../api/api";
import {setAllTokens, setNewAccessToken} from "../../api/localStorage";
import {setAuth} from "../../store/main-slice";
import {useAppDispatch} from "../../hooks/redux";

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [isError, setIsError] = useState(false);

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: '',
        },

        onSubmit: (values) => {
            login(values.email, values.password).then((result) => {
                if (result && result.detail) {
                    setIsError(true);
                }
                if (result && result.refresh && values.remember) {
                    setAllTokens(result.access, result.refresh);

                    setTimeout(() => {
                        dispatch(setAuth(true));
                    }, 50)
                    navigate('/');

                } else if (result.access) {
                    setNewAccessToken(result.access);

                    setTimeout(() => {
                        dispatch(setAuth(true));
                    }, 50)
                    navigate('/');
                }
            })

            setSubmitting(false);
            resetForm();
        },
    });

    return (
        <form className={styles.authForm} onSubmit={handleSubmit}>
            <h2 className={styles.authForm__title}>Welcome back</h2>
            <label className={styles.authForm__label} htmlFor={`name`}>Email address</label>
            <input
                className={isError ? styles.authForm__inputError : styles.authForm__input}
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                onFocus={() => setIsError(false)}
                placeholder={'Your email'}
            />
            <label className={styles.authForm__label} htmlFor={`email`}>Password</label>
            <div className={styles.password}>
                <input
                    className={isError ? styles.authForm__inputError : styles.authForm__input}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    value={values.password}
                    onFocus={() => setIsError(false)}
                    placeholder={'Your password'}
                />
                <button type={'button'} className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>
                    <img src={showPassword ? eyeSlash : eye} alt="show"/>
                </button>
            </div>
            <div className={styles.remember}>
                <div>
                    <input
                        id="remember"
                        name="remember"
                        onChange={handleChange}
                        value={values.remember}
                        type="checkbox"
                    />
                    <label className={styles.remember__label} htmlFor={`remember`}>Remember me</label>
                </div>
                <a className={styles.link} href="#">Forgot password?</a>
            </div>
            <div className={styles.send}>
                <button
                    className={styles.send__buttonGoogle}
                    type={'button'}
                    disabled={isSubmitting}
                >
                    <span className={styles.send__textGoogle}>
                        <img className={styles.send__icon} src={google} alt="google"/>Log in with Google
                    </span>
                </button>
                <button
                    className={styles.send__buttonLogin}
                    type="submit"
                    disabled={isSubmitting}
                >
                    <span className={styles.send__textLogin}>Log In</span>
                </button>
            </div>
            <div className={styles.register}>
                <p className={styles.register__text}>
                    Don’t have an account?
                    <button onClick={() => navigate('/register')} className={styles.register__link}>
                        Sign up here
                    </button>
                </p>
            </div>
        </form>
    )
}

export default SignIn;