import React from "react";
import styles from './LoginRegister.module.scss';
import SignIn from "./SignIn";
import SignUp from "./signUp";
import AdvertisingBox from "./AdvertisingBox/AdvertisingBox";
import SecondAuthentication from "./SecondAuthentication";

interface LoginRegisterProps {
    register?: boolean,
    secondAuthentication?: boolean,
    login?: boolean
}

const LoginRegister = ({register = false, login = false, secondAuthentication = false}:LoginRegisterProps) => {
    return (
        <div className={styles.LoginRegister}>
            {register ? <SignUp /> : login ? <SignIn /> : secondAuthentication ? <SecondAuthentication /> : ''}
            <AdvertisingBox />
        </div>
    )
}

export default LoginRegister;