import React from "react";
import styles from '../FAQ.module.scss';
import {useFormik} from "formik";


const ContactUs = () => {
    const {handleSubmit, handleChange, isSubmitting, values, setSubmitting,resetForm} = useFormik({
        initialValues: {
            email: '',
            theme: '',
            description: '',
        },
        onSubmit: (values) => {
            console.log(values);
            resetForm();
            setSubmitting(true);
        },

    });
    return (
        <div className={styles.contractUs}>
            <h3>Contact with us</h3>
            <p>
                If you have any questions related to the BePosting service that you cannot resolve on your own, or
                suggestions or complaints in this case, please contact us. To do this, you need to fill out the form
                below and our manager will contact you in the near future.
            </p>
            <div className={styles.contractUs__form}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.textField}>
                        <label className={styles.label} htmlFor={`email`}>Email</label>
                        <input
                            className={styles.textInput}
                            type="email"
                            name="email"
                            id='email'
                            onChange={handleChange}
                            value={values.email}
                            placeholder={'Write the email'}
                        />
                    </div>
                    <div className={styles.textField}>
                        <label className={styles.label} htmlFor={`theme`}>Theme of the letter</label>
                        <input
                            className={styles.textInput}
                            type="text"
                            name="theme"
                            id='theme'
                            onChange={handleChange}
                            value={values.theme}
                            placeholder={'Write theme'}
                        />
                    </div>
                    <div>
                        <label className={styles.label} htmlFor={`description`}>Description</label>
                        <div className={styles.textareaBox}>
                        <textarea
                            className={styles.textTextarea}
                            name="description"
                            id='description'
                            onChange={handleChange}
                            value={values.description}
                            placeholder={'Write a description for your letter'}
                        />
                        </div>
                    </div>
                    <div className={styles.contractUs__actions}>
                        <button disabled={isSubmitting} type='submit' className={styles.companyEdit__save}>Save</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactUs;