import React from "react";
import styles from './StartedField.module.scss'
import SendEmail from "../../../../../Common/SendEmail/SendEmail";

const StartedField = () => {

    return (
        <div className={styles.fieldBox}>
            <p className={styles.fieldBox__text}>Enter your email to get started</p>
            <SendEmail textButton='Get started' />
        </div>
    )
}

export default StartedField;