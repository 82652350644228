import React, {useEffect, useState} from "react";
import styles from './Create.module.scss';
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {getAccessToken} from "../../../api/localStorage";
import {getConnectedDiscordAccounts, getDiscordChannels} from "../../../api/api";
import {Networks} from "../../../store/main-slice.types";

interface TwitterChangersProps {
    account: number;
    setAccount: (setAccount: number) => void;
    chanel: number[];
    setChanel: (setChanel: [number]) => void;
}

interface ChannelsProps {
    category: string;
    channel_id: string;
    channel_type: string;
    created_date: string
    id: number
    is_deleted: boolean
    is_support: boolean
    name: string
    server: string
    support_bot_id: null | number
}

const DiscordChangers = ({account, setAccount, chanel, setChanel}: TwitterChangersProps) => {
    const [allAccounts, setAllAccounts] = useState<Networks[]>();
    const [allChannels, setAllChannels] = useState<ChannelsProps[]>([])

    const handleChange = (event: SelectChangeEvent | any, value: string) => {

        if (value === 'setAccount' && event.target.value !== account) {
            setAccount(event.target.value);
            getAccessToken().then((token) => {
                if (token && event.target.value) {
                    getDiscordChannels(token, event.target.value).then(r => setAllChannels(r))
                }
            });

        }
        if (value === 'setSocial' && event.target.value !== chanel) {
            setChanel(event.target.value);
        }
    };

    useEffect(() => {
        getAccessToken().then((token) => {
            if (token && !allAccounts?.length) {
                getConnectedDiscordAccounts(token).then(response => {
                    if (response) {
                        const currentAccounts: Networks[] = [];
                        response.forEach((item: Networks) => {
                            if (item.is_active && !item.is_deleted) {
                                currentAccounts.push(item)
                            }
                        })
                        setAllAccounts(currentAccounts)
                    }
                });
            }
        });

    }, [allAccounts?.length])


    return (
        <>
            {allAccounts && allAccounts.length > 0 && (
                <div className={styles.socialChangedWrapper}>
                    {allAccounts && allAccounts.length > 0 && (
                        <div className={`${styles.socialChanged} post-filters creator`}>
                            <p className={styles.socialChanged__label}>select Account</p>
                            <Box sx={{width: 196}}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={account}
                                        onChange={(e) => handleChange(e, 'setAccount')}
                                    >
                                        {allAccounts && allAccounts.length && (
                                            allAccounts.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.id}</MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    )}
                    {allChannels && allChannels.length > 0 && (
                        <div className={`${styles.socialChanged} post-filters creator`}>
                            <p className={styles.socialChanged__label}>select Chanel</p>
                            <Box sx={{width: 196}}>
                                <FormControl fullWidth>
                                    <Select
                                        multiple={true}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={chanel}
                                        onChange={(e) => handleChange(e, 'setSocial')}
                                    >
                                        {allChannels && allChannels.length && (
                                            allChannels.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    )}
                </div>
            )}
        </>


    )
}

export default DiscordChangers;