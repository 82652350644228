import React from "react";
import styles from './PreviewSocials.module.scss';
import emptyPhoto from '../../../../assets/img/defaultPhoto.png';
import discordFile from '../../../../assets/img/discordFile.svg';

interface discordProps {
    picture: any;
    description: string;
    filesData: any;
}

const Discord = ({picture, description, filesData}: discordProps) => {
    const user = {name: 'Katrine', photo: ''}
    return (
        <>
            {picture || description.length || filesData.length
                ? <div className={styles.discord}>
                    <img className={styles.discord__userPhoto} src={emptyPhoto} alt=""/>
                    <div className={styles.discord__content}>
                        <div className={styles.discord__user}>
                            <span className={styles.discord__userName}>{user.name}</span>
                            <span className={styles.discord__userDate}>now</span>
                        </div>
                        {filesData.length < 1
                            ? <>
                                {description && (
                                    <p className={styles.discord__description}>{description}</p>
                                )}
                                {picture && (
                                    <img className={styles.discord__image} src={picture} alt=""/>
                                )}
                            </>
                            : <div className={styles.discord__filesWrapper}>
                                {description && (
                                    <p className={styles.discord__description}>{description}</p>
                                )}
                                {filesData.map((item: any, index: number) => <div key={index} className={styles.discord__file}>
                                    <img src={discordFile} alt=""/>
                                    <div className={styles.discord__fileInfo}>
                                        <span className={styles.discord__fileName}>{item.name}</span>
                                        <span className={styles.discord__fileSize}>{item.size}</span>
                                    </div>
                                </div>)}
                            </div>}
                    </div>
                </div> : null}
        </>

    )
}

export default Discord;