import React, {useState} from "react";
import styles from '../Company.module.scss'
import close from "../../../../assets/img/close.svg";
import {useFormik} from "formik";
import ChangeRole from "./ChangeRole";

interface AddMembersProps {
    setShowInviteMembers: (setShowInviteMembers: boolean) => void,
}

const AddMembers = ({setShowInviteMembers}: AddMembersProps) => {
    const [role, setRole] = useState('');

    const {handleSubmit, handleChange, isSubmitting, values, resetForm} = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: (values) => {
            const newValues = {...values, role,}
            console.log(newValues);
            resetForm();
        },

    });

    return (
        <div className={styles.addMembersWrapper}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.addMembers}>
                    <button type='button' className={styles.addAccount__closer}
                            onClick={() => setShowInviteMembers(false)}>
                        <img src={close} alt=""/>
                    </button>
                    <h3>Invite Member</h3>
                    <p className={styles.addMembers__subTitle}>Choose the role</p>
                    <p className={styles.addMembers__subText}>Give your member access to this company by selecting the
                        role</p>
                    <ChangeRole setRole={setRole} />
                    <div className={`${styles.textField} ${styles.addMembers__email}`}>
                        <h5>Email</h5>
                        <p>Write the email of the user you want to add to the team</p>
                        <input
                            className={styles.textInput}
                            type="email"
                            name="email"
                            id='email'
                            onChange={handleChange}
                            value={values.email}
                        />
                    </div>
                    <div className={styles.addMembers__actions}>
                        <button disabled={role === '' || values.email === '' || isSubmitting} type='submit'>Add member</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddMembers;