import styles from './ConnectsAccounts.module.scss'
import React from "react";
import close from "../../../../assets/img/close.svg";
import {useFormik} from "formik";
import {connectDiscord, getConnectedNetworks} from "../../../../api/api";
import {getAccessToken} from "../../../../api/localStorage";
import {useAppDispatch} from "../../../../hooks/redux";
import {setSocialNetworks} from '../../../../store/main-slice';

interface TwitterConnectProps {
    setIsCreate: (setIsCreate: boolean) => void,
    setConnectedNetwork: (setConnectedNetwork: string | null) => void,
    setSocialsSelected: (setSocialsSelected: string) => void,
}

const DiscordConnect = ({setConnectedNetwork, setIsCreate, setSocialsSelected}:TwitterConnectProps) => {
    const dispatch = useAppDispatch();

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            name: '',
            token: '',
            server_id: '',
            bot_id: ''
        },

        onSubmit: (values) => {
            getAccessToken().then((accessToken) => {
                if (accessToken) {
                    connectDiscord(accessToken, values.name, values.token, values.server_id, values.bot_id).then((result) => {
                        console.log(result);
                        if (result && result.created_date) {
                            getConnectedNetworks(accessToken).then((response) => {
                                dispatch(setSocialNetworks(response));
                                setSocialsSelected('')
                            })
                        }
                    })
                }
            })
        }
    });

    return (
        <div className={styles.twitterConnect}>
            <button type='button' className={styles.closer}
                    onClick={() => setIsCreate(false)}>
                <img src={close} alt=""/>
            </button>
            <h2 className={styles.twitterConnect__title}>Let's connect your profile</h2>
            <p className={styles.twitterConnect__subtitle}>Some text for connect in Discord</p>
            <form className={styles.twitterConnect__form} onSubmit={handleSubmit}>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`name`}>Name</label>
                    <input
                        className={styles.field__input}
                        id="name"
                        name="name"
                        type="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder={'Name'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`consumer_key`}>Token</label>
                    <input
                        className={styles.field__input}
                        id="token"
                        name="token"
                        type="token"
                        onChange={handleChange}
                        value={values.token}
                        placeholder={'Token'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`consumer_secret`}>Server id</label>
                    <input
                        className={styles.field__input}
                        id="server_id"
                        name="server_id"
                        type="server_id"
                        onChange={handleChange}
                        value={values.server_id}
                        placeholder={'Server id'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`access_token_secret`}>Bot id</label>
                    <input
                        className={styles.field__input}
                        id="bot_id"
                        name="bot_id"
                        type="bot_id"
                        onChange={handleChange}
                        value={values.bot_id}
                        placeholder={'Bot id'}
                    />
                </div>
                <div className={styles.actions}>
                    <button className={styles.continue} type="submit" >
                        <span>Continue</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default DiscordConnect;