import React, {useState} from "react";
import styles from './RightNavBar.module.scss';
import {useAppSelector} from "../../../hooks/redux";
import {getMySocialNetworks} from "../../../store/selectors";
import SocialLink from "./SocialLink";
import add from '../../../assets/img/AddPlus.svg'

const RightNavBar = ({setAddAccountPopup} : {setAddAccountPopup: (setAddAccountPopup: boolean) => void}) => {
    const MySocials = useAppSelector(getMySocialNetworks);

    return (
        <div className={styles.rightNavBarWrapper}>
            {MySocials && (
                MySocials.map((link, index) => <SocialLink key={index} title={link.title} icon={link.icon} />)
            )}
            <button className={styles.addButton} onClick={() => setAddAccountPopup(true)}>
                <img src={add} alt="add link"/>
            </button>

        </div>
    )
}

export default RightNavBar;