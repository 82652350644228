import React, {useState} from "react";
import styles from './Calendar.module.scss';
import {useAppSelector} from "../../../hooks/redux";
import {getCalendar, getCalendarDate} from "../../../store/selectors";
import ChangeDate from "./ChangeDate";
import Days from "./Days";
import DayEvents from "./DayEvents";


const Calendar = () => {
    const date = useAppSelector(getCalendarDate);
    const calendar = useAppSelector(getCalendar);
    const [currentDate, setCurrentDate] = useState(date);
    const [currentCalendar, setCalendar] = useState(calendar);

    return (
        <div className={styles.calendarWrapper}>
            <ChangeDate date={currentDate}/>
            <Days/>
            <div className={styles.calendar}>
                {currentCalendar && (
                    currentCalendar.map((item, index) =>
                        <DayEvents key={index}
                                   day={item.day}
                                   date={item.date}
                                   posts={item.posts}
                                   current={item.current}
                        />)
                )}
            </div>
        </div>
    )
}

export default Calendar;