import styles from './ConnectsAccounts.module.scss'
import React from "react";
import close from "../../../../assets/img/close.svg";
import {useFormik} from "formik";
import {connectTwitter, getConnectedNetworks} from "../../../../api/api";
import {getAccessToken} from "../../../../api/localStorage";
import {useAppDispatch} from "../../../../hooks/redux";
import {setSocialNetworks} from '../../../../store/main-slice';

interface TwitterConnectProps {
    setIsCreate: (setIsCreate: boolean) => void,
    setConnectedNetwork: (setConnectedNetwork: string | null) => void,
    setSocialsSelected: (setSocialsSelected: string) => void,
}

const TwitterConnect = ({setConnectedNetwork, setIsCreate, setSocialsSelected}:TwitterConnectProps) => {
    const dispatch = useAppDispatch();

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            name: '',
            consumer_key: '',
            consumer_secret: '',
            access_token: '',
            access_token_secret: ''
        },

        onSubmit: (values) => {
            getAccessToken().then((token) => {
                if (token) {
                    connectTwitter(token, values.name, values.consumer_key, values.consumer_secret, values.access_token, values.access_token_secret).then((result) => {
                        if (result && result.created_date) {
                            getConnectedNetworks(token).then((response) => {
                                dispatch(setSocialNetworks(response));
                                setSocialsSelected('')
                            })
                        }
                    })
                }
            })
        }
    });

    return (
        <div className={styles.twitterConnect}>
            <button type='button' className={styles.closer}
                    onClick={() => setIsCreate(false)}>
                <img src={close} alt=""/>
            </button>
            <h2 className={styles.twitterConnect__title}>Let's connect your profile</h2>
            <p className={styles.twitterConnect__subtitle}>Some text for connect in Twitter</p>
            <form className={styles.twitterConnect__form} onSubmit={handleSubmit}>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`name`}>Name</label>
                    <input
                        className={styles.field__input}
                        id="name"
                        name="name"
                        type="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder={'Name'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`consumer_key`}>Consumer key</label>
                    <input
                        className={styles.field__input}
                        id="consumer_key"
                        name="consumer_key"
                        type="consumer_key"
                        onChange={handleChange}
                        value={values.consumer_key}
                        placeholder={'Consumer key'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`consumer_secret`}>Consumer secret</label>
                    <input
                        className={styles.field__input}
                        id="consumer_secret"
                        name="consumer_secret"
                        type="consumer_secret"
                        onChange={handleChange}
                        value={values.consumer_secret}
                        placeholder={'Consumer secret'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`access_token`}>Access Token</label>
                    <input
                        className={styles.field__input}
                        id="access_token"
                        name="access_token"
                        type="access_token"
                        onChange={handleChange}
                        value={values.access_token}
                        placeholder={'Access Token'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`access_token_secret`}>Access Token Secret</label>
                    <input
                        className={styles.field__input}
                        id="access_token_secret"
                        name="access_token_secret"
                        type="access_token_secret"
                        onChange={handleChange}
                        value={values.access_token_secret}
                        placeholder={'Access Token Secret'}
                    />
                </div>
                <div className={styles.actions}>
                    <button className={styles.continue} type="submit" >
                        <span>Continue</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default TwitterConnect;