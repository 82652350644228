const baseUrl = 'https://dev.api.socialposting.pp.ua/api/v1/'

export const getAllBlogs = async () => {
    try {
        const response = await fetch(`${baseUrl}blog/articles/`, {
            method: "GET"
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const getMoreBlogs = async (count: number) => {
    try {
        const response = await fetch(`${baseUrl}blog/articles/?offset=${count}&load_more=true`, {
            method: "GET"
        })
        return await response.json()
    } catch (error) {
        console.log(error)
    }
}

export const getMainStatistic = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}base/statistics_main/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });

        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const getBlog = async (code: string) => {
    try {
        const response = await fetch(`${baseUrl}blog/article/${code}/`, {
            method: "GET"
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const getConnectedNetworks = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}users/accounts/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getConnectedTwitterAccounts = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}twitter/account/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getConnectedInstagramAccounts = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}instagram/account/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getConnectedTelegramAccounts = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}telegram/account/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getConnectedDiscordAccounts = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}discord/account/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getConnectedSlackAccounts = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}slack/account/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const sendEmail = (email: string) => {
    try {
        fetch(`${baseUrl}base/add_subscriber/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email})
        }).then(() => console.log('ready'));
    } catch (error) {
        return error;
    }
}

export const register = async (name: string, email: string, password: string) => {
    try {
        const response = await fetch(`${baseUrl}users/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                first_name: name,
                email: email,
                password: password,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const login = async (email: string, password: string) => {
    try {
        const response = await fetch(`${baseUrl}users/token/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const updateAccessToken = async (token: string) => {
    try {
        const response = await fetch(`${baseUrl}users/token/refresh/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh: token,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const connectTwitter = async (access: string, name: string, key: string, secret: string, token: string, token_secret: string) => {
    try {
        const response = await fetch(`${baseUrl}twitter/account/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "consumer_key": key,
                "consumer_secret": secret,
                "access_token": token,
                "access_token_secret": token_secret,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const connectDiscord = async (access: string, name: string, token: string, server_id: string, bot_id: string) => {
    try {
        const response = await fetch(`${baseUrl}discord/account/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "token": token,
                "server_id": server_id,
                "bot_id": bot_id,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const connectInstagram = async (access: string, userName: string, clientID: string, clientSecret: string, accessToken: string, name: string) => {
    try {
        const response = await fetch(`${baseUrl}instagram/account/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_name": userName,
                "app_secret": clientSecret,
                "app_id": clientID,
                "token": accessToken,
                "name": name,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const connectSlack = async (access: string, name: string, token: string) => {
    try {
        const response = await fetch(`${baseUrl}slack/account/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "token": token,
            })
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const connectTelegram = async (access: string, name: string, phone: string, code: string | null | number) => {
    try {
        const response = await fetch(`${baseUrl}telegram/account/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "phone": phone,
                "code": code,
            })
        });

        const data = await response.json();

        if (response.status !== 200 && response.status !== 201) {
            console.log ('Error with status' + response.status);
        }

        return {status: response.status, data: data};

    } catch (error) {
        console.log(error);

        return {status: null, data: { message: "Error" }};
    }
}

export const implementTelegram2FA = async (access: string, name: string, phone: string, password: string | null | number) => {
    try {
        const response = await fetch(`${baseUrl}telegram/account/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "phone": phone,
                "password": password,
            })
        });
        const data = await response.json();

        if (response.status === 400 || response.status === 401) {
            console.log ('Error with status' + response.status);
        }
        return {status: response.status, data: data};

    } catch (error) {
        console.log(error);
        return {status: null, data: { message: "Error" }};
    }
}

export const createTwitterPost = async (access: string, status: string, account: number, title: string, message: string, date: null | string) => {
    try {
        const response = await fetch(`${baseUrl}twitter/post/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                account_id: account,
                status: status,
                title: title,
                post: message,
                time: date,
            })
        })
        if (response.status === 201) {
            return true;
        } else {
            return false;
        }

    } catch (error) {
        console.log(error);
    }
}

export const createDiscordPost = async (access: string, status: any, account: any, title: string, message: string, date: any, channel: any, picture: any) => {
    try {
        const formData = new FormData();
        if (date !== null) {
            formData.append("published_date", date);
        }
        if (picture !== null) {
            formData.append("added_file", picture);
        }
        formData.append("account", account);
        formData.append("status", status);
        formData.append("text", message);
        channel.forEach((item: number) => formData.append("channels", item.toString()));

        const response = await fetch(`${baseUrl}discord/post/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
            },
            body: formData
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const createSlackPost = async (access: string, status: any, account: any, title: string, message: string, date: any, channel: any, picture: any) => {
    try {
        const formData = new FormData();
        if (picture !== null) {
            formData.append("added_file", picture);
        }
        if (date !== null) {
            formData.append("published_date", date);
        }
        formData.append("account", account);
        formData.append("post", message);
        channel.forEach((item: number) => formData.append("channels", item.toString()));
        formData.append("status", status);

        const response = await fetch(`${baseUrl}slack/post/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
            },
            body: formData
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const createTelegramPost = async (access: string, status: any, account: any, title: string, message: string, date: any, channel: any, picture: any) => {
    try {
        const formData = new FormData();
        if (picture !== null) {
            formData.append("files", picture);
        }
        formData.append("time", date);
        formData.append("account_id", account);
        formData.append("post", message);
        formData.append("title", title);
        channel.forEach((item: number) => formData.append("channel", item.toString()));
        formData.append("status", status);

        const response = await fetch(`${baseUrl}slack/post/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
            },
            body: formData
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const createInstagramPost = async (access: string, status: any, account: any, title: string, message: string, date: any, file: any) => {
    try {
        const formData = new FormData();
        formData.append("media", file);
        formData.append("published_date", date);
        formData.append("account", account);
        formData.append("status", status);
        formData.append("caption", message);
        formData.append("title", title);
        const response = await fetch(`${baseUrl}instagram/post/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
            },
            body: formData
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getSlackConnectBtn = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}slack/bot/share/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getAllHistoryPosts = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}users/history_posts`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getDiscordChannels = async (access: string, id: number) => {
    try {
        const response = await fetch(`${baseUrl}discord/channel/?account=${id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTelegramChannels = async (access: string, id: number) => {
    try {
        const response = await fetch(`${baseUrl}telegram/channel/?account_id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getSlackChannels = async (access: string, id: number) => {
    try {
        const response = await fetch(`${baseUrl}slack/channel/?account=${id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

// export const getAllTwitterPosts = async (access: string) => {
//     try {
//         const response = await fetch(`${baseUrl}twitter/post`, {
//             method: "GET",
//             headers: {
//                 'Authorization': `Bearer ${access}`,
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json'
//             },
//         })
//         return await response.json();
//     } catch (error) {
//         console.log(error);
//     }
// }

export const removeTwitterAccount = async (access: string, accountId: number) => {
    try {
        const response = await fetch(`${baseUrl}twitter/account/${accountId}/`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const removeTwitterPost = async (access: string, postId: number) => {
    try {
        const response = await fetch(`${baseUrl}twitter/post/${postId}/`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getUserInfo = async (access: string) => {
    try {
        const response = await fetch(`${baseUrl}users/profile/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}

export const sendUserInfo = async (access: string,  date?:string | null , gender?:string | null, phone_number?:string | null , country?:string | null) => {
    try {
        const bodyData: { [key: string]: string | undefined } = {};
        if (date != null) {bodyData.date_of_birth = date;}
        if (gender != null) {bodyData.gender = gender;}
        if (phone_number != null) {bodyData.phone_number = phone_number;}
        if (country != null) {bodyData.country = country;}

        const response = await fetch(`${baseUrl}users/profile/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(bodyData)
        });
        return await response.json()
    } catch (error) {
        console.log(error);
    }
}