import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import styles from "../HomePage.module.scss";
import arrowLeft from "../../../assets/img/arrowLeft.svg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {getBlog} from '../../../api/api';
import {useWindowDimensions} from 'react-native';
import RenderHtml from 'react-native-render-html';
import './blogView.css';


const BlogView = ({home = false}) => {
    const {id} = useParams();
    const [blog, setBlog] = useState()
    const navigate = useNavigate();

    const {width} = useWindowDimensions();

    useEffect(() => {
        if (id) {
            getBlog(id).then((response) => setBlog(response.content))
        }

    }, [])

    const source = {
        html: `${blog}`
    };

    const renderersProps = {
        img: {
            enableExperimentalPercentWidth: true
        }
    };

    return (
        <>
            {home && (<Header />)}
            <div className={styles.homePageWrapper}>
                <button className={styles.back} onClick={() => navigate(-1)}>
                    <img src={arrowLeft} alt=""/> <span>Back</span>
                </button>
                {blog && (
                    <RenderHtml
                        contentWidth={width}
                        source={source}
                        renderersProps={renderersProps}
                    />
                )}
            </div>
            {home && (<Footer />)}
        </>
    )
}

export default BlogView;