import { combineReducers, configureStore } from '@reduxjs/toolkit';
import mainSlice from './main-slice';
import postsSlice from "./posts-slice";
import calendarSlice from "./calendar-slice";
import blogsSlice from "./blogs-slice";


export const setupStore = () => {
  return configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};
const rootReducers = combineReducers({
  main: mainSlice,
  posts: postsSlice,
  blogs: blogsSlice,
  calendar: calendarSlice,
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
