import React from "react";
import styles from "./Profile.module.scss";

const TariffBlock = () => {

    return (
        <div className={`${styles.block} ${styles.tariffs}`}>
            <h3>Tariffs data</h3>
            <div className={styles.row}>
                <h4>Basic package</h4>
                <span>$9.99/month</span>
            </div>
            <p>
                Sed ut perspiciatis unde omnis iste natus error sit min voluptatem accusantium doloremque
            </p>
            <div className={styles.tariffs__wrap}>
                <div className={styles.tariffs__group}>
                    <div className={styles.row}>
                        <h5>Social Profiles</h5>
                        <span>2/3</span>
                    </div>
                    <div className={styles.row}>
                        <h5>User</h5>
                        <span>1/1</span>
                    </div>
                    <div className={styles.row}>
                        <h5>Posts</h5>
                        <span>64/90</span>
                    </div>
                </div>
                <div className={styles.tariffs__group}>
                    <div className={styles.row}>
                        <h5>Start date</h5>
                        <span>17.11.2023</span>
                    </div>
                    <div className={styles.row}>
                        <h5>End date</h5>
                        <span>17.12.2023</span>
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                <button className={styles.actions__improve}>
                    <span>Improve opportunities</span>
                </button>
                <button className={styles.actions__repeat}>
                    <span>Repeat payment</span>
                </button>
            </div>
        </div>
    )
}

export default TariffBlock;