import React, {useState} from "react";
import styles from "./Profile.module.scss";
import IconEdit from "../../../assets/img/edit.svg";
import DefaultPhoto from "../../../assets/img/userProfile.svg";
import IconCheck from "../../../assets/img/check.svg";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface PersonalBlockProps {
    firstName?: string,
    lastName?: string,
    birthDate: string,
    changeBirthDate: (value: string) => void,
    userGender?: string,
    changeUserGender: (value: string) => void,
    profilePicture?: string,
    savePersonalInfo: () => void
}

const PersonalBlock = ({firstName, lastName, birthDate, changeBirthDate, userGender, changeUserGender, profilePicture, savePersonalInfo}: PersonalBlockProps) => {
    const [editMode, setEditMode] = useState(false);

    function formatDate(date: string) {
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${year}`;
    }

    const handleSave = () => {
        setEditMode(false);
        savePersonalInfo();
    }

    return (
        <div className={`${styles.block} ${styles.personal}`}>
            <div className={styles.titleRow}>
                <h3>Personal information</h3>
                {editMode ? <button className={styles.saveBtnBlock} onClick={handleSave}>
                        <span>Save</span>
                        <img src={IconCheck} alt=""/>
                    </button> :
                    <button onClick={() => setEditMode(true)}>
                        <img src={IconEdit} alt=""/>
                    </button>}
            </div>
            <div className={styles.personal__imgRow}>
                <img src={profilePicture ? profilePicture : DefaultPhoto} alt=""/>
                <div>
                    <h4>{firstName} {lastName}</h4>
                    <h5>Date of birth</h5>
                    {
                        editMode ?
                            <input className={styles.textInput} type="date" name="date" id='date'
                                   onChange={(e) => changeBirthDate(e.target.value)} value={birthDate}/> :
                            <p>{formatDate(birthDate)}</p>
                    }
                    <h5>Sex</h5>
                    {
                        editMode ? <div className="timePopupBox"><Box>
                                <FormControl fullWidth>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                            value={userGender}
                                            onChange={(e: SelectChangeEvent) => changeUserGender(e.target.value)}>
                                        <MenuItem key="Male" value="Male">Male</MenuItem>
                                        <MenuItem key="Female" value="Female">Female</MenuItem>
                                        <MenuItem key="Other" value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box></div> :
                            <p>{userGender}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default PersonalBlock;