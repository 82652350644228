import React from "react";
import styles from "../InfoBoxes.module.scss";
import ButtonArrow from "../../../../Common/ButtonArrow/ButtonArrow";

const InfoBox = ({title, count}:{title: string, count: number}) => {
    return (
        <div className={styles.InfoBox}>
            <h4 className={styles.InfoBox__title}>{title}</h4>
            <div className={styles.infoBoxContent}>
                <div className={styles.infoBoxContent__count}>{count}</div>
                <ButtonArrow text={'Go to'} />
            </div>
        </div>
    )
}

export default InfoBox;