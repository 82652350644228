import React from 'react';
import Button from "../../../Common/Button/Button";
import styles from './Authorization.module.scss'


const Authorization = () => {
    return (
        <div className={styles.authorizationLinks}>
            <Button text={'Log in'} color={'white'} login />
            <Button text={'Sign up'} color={'blue'} register />
        </div>
    )
}
export default Authorization;