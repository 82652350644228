import React, {useEffect, useState} from "react";
import styles from './Company.module.scss'
import Companies from "./Companies";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getCompanies, getConnectedSocialNetworks} from "../../../store/selectors";
import resize from '../../../assets/img/resizeCompany.svg'
import CompanyTabs from "./CompanyTabs";
import CompanyTeam from "./CompanyDetails/CompanyTeam";
import CompanyAccounts from "./CompanyDetails/CompanyAccounts";
import CompanyEdit from "./CompanyDetails/CompanyEdit/CompanyEdit";
import AddCompany from "./AddCompany/AddCompany";
import {getConnectedNetworks, updateAccessToken} from "../../../api/api";
import {getAccessToken, getRefreshToken, setNewAccessToken} from "../../../api/localStorage";
import {setSocialNetworks} from "../../../store/main-slice";

const Company = () => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(getCompanies);
    const networks = useAppSelector(getConnectedSocialNetworks);
    const [company, setCompany] = useState(companies[0]);
    const [contentTab, setContentTab] = useState('team');
    const [sizing, setSizing] = useState(false);
    const [showAddCompany, setShowAddCompany] = useState(false);


    useEffect(() => {
        getAccessToken().then((token) => {
            if (token) {
                getConnectedNetworks(token).then((response) => {
                    if (response && response.code && response.code === 'token_not_valid') {
                        getRefreshToken().then((token) => {
                            if (token) {
                                updateAccessToken(token).then((res) => {
                                    if (res && res.access) {
                                        setNewAccessToken(res.access);
                                    }
                                })
                            }
                        })
                    } else if (networks !== response) {
                        dispatch(setSocialNetworks(response))
                    }
                })
            }
        });
    }, [])

    return (
        <div className={styles.companyWrapper}>
            <div className={sizing ? `${styles.companiesSize} companiesSize` : `${styles.companies} companies`}>
                <div className={sizing ? styles.companiesSize__actions : styles.companies__actions}>
                    <button onClick={() => setShowAddCompany(true)}
                        className={sizing ? styles.companies__addCompanySize : styles.companies__addCompany}>
                        {sizing ? "" : "Add new company"}
                    </button>
                    <button className={styles.companies__resize} onClick={() => setSizing(!sizing)}>
                        <img style={sizing ? {transform: 'rotateZ(180deg)'} : {transform: 'rotateZ(0deg)'}} src={resize} alt=""/>
                    </button>
                </div>
                <Companies setCompany={setCompany} companies={companies} sizing={sizing} />
                <div className={styles.companies__footer}/>
            </div>
            <div className={`${styles.companyContent} companyContent`}>
                <CompanyTabs setContentTab={setContentTab}/>
                {contentTab === 'team' && (
                    <CompanyTeam team={company.team}/>
                )}
                {contentTab === 'social' && (
                    <CompanyAccounts accounts={company.socialAccounts}/>
                )}
                {contentTab === 'company' && (
                    <CompanyEdit about={company.about} name={company.name} logo={company.icon}
                                 networkCount={company.connectedNetworks.length}
                                 accountsCount={company.socialAccounts.length}
                                 teamCount={company.team.length}
                    />
                )}
            </div>
            {showAddCompany && (
                <AddCompany setShowAddCompany={setShowAddCompany} showAddCompany={showAddCompany} />
            )}
        </div>
    )
}

export default Company;