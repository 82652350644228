import React, {useEffect} from "react";
import styles from './BlogContent.module.scss'
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {getHomeBlogs} from "../../../../store/selectors";
import BlogBox from "./BlogBox/BlogBox";
import {getAllBlogs} from "../../../../api/api";
import {setBlogs} from "../../../../store/blogs-slice";

const BlogContent = () => {
    const blogs = useAppSelector(getHomeBlogs);
    const dispatch = useAppDispatch();

    useEffect(() => {
        getAllBlogs().then((response) => {
            if (response && response.results) {
                dispatch(setBlogs(response.results))
            }
        })
    },[])

    return (
        <div className={styles.BlogContent}>
            {blogs.length > 0 && (
                blogs.map((blog, index) => <BlogBox
                    code={blog.code}
                    preview_image={blog.preview_image}
                    title={blog.title}
                    short_description={blog.short_description}
                    created_at={blog.created_at}
                    key={index}
                />)
            )}
        </div>
    )
}

export default BlogContent;