import React from "react";
import styles from './Banner.module.scss'
import SendEmail from "../../Common/SendEmail/SendEmail";
import image from '../../../assets/img/banner.svg'


const Banner = () => {
    return (
        <div style={{ backgroundImage: `url(${image})` }}>
            <div className={styles.banner}>
                <h2 className={styles.banner__title}>BePosted easy and convenient social media posting</h2>
                <p className={styles.banner__text}>
                    It's enough to switch between different applications and accounts -
                    do it quickly from a single place and without extra hassle! Schedule publications,
                    view statistics and manage your own accounts and channels in a single window.
                </p>
                <div className={styles.banner__email}>
                    <SendEmail textButton='Get started' />
                </div>
            </div>
        </div>
    )
}

export default Banner;