import React from "react";
import styles from './Events.module.scss';
import ButtonArrow from "../../../Common/ButtonArrow/ButtonArrow";
import {blog} from "../../../../store/blogs-slice.types";


const Event = ({code, title, preview_image, short_description, created_at}: blog) => {
    return (
        <div className={styles.event}>
            <img className={styles.event__image} src={preview_image} alt=""/>
            <div className={styles.event__content}>
                <h5>{title}</h5>
                <p className={styles.event__text}>{short_description}</p>
                <div className={styles.event__actions}>
                    <span>{created_at.slice(6, created_at.length)}</span>
                    <ButtonArrow code={code} text={'Read more'} />
                </div>
            </div>
        </div>
    )
}

export default Event;