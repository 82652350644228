import React from "react";
import styles from './SocialItem.module.scss'

const SocialItem = ({title, text, icon}:{title: string, text: string, icon: string}) => {

    return (
        <div className={styles.item}>
            <div className={styles.item__content}>
                <div className={styles.item__icon}>
                    <img src={icon} alt={title}/>
                </div>
                <div>
                    <h5 className={styles.item__title}>{title}</h5>
                    <p className={styles.item__text}>{text}</p>
                </div>
            </div>
            <a className={styles.item__readMore} href="#">Read more</a>
        </div>
    )
}

export default SocialItem;