import React from "react";
import styles from './Box.module.scss'

const Box = ({title, text}:{title: string, text: string}) => {
    return (
        <div className={styles.box}>
            <h4 className={styles.box__title}>{title}</h4>
            <p className={styles.box__text}>{text}</p>
        </div>
    )
}

export default Box;