import React from "react";
import styles from "../createCompany.module.scss";
import {useAppSelector} from "../../../../hooks/redux";
import {getReviews} from "../../../../store/selectors";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import ReviewSlide from "./ReviewSlide";
import { Autoplay } from 'swiper/modules';


const ReviewSlider = () => {
    const reviews = useAppSelector(getReviews);
    console.log(reviews)

    return (
        <div className={styles.reviewBox}>
            <Swiper
                direction={'vertical'}
                modules={[Autoplay]}
                spaceBetween={0}
                slidesPerView={3}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
            >
                {reviews && (
                    reviews.map((item, index) =>
                            <SwiperSlide key={index}>
                                <ReviewSlide photo={item.photo} name={item.name} date={item.date} text={item.text} />
                            </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    )
}

export default ReviewSlider;