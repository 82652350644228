import React from "react";
import styles from './header.module.scss';
import Navigation from "./Navigation/Navigation";
import Authorization from "./Authorization/Authorization";
import Logo from "./Navigation/Logo";

const Header = () => {

    return (
        <div className={styles.header}>
            <Logo />
            <Navigation />
            <Authorization />
        </div>
    )
}

export default Header;