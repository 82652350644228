import React from "react";
import styles from './Blog.module.scss'
import BlogContent from "./BlogContent/BlogContent";


const Blog = () => {
    return (
        <div className={styles.wrapper}>
            <p className={styles.subtitle}>Don't miss interesting information</p>
            <h2 className={styles.title}>Blog</h2>
            <BlogContent />
        </div>
    )
}

export default Blog;