import React, {useState} from "react";
import styles from "./Tariff.module.scss";


const ProfilesUsers = ({value, content, styleSmall}:{value: number, content: string, styleSmall: boolean}) => {

    const [count, setCount] = useState(value);

    const setProfiles = (add = false) => {
        setCount(add ? count + 1 : count - 1);
    }

    return (
        <p className={styleSmall ? styles.tariffSmall__text : styles.tariff__text}>
            <button onClick={() => setProfiles()} className={styleSmall ? styles.calcSmall : styles.calc} disabled={count === 1}>-</button>
            <span className={styles.bold}>
                {count}
            </span>
            <button onClick={() => setProfiles(true)} className={styleSmall ? styles.calcSmall : styles.calc}>+</button>
            {content}
        </p>
    )
}

export default ProfilesUsers;