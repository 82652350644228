import React from "react";
import styles from './CalendarPopups.module.scss';

interface EventItemProps {
    socialIcon: string;
    status: string;
    author: string;
    time: string;
    id: number;
    getPostId: (getPostId: number) => void,
}

const EventItem = ({socialIcon, status, author, time, id, getPostId}: EventItemProps) => {
    return (
        <li onClick={() => getPostId(id)} className={styles.eventItem}>
            <div className={styles.eventItem__leftBox}>
                <img src={socialIcon} alt=""/>
                <p>{status}</p>
            </div>
            <div className={styles.eventItem__rightBox}>
                <span>{author}</span>
                <span>{time}</span>
            </div>
        </li>
    )
}

export default EventItem;