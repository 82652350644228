import React from "react";
import styles from './About.module.scss'
import image from '../../../assets/img/about.jpg'
import AboutContent from "./AboutContent/AboutContent";

const About = () => {
    return (
        <div id='about' style={{ backgroundImage: `url(${image})`, paddingTop: '123px' }}>
            <p className={styles.subtitle}>How it works</p>
            <h2 className={styles.title}>About service</h2>
            <AboutContent />
        </div>
    )
}

export default About;