import React from "react";
import styles from './Footer.module.scss'
import FooterAuthorization from "./FooteAuthorization/FooterAuthorization";
import FooterContent from "./FooterContent/FooterContent";

const Footer = () => {

    const copyright = 'Copyright 2023,  Avivi, All Rights Reserved'

    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footer}>
                <FooterAuthorization />
                <FooterContent />
                <p className={styles.copyright}>{copyright}</p>
            </div>
        </div>
    )
}

export default Footer;