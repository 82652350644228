import React from "react";
import styles from './FeetBack.module.scss';
import FeedbackContent from "./FeedbackContent/FeedbackContent";


const FeetBack = () => {
    return (
        <div id='support' className={styles.wrapper}>
            <p className={styles.subtitle}>If necessary, contact us</p>
            <h2 className={styles.title}>Feedback</h2>
            <FeedbackContent />
        </div>
    )
}

export default FeetBack;