import React from "react";
import styles from './CreateCompanyInfo.module.scss';
import CreateCompanyForm from "./CreateCompanyForm";


const CreateCompanyInfo = ({setStep}:{setStep: (setStep: number) => void}) => {
    return (
        <div className={styles.companyInfo}>
            <h2 className={styles.companyInfo__title}>
                Let's start creating your account
            </h2>
            <p className={styles.companyInfo__subTitle}>
                Tell us a little about your company and we can customize your profile.
            </p>
            <CreateCompanyForm setStep={setStep} />
        </div>
    )
}

export default CreateCompanyInfo;