import styles from "./History.module.scss";
import emptyPic from "../../../assets/img/emptyPic.png";
import React, {useEffect, useState} from "react";
import {Post} from "../../../store/posts-slice.types";
import {getAllHistoryPosts, removeTwitterPost} from "../../../api/api";
import {getAccessToken} from "../../../api/localStorage";
import {getBasePictureSrc} from "../../../store/selectors";

interface PostTableItemProps {
    showActions: (showActions: Post) => void,
    item: Post;
    toggleMenu: number,
    setPost: (setPost: Post) => void,
    setEditPost: (setPost: Post) => void,
    setToggleMenu: (setToggleMenu: number) => void,
    setShowEditor: (setShowEditor: boolean) => void,
    setPosts: (setPosts: Post[]) => void,
}


const PostTableItem = ({showActions, item, toggleMenu, setToggleMenu, setShowEditor, setPost, setEditPost, setPosts}: PostTableItemProps) => {
    const [formatDate, setFormatDate] = useState('');
    const basePictureUrl = getBasePictureSrc;

    useEffect(() => {
        if (item && item.created_date) {
            const newDate = new Date(item.created_date).toLocaleDateString('en-US', {
                year: "numeric",
                month: "long",
                day: "numeric",});
            setFormatDate(newDate)
        }
    }, [item])

    const onDelete = (id: number) => {
        getAccessToken().then((token) => {
            if (token) {
                removeTwitterPost(token, id).then(r => {
                    if (r && r.result && r.result === 'ok') {
                        getAllHistoryPosts(token).then(result => {
                            if (result && result.posts) {
                                setPosts(result.posts)
                            }
                        })
                    }
                })
            }
        });
    }

    return (
        <tr className={styles.post}>
            <td className={styles.post__actions}>
                <button className={styles.post__togglePopup} onClick={(e) => showActions(item)}>
                    <span className={styles.post__button}/>
                </button>
                <div
                    className={toggleMenu !== item.id ? styles.post__actionsExpandHide : styles.post__actionsExpandShow}>
                    <button onClick={() => {
                        setPost(item);
                        setToggleMenu(0);
                    }}>View
                    </button>
                    {item.socialNetwork !== 'Twitter' && (
                        <button onClick={() => {
                            setShowEditor(true);
                            setToggleMenu(0);
                            setEditPost(item);
                        }
                        }>Edit</button>
                    )}
                    <button>Copy</button>
                    <button onClick={() => {
                        setShowEditor(true);
                        setToggleMenu(0);
                        onDelete(item.id);
                    }}>Delete</button>
                </div>
            </td>
            <td>
                <img className={styles.post__picture} src={item.media ? `${item.media}` : emptyPic} alt=""/>
            </td>
            <td className={styles.post__title}>{item.title}</td>
            <td className={styles.post__social}>{item.socialNetwork}</td>
            <td className={item.status === 1 ? styles.post__statusPub
                : item.status === 2 ? styles.post__statusDraft
                    : item.status === 3 ? styles.post__statusPost
                        : item.status === 4 ? styles.post__statusDel : ''}
            >{item.status === 1 ? 'Published'
                : item.status === 2 ? 'Draft'
                    : item.status === 3 ? 'Postponed'
                        : item.status === 4 ? 'Deleted' : ''}</td>
            <td className={styles.post__author}>{item.author}</td>
            <td>{formatDate}</td>
        </tr>
    )
}

export default PostTableItem;