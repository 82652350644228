import React, {useEffect, useState} from 'react';
import styles from './App.module.scss'
import {Route, Routes} from 'react-router-dom';
import HomePage from "./components/HomePage/HomePage";
import LoginRegister from "./components/LoginRegister/LoginRegister";
import Home from "./components/Posting/Home/Home";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {getSize, getIsAuth} from "./store/selectors";
import PostHeader from "./components/Posting/PostHeader/PostHeader";
import LeftNavBar from "./components/Posting/LeftNavBar/LeftNavbar";
import Create from "./components/Posting/Create/Create";
import History from "./components/Posting/History/History";
import Company from "./components/Posting/Company/Company";
import Settings from "./components/Posting/Settings/Settings";
import FAQ from "./components/Posting/FAQ/FAQ";
import './globalBase.css'
import CreateCompany from "./components/LoginRegister/CreateCompany/CreateCompany";
import Calendar from "./components/Posting/Calendar/Calendar";
import BlogPage from "./components/HomePage/BlogPage/BlogPage";
import BlogView from "./components/HomePage/BlogPage/BlogView";
import {setAuth, setSocialNetworks, setStatistic} from "./store/main-slice";
import RightNavWrapper from "./components/Posting/RightNavBar/RightNavWrapper";
import BlogList from "./components/HomePage/BlogPage/BlogList";
import {
    getAccessToken,
    getAccessTokenTimer,
    getRefreshToken,
    getRefreshTokenTimer,
    setNewAccessToken
} from "./api/localStorage";
import {getConnectedNetworks, getMainStatistic, updateAccessToken} from "./api/api";
import Profile from "./components/HomePage/Profile/Profile";


function App() {
    const isAuth = useAppSelector(getIsAuth);
    const sizeNavBar = useAppSelector(getSize);
    const dispatch = useAppDispatch();
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        if (token) {
            getConnectedNetworks(token).then((response) => {
                if (response && response.code && response.code === 'token_not_valid') {
                    console.log(response.code)
                    getRefreshToken().then((res) => {
                        console.log(res)
                        if (res) {
                            updateAccessToken(res).then((resp) => {
                                if (resp && resp.access) {
                                    setNewAccessToken(resp.access);
                                    getConnectedNetworks(resp.access).then((result) => {
                                        dispatch(setSocialNetworks(result));
                                        getMainStatistic(resp.access).then(r => dispatch(setStatistic(r)))
                                    })
                                }
                            })
                        }
                    })
                } else {
                    dispatch(setSocialNetworks(response));
                    getMainStatistic(token).then(r => dispatch(setStatistic(r)))
                }
            })
        } else {
            getRefreshToken().then((token) => {
                if (token) {
                    updateAccessToken(token).then((res) => {
                        if (res && res.access) {
                            setNewAccessToken(res.access);
                        }
                    })
                }
            })
        }
    }, [token, isAuth])

    useEffect(() => {
        getAccessToken().then(res => {
            if (res) {
                setToken(res)
            }
        })
        getRefreshToken().then((token) => {
            if (token) {
                getRefreshTokenTimer().then((response) => {
                    if (Math.round(new Date().getTime() / 1000) > Number(response)) {
                        localStorage.removeItem("refreshTokenTimer");
                        dispatch(setAuth(false));
                    } else {
                        dispatch(setAuth(true));
                    }
                })
            }
        })

        getAccessTokenTimer().then((result) => {
            if (!result) {
                dispatch(setAuth(false));
            }

            if (result) {
                dispatch(setAuth(true));
            }

            if (result && Math.round(new Date().getTime() / 1000 - 3500) > Number(result)) {

                localStorage.removeItem("accessToken");
                localStorage.removeItem("accessTokenTimer");
                getRefreshToken().then((token) => {
                    if (token) {
                        updateAccessToken(token).then((res) => {
                            if (res && res.access) {
                                setNewAccessToken(res.access);
                            }
                        })
                    }
                })
            }

        })
    }, [])

    return (
        <div className={styles.wrapper}>
            {isAuth
                ?
                <div className={sizeNavBar ? styles.contentWrapperSize : styles.contentWrapper}>
                    <PostHeader/>
                    <LeftNavBar/>

                    <RightNavWrapper/>
                    <div className={styles.wrapperContent}>
                        <Routes>
                            <Route index element={<Home/>}/>
                            <Route path={'/blogs'} element={<BlogList/>}/>
                            <Route path={'/blogs/:id/*'} element={<BlogView/>}/>
                            <Route path={'/create/*'} element={<Create/>}/>
                            <Route path={'/create/add'} element={<Create add/>}/>
                            <Route path={'/history/*'} element={<History/>}/>
                            <Route path={'/calendar/*'} element={<Calendar/>}/>
                            <Route path={'/Company/*'} element={<Company/>}/>
                            <Route path={'/settings/*'} element={<Settings/>}/>
                            <Route path={'/settings/profile/'} element={<Profile/>}/>
                            <Route path={'/faq/*'} element={<FAQ/>}/>
                        </Routes>
                    </div>
                </div>
                :
                <Routes>
                    <Route index element={<HomePage/>}/>
                    <Route path={'/blogs'} element={<BlogPage/>}/>
                    <Route path={'/blogs/:id/*'} element={<BlogView home/>}/>
                    <Route path={'/login'} element={<LoginRegister login/>}/>
                    <Route path={'/register'} element={<LoginRegister register/>}/>
                    {/*<Route path={'/login'} element={<TemporaryAdvertising />}/>*/}
                    {/*<Route path={'/register'} element={<TemporaryAdvertising />}/>*/}
                    <Route path={'/confirm-registration'} element={<LoginRegister secondAuthentication/>}/>
                    <Route path={'/create_company'} element={<CreateCompany/>}/>
                </Routes>}
        </div>
    );
}

export default App;
