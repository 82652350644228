import React from "react";
import styles from './Reviews.module.scss';
import ReviewsContent from "./ReviewsContent/ReviewsContent";


const Reviews = () => {
    return (
        <div id='reviews' className={styles.wrapper}>
            <p className={styles.subtitle}>What others say about us</p>
            <h2 className={styles.title}>Reviews</h2>
            <ReviewsContent />
        </div>
    )
}

export default Reviews;