import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import home from '../../../../assets/img/linkHome.svg';
import create from '../../../../assets/img/create.svg';
import history from '../../../../assets/img/history.svg';
import company from '../../../../assets/img/company.svg';
import faq from '../../../../assets/img/faq.svg';
import calendar from '../../../../assets/img/calendar.svg';
import settings from '../../../../assets/img/settings.svg';
import homeActive from '../../../../assets/img/homeActive.svg';
import createActive from '../../../../assets/img/createActive.svg';
import companyActive from '../../../../assets/img/companyActive.svg';
import faqActive from '../../../../assets/img/faqActive.svg';
import calendarActive from '../../../../assets/img/calendarActive.svg';
import settingsActive from '../../../../assets/img/settingsActive.svg';
import historyActive from '../../../../assets/img/historyActive.svg';
import './LeftBarNavigation.css';
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";

const LeftBarNavigation = ({size}: { size: boolean }) => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState(pathname !== '/settings/profile/' ? `${pathname}` : '/settings/');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentUrl = pathname.substring(1, 6);

    const homeIcon = pathname === '/' || currentUrl === 'blogs' ? {
        'backgroundImage': `url(${homeActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${home})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${home})`,
    }
    const createIcon = pathname === '/create/' || pathname === '/create/add' ? {
        'backgroundImage': `url(${createActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${create})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${create})`,
    }
    const historyIcon = pathname === '/history/' ? {
        'backgroundImage': `url(${historyActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${history})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${history})`,
    }
    const calendarIcon = pathname === '/calendar/' ? {
        'backgroundImage': `url(${calendarActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${calendar})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${calendar})`,
    }
    const companyIcon = pathname === '/company/' ? {
        'backgroundImage': `url(${companyActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${company})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${company})`,
    }
    const settingsIcon = pathname === '/settings/' ? {
        'backgroundImage': `url(${settingsActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${settings})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${settings})`,
    }
    const faqIcon = pathname === '/faq/' ? {
        'backgroundImage': `url(${faqActive})`,
    } : pathname === '/settings/profile/' ? {
        'backgroundImage': `url(${faq})`,
        'backgroundColor': 'transparent',
        'color': 'rgba(0, 0, 0, 0.6)',
    } : {
        'backgroundImage': `url(${faq})`,
    }

    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                TabIndicatorProps={
                    pathname === '/settings/profile/' ? {
                        style: {display: 'none'}
                    } : {
                        style: {display: 'block'}
                    }}
            >
                <Tab style={homeIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'} id='home'
                     label={size ? '' : 'Home'} onClick={() => navigate('/')} value={'/' || '/blogs'}/>
                <Tab style={createIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'} id='create'
                     label={size ? '' : 'Create'} onClick={() => navigate('/create/')} value={'/create/'}/>
                <Tab style={historyIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'}
                     label={size ? '' : 'History'} onClick={() => navigate('/history/')} value={'/history/'}/>
                {/*<Tab style={calendarIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'}*/}
                {/*     label={size ? '' : 'Calendar'} onClick={() => navigate('/calendar/')} value={'/calendar/'} />*/}
                <Tab style={companyIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'}
                     label={size ? '' : 'Company'} onClick={() => navigate('/company/')} value={'/company/'}/>
                <Tab style={settingsIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'}
                     label={size ? '' : 'Settings'} onClick={() => navigate('/settings/')} value={'/settings/'}/>
                <Tab style={faqIcon} className={size ? 'leftNavBar__linkSize' : 'leftNavBar__link'}
                     label={size ? '' : 'FAQ'} onClick={() => navigate('/faq/')} value={'/faq/'}/>
            </Tabs>
        </Box>
    )
}

export default LeftBarNavigation;