import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Schedule.css';
import {useAppSelector} from "../../../../hooks/redux";
import {getSocialRating} from "../../../../store/selectors";


const Schedule = () => {
    const data = useAppSelector(getSocialRating);

    const state = {
        series: [...data.map(item => item.value)],
        place: [...data.map(item => item.place)],
        options: {
            dataLabels: {
                enabled: false
            },
            labels: [...data.map(item => item.name)],
            colors: ['#FEA859', '#FFD66B', '#5B93FF', '#3E3939', '#FF6B6B', '#7059FE'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                color: '#4856C1',
                                offsetY: 16,
                                formatter: function (val:any) {
                                    return val
                                }
                            },
                        }
                    }
                }
            },
        },
    };

    return (
        <div className="donut chart-wrapper">
            <p className='chart-title'>TOP popular social networks from connected</p>
            <ReactApexChart options={state.options} series={state.series} type="donut" width={380} />
        </div>
    );
}


export default Schedule;