import React from "react";
import styles from './ButtonArrow.module.scss';
import {useNavigate} from "react-router-dom";


const ButtonArrow = ({text, code = null}: { text: string, code?: string | null }) => {
    const navigate = useNavigate();

    const onSubmit = () => {
        if (code) {
            navigate(`blogs/${code}`)
        }
    }

    return (
        <button className={styles.buttonArrow} onClick={() => onSubmit()}>
            <span className={styles.buttonArrow__buttonText}>{text}</span>
            <div className={styles.buttonArrow__buttonIcon}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" fill="#4856C1"/>
                    <path d="M2 10H18M18 10L11.6 4M18 10L11.6 16" stroke="white" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
            </div>
        </button>
    )
}

export default ButtonArrow;