import React, {useState} from "react";
import styles from '../Create.module.scss'
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {useAppSelector} from "../../../../hooks/redux";
import {getMySocialNetworks} from "../../../../store/selectors";
import '../CustomSelect.css';
import {SocialNetwork} from "../../../../store/main-slice.types";
import Telegram from "./Telegram";
import Discord from "./Discord";
import Twitter from "./Twitter";
import Instagram from "./Instagram";
import EmptyPreview from "./EmptyPreview";

interface PreviewProps {
    picture: any;
    description: string;
    filesData: any;
}

const Preview = ({picture, description, filesData}: PreviewProps) => {
    const mySocials = useAppSelector(getMySocialNetworks);
    const [social, setSocial] = useState('');
    const [previewSocial, setPreviewSocial] = useState<SocialNetwork>();

    const handleChange = (event: SelectChangeEvent) => {
        setSocial(event.target.value as string);
    };

    return (
        <div className={styles.previewWrapper}>
            <h3 className={styles.preview__title}>Preview</h3>
            <div className='customSelect'>
                <Box>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={social}
                            onChange={handleChange}
                        >
                            {mySocials && (
                                mySocials.map((item, index) => <MenuItem
                                    key={index}
                                    onClick={() => setPreviewSocial(item)}
                                    value={item.title}
                                >
                                    {item.title}
                                </MenuItem>)
                            )}
                            <MenuItem onClick={() => setPreviewSocial({icon: '', title: 'All', text: ''})}
                                      value={'All'}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
            {previewSocial && previewSocial.title === 'Telegram'?
                <Telegram picture={picture} description={description} filesData={filesData}/>
                : previewSocial && previewSocial.title === 'X' ?
                    <Twitter description={description}/>
                    : previewSocial && previewSocial.title === 'Discord' ?
                        <Discord picture={picture} description={description} filesData={filesData}/>
                        : previewSocial && previewSocial.title === 'Instagram' ?
                            <Instagram picture={picture} description={description}/>
                            : previewSocial && previewSocial.title === 'All' ?
                                <>
                                    <Telegram picture={picture} description={description} filesData={filesData}/>
                                    <Twitter description={description}/>
                                    <Discord picture={picture} description={description} filesData={filesData}/>
                                    <Instagram picture={picture} description={description}/>
                                </>
                                : <EmptyPreview />
            }
        </div>
    )
}

export default Preview;