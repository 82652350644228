import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAccessTokenTimer = async () => {
    try {
        return await localStorage.getItem('accessTokenTimer');
    } catch (e) {
        console.log(e);
    }
};

export const getAccessToken = async () => {
    try {
        return await localStorage.getItem('accessToken');
    } catch (e) {
        console.log(e);
    }
};

export const getRefreshTokenTimer = async () => {
    try {
        return await localStorage.getItem('refreshTokenTimer');
    } catch (e) {
        console.log(e);
    }
};

export const getRefreshToken = async () => {
    try {
        return await localStorage.getItem('refreshToken');
    } catch (e) {
        console.log(e);
    }
};

export const setAllTokens = (access: string, refresh: string) => {
    try {
        // cookies.set('myCat', 'Pacman', { path: '/' });
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('refreshTokenTimer', `${Math.round(new Date().getTime() / 1000 + 2592000)}`);
        localStorage.setItem('accessTokenTimer', `${Math.round(new Date().getTime() / 1000 + 3550)}`);
    } catch (e) {
        console.log(e);
    }
}

export const setNewAccessToken = (token: string) => {
    try {
        localStorage.setItem('accessToken', token);
        localStorage.setItem('accessTokenTimer', `${Math.round(new Date().getTime() / 1000 + 3550)}`);
    } catch (e) {
        console.log(e);
    }
}