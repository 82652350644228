import React from "react";
import styles from "./Graphic.module.scss";
import {posts} from '../../../../store/main-slice.types'

interface ButtonIntervalProps {
    name: string,
    graphic: posts[],
    timeInterval: posts[] | null,
    setTimeInterval: (values: posts[]) => void
}

const ButtonInterval = ({name, graphic, timeInterval, setTimeInterval} : ButtonIntervalProps) => {
    return (
        <button className={timeInterval === graphic ? styles.graphicHeader__buttonActive : styles.graphicHeader__button}
                onClick={() => setTimeInterval(graphic)}>
            {name}
        </button>
    )
}

export default ButtonInterval;