import React from "react";
import styles from './RightNavBar.module.scss';


const SocialLink = ({title, icon}:{title: string, icon: string}) => {
    return (
        <button className={styles.socialLink}>
            <img src={icon} alt={title} />
        </button>
    )
}

export default SocialLink;