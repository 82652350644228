import React from "react";
import styles from './ContentMenu.module.scss'
import {useAppSelector} from "../../../../../hooks/redux";
import {getNavigation} from "../../../../../store/selectors";
import {Link} from "react-router-dom";


const ContentMenu = () => {
    const navigation = useAppSelector(getNavigation);

    return (
        <div className={styles.menuWrapper}>
            {navigation && (
                <div className={styles.navBox}>
                    {navigation.map((link, index) =>
                        <Link
                            key={index}
                            className={styles.link}
                            to={link.url}
                        >
                            {link.title}
                        </Link>
                    )}
                </div>
            )}
        </div>
    )
}

export default ContentMenu;