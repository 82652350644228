import React, {ChangeEvent} from "react";
import styles from "./Create.module.scss";
import upload from "../../../assets/img/upload.svg";
import close from "../../../assets/img/close.svg";

interface PictureProps {
    files?: any,
    picture: any
    setPicture: (setPicture: any) => void,
    setImgData: (setPicture: any) => void,
    label: string,
    placeholder?: string
}

const Picture = ({files=[], picture, setPicture, setImgData, label, placeholder='Upload picture'} : PictureProps) => {

    const getFile = (e: any) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            return e.target.files[0];
        }
    }
    return (
        <div className={styles.picture__inputWrapper}>
            <label className={styles.label} htmlFor={`picture`}>{label}</label>
            <div className={`${styles.picture__input} picInput`}>
                <input
                    type="file"
                    disabled={!!files.length}
                    className={files.length ? styles.picture__inputDisable : ''}
                    name="picture"
                    id='picture'
                    onChange={(e) => {
                        getFile(e)
                    }}
                />
                <div
                    className={files.length ? `${styles.picture__customInputDisable} picCustomInputDisable` : `${styles.picture__customInput} picCustomInput`}>
                    {picture
                        ? <span>{picture.name}</span>
                        : <>
                            <span>{placeholder}</span>
                            <img src={upload} alt="upload"/>
                        </>}
                    <button onClick={() => {
                        setPicture(null);
                        setImgData(null);
                    }}
                            className={picture ? styles.picture__closeActive : styles.picture__close}>
                        <img src={close} alt="remove"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Picture;