import React, {useState} from "react";
import styles from "./Editor.module.scss";
import left from "../../../assets/img/left.svg";
import close from "../../../assets/img/close.svg";
import emptyPicture from "../../../assets/img/emptyPicture.svg";
import Picture from "../Create/Picture";
import {Post} from "../../../store/posts-slice.types";
import addFileActive from "../../../assets/img/addFileActive.svg";
import addFile from "../../../assets/img/addFile.svg";
import smileActive from "../../../assets/img/smileActive.svg";
import smile from "../../../assets/img/smile.svg";
import EmojiPicker, {EmojiClickData, EmojiStyle, Theme} from "emoji-picker-react";
import FilePopup from "../Create/FilePopup";
import fb from '../../../assets/img/socialIcons/facebook.jpg';
import twitter from '../../../assets/img/socialIcons/twitter.jpg';
import youtube from '../../../assets/img/socialIcons/youtube.jpg';
import tik_tok from '../../../assets/img/socialIcons/tik_tok.jpg';
import reddit from '../../../assets/img/socialIcons/reddit.jpg';
import pinterest from '../../../assets/img/socialIcons/pinterest.jpg';
import linkedin from '../../../assets/img/socialIcons/linkedin.jpg';
import discord from '../../../assets/img/socialIcons/discord.jpg';
import telegram from '../../../assets/img/socialIcons/telegram.jpg';
import inst from '../../../assets/img/socialIcons/instagram.jpg';
import facebookIcon from '../../../assets/img/Facebook.svg';
import instagramIcon from '../../../assets/img/Instagram.svg';
import twitterIcon from '../../../assets/img/Twitter.svg';
import telegramIcon from '../../../assets/img/Telegram.svg';

interface EditorProps {
    setShowEditor: (setShowEditor: boolean) => void,
    editPost: Post,
}

const Editor = ({setShowEditor, editPost}: EditorProps) => {
    const [imgData, setImgData] = useState<any>(null);
    const [files, setFiles] = useState<any>(editPost.files);
    const [picture, setPicture] = useState<any>(editPost.picture);
    const [title, setTitle] = useState(editPost.title);
    const [descriptionText, setDescriptionText] = useState<string>(editPost.post);
    const [showFilePopup, setShowFilePopup] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false);
    const [filesData, setFilesData] = useState<any[]>([]);


    function onClick(emojiData: EmojiClickData, event: MouseEvent) {
        setDescriptionText(
            (descriptionText) =>
                descriptionText + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
        )
    }

    const closerFilePopup = () => {
        setShowFilePopup(false)
    }

    const setFilesValue = (files: any, fileData: any) => {
        setFiles(files);
        setFilesData(fileData);
    }

    return (
        <div className={styles.editorWrapper}>
            <div className={styles.editor}>
                <div className={styles.closers}>
                    <button onClick={() => setShowEditor(false)}><img src={left} alt=""/><span>Back</span></button>
                    <button onClick={() => setShowEditor(false)}><img src={close} alt=""/></button>
                </div>
                <div className={styles.innerWrapper}>
                    <h2 className={styles.editor__title}>Edit your publication</h2>
                    <p  className={styles.editor__subtitle}><b>Publication to:</b> <img src="" alt=""/> {editPost.socialNetwork}</p>
                    <div className={styles.picture}>
                        <img className={styles.picture__img} src={imgData ? imgData : emptyPicture} alt=""/>
                        <Picture files={files} picture={picture} setPicture={setPicture} setImgData={setImgData}
                                 label={'Picture'}/>
                    </div>
                    <div className={styles.textField}>
                        <label className={styles.label} htmlFor={`title`}>Title</label>
                        <input
                            className={styles.textInput}
                            type="text"
                            name="title"
                            id='title'
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </div>
                    <div className={styles.textField}>
                        <label className={styles.label} htmlFor={`description`}>Description</label>
                        <div className={styles.textareaBox}>
                        <textarea
                            className={styles.textTextarea}
                            name="description"
                            id='description'
                            onChange={(e) => setDescriptionText(e.target.value)}
                            value={descriptionText}
                        />
                            <div className={styles.textTextarea__footer}>
                                {imgData
                                    ? <button type='button' disabled={!!imgData}
                                              className={styles.textTextarea__fileDisable}>
                                        <img src={showFilePopup ? addFileActive : addFile} alt="add file"/>
                                    </button>
                                    : <button type='button' onClick={() => setShowFilePopup(true)}
                                              className={showFilePopup ? styles.textTextarea__fileActive : styles.textTextarea__file}>
                                        <img src={showFilePopup ? addFileActive : addFile} alt="add file"/>
                                    </button>
                                }
                                <button type='button' onClick={() => {
                                    setShowEmoji(!showEmoji)
                                }}
                                        className={showEmoji ? styles.textTextarea__emojiActive : styles.textTextarea__emoji}>
                                    <img src={showEmoji ? smileActive : smile} alt="add emoji"/>
                                </button>
                            </div>
                            <div className={showEmoji ? styles.emojiBox : styles.emojiBoxHide}>
                                <EmojiPicker
                                    onEmojiClick={onClick}
                                    autoFocusSearch={true}
                                    emojiStyle={EmojiStyle.NATIVE}
                                    searchDisabled
                                    theme={Theme.LIGHT}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <FilePopup closerFilePopup={closerFilePopup} showFilePopup={showFilePopup} setFilesValue={setFilesValue}/>
            </div>
        </div>
    )
}

export default Editor;