import React, {useEffect, useState} from "react";
import styles from './Create.module.scss';
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {getAccessToken} from "../../../api/localStorage";
import {getConnectedInstagramAccounts} from "../../../api/api";
import {Networks} from "../../../store/main-slice.types";

interface TwitterChangersProps {
    account: number;
    setAccount: (setAccount: number) => void;
}


const InstagramChangers = ({account, setAccount}: TwitterChangersProps) => {
    const [allAccounts, setAllAccounts] = useState<Networks[]>()

    const handleChange = (event: SelectChangeEvent | any, value: string) => {

        if (value === 'setAccount' && event.target.value !== 0) {
            setAccount(event.target.value);
        }
    };

    useEffect(() => {
        getAccessToken().then((token) => {
            if (token && !allAccounts?.length) {
                getConnectedInstagramAccounts(token).then(response => {
                    if (response) {
                        const currentAccounts: Networks[] = [];
                        response.forEach((item: Networks) => {
                            if (item.is_active && !item.is_deleted) {
                                currentAccounts.push(item)
                            }
                        })
                        setAllAccounts(currentAccounts)
                    }
                });
            }
        });

    }, [allAccounts?.length])

    return (
        <>
            {allAccounts && allAccounts.length > 0 && (
                <div className={styles.socialChangedWrapper}>
                    {allAccounts && allAccounts.length ? (
                        <div className={`${styles.socialChanged} post-filters creator`}>
                            <p className={styles.socialChanged__label}>select Account</p>
                            <Box sx={{width: 196}}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={account}
                                        onChange={(e) => handleChange(e, 'setAccount')}
                                    >
                                        {allAccounts && allAccounts.length && (
                                            allAccounts.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                            ))
                                        )}

                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    ) : null}
                </div>
            )}
        </>
    )
}

export default InstagramChangers;