import React from "react";
import styles from "../CompanyInfo.module.scss";


interface CompanyEditInfoProps {
    networkCount: number
    accountsCount: number
    teamCount: number
}

const CompanyEditInfo = ({networkCount, accountsCount, teamCount}: CompanyEditInfoProps) => {
    return (
        <div className={styles.companyEdit__info}>
            <div className={styles.companyEdit__infoField}>
                <span className={styles.companyEdit__infoLabel}>Your position</span>
                <span>Owner</span>
            </div>
            <div className={styles.companyEdit__infoField}>
                <span className={styles.companyEdit__infoLabel}>Social network</span>
                <span>{networkCount} social networks connected</span>
            </div>
            <div className={styles.companyEdit__infoField}>
                <span className={styles.companyEdit__infoLabel}>Accounts</span>
                <span>{accountsCount} accounts connected</span>
            </div>
            <div className={styles.companyEdit__infoField}>
                <span className={styles.companyEdit__infoLabel}>Team</span>
                <span>{teamCount} members in your team</span>
            </div>
        </div>
    )
}

export default CompanyEditInfo;