import {createSlice} from '@reduxjs/toolkit';
import img from '../assets/img/pic.jpg'
import {Posts} from "./posts-slice.types";

const initialState: Posts = {
    posts: [
        // {
        //     id: 1,
        //     picture: '',
        //     socialNetwork: 'Telegram',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Published',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 224,
        //     comments: 24,
        //     repost: 2
        // },
        // {
        //     id: 2,
        //     picture: '',
        //     socialNetwork: 'X',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Postponed',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 0,
        //     comments: 0,
        //     repost: 0
        // },
        // {
        //     id: 3,
        //     picture: '',
        //     socialNetwork: 'Discord',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Published',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 56745,
        //     comments: 558,
        //     repost: 50
        // },
        // {
        //     id: 4,
        //     picture: '',
        //     socialNetwork: 'Instagram',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Draft',
        //     author: 'Bill Bon',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 0,
        //     comments: 0,
        //     repost: 0
        // },
        // {
        //     id: 5,
        //     picture: img,
        //     socialNetwork: 'X',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Published',
        //     author: 'Bill Bon',
        //     files: ['Some report_new.xls', 'Second report_new.xls'],
        //     date: '31 November 2023',
        //     view: 2334124,
        //     comments: 2234,
        //     repost: 241
        //
        // },
        // {
        //     id: 6,
        //     picture: '',
        //     socialNetwork: 'Instagram',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Published',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 23124,
        //     comments: 234,
        //     repost: 24
        // },
        // {
        //     id: 7,
        //     picture: '',
        //     socialNetwork: 'Telegram',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Deleted',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 89,
        //     comments: 8,
        //     repost: 2
        // },
        // {
        //     id: 8,
        //     picture: '',
        //     socialNetwork: 'Discord',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Published',
        //     author: 'Bill Bon',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 124,
        //     comments: 34,
        //     repost: 2
        // },
        // {
        //     id: 9,
        //     picture: '',
        //     socialNetwork: 'X',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Draft',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 0,
        //     comments: 0,
        //     repost: 0
        // },
        // {
        //     id: 10,
        //     picture: '',
        //     socialNetwork: 'X',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Draft',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 0,
        //     comments: 0,
        //     repost: 0
        // },
        // {
        //     id: 11,
        //     picture: '',
        //     socialNetwork: 'X',
        //     title: 'Some title',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
        //     status: 'Draft',
        //     author: 'Jaylon Vaccaro',
        //     files: [],
        //     date: '31 November 2023',
        //     view: 0,
        //     comments: 0,
        //     repost: 0
        // }
    ]
};

export const postsSlice = createSlice({
    name: 'postsPage',
    initialState,
    reducers: {},
});

export default postsSlice.reducer;
export const {} = postsSlice.actions;
