import React from "react";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    backgroundColor: '#fff'
}));

const FAQList = () => {
    const [expanded, setExpanded] = React.useState<string | false>('');
    const faqItems = [
        {
            question: 'question one',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consefugiat nulla pariatur.'
        },
        {
            question: 'question second',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        {
            question: 'question third',
            answer: 'Et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consefugiat nulla pariatur.'
        }
    ]

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            {faqItems.map((item, index) =>
                <Accordion key={index} expanded={expanded === item.question} onChange={handleChange(item.question)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography className='accordion-title'>{item.question}</Typography>
                        <span className='accordion-button'/>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className='accordion-text'>{item.answer}</Typography>
                    </AccordionDetails>
                </Accordion>)}
        </div>
    )
}

export default FAQList;