import styles from "../header.module.scss";
import logo from "../../../../assets/img/Logo.svg";
import React from "react";
import {useNavigate} from "react-router-dom";


const Logo = () => {
    const navigate = useNavigate();

    const toHome = () => {
        window.scrollTo(0, 0);
        navigate('/');
    }

    return (
        <div className={styles.logo} onClick={() => toHome()}>
            <img src={logo} alt="logo"/>
        </div>
    )
}

export default Logo;