import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from '../FAQ.module.scss'
import '../FAQ.css';


const FAQMenuTabs = ({setContent}: {setContent: (setContent: string) => void}) => {
    const [value, setValue] = useState('Tips for using');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setContent(newValue);
    };
    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
            >
                <Tab
                    className={styles.customTab}
                    label={<div>
                        <h4>Tips for using</h4>
                        <p className={styles.tab__subText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                    </div>}
                    value={'Tips for using'}/>
                <Tab
                    className={styles.customTab}
                    label={<div>
                        <h4>FAQ</h4>
                        <p className={styles.tab__subText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                    </div>}
                    value={'FAQ'}/>
                <Tab
                    className={styles.customTab}
                    label={<div>
                        <h4>Privacy policy</h4>
                        <p className={styles.tab__subText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                    </div>}
                    value={'Privacy policy'}/>
                <Tab
                    className={styles.customTab}
                    label={<div>
                        <h4>Public offer contract</h4>
                        <p className={styles.tab__subText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                    </div>}
                    value={'Public offer contract'}/>
                <Tab
                    className={styles.customTab}
                    label={<div>
                        <h4>Contact us</h4>
                        <p className={styles.tab__subText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                    </div>}
                    value={'Contact us'}/>
            </Tabs>
        </Box>
    )
}

export default FAQMenuTabs;