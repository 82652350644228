import React, {useState} from "react";
import styles from '../Settings.module.scss';
import {useFormik} from "formik";
import eyeSlash from "../../../../assets/img/eye-slash.svg";
import eye from "../../../../assets/img/eye.svg";
import SwitchBox from "../../../Common/SwitchBox/SwitchBox";
import {useAppSelector} from "../../../../hooks/redux";
import {getIsTwoFactorAuthentication} from "../../../../store/selectors";

const Privacy = () => {
    const isTwoFactorAuthentication = useAppSelector(getIsTwoFactorAuthentication);
    const [isConnect, setIsConnect] = useState(isTwoFactorAuthentication)
    const [confirm, setConfirm] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setRepeatShowPassword] = useState(false)

    const {handleSubmit, handleChange, isSubmitting, values, resetForm} = useFormik({
        initialValues: {
            email: '',
            password: '',
            repeat: '',
        },

        onSubmit: (values) => {
            const newValues = {...values, isConnect}
            if (values.password === values.repeat) {
                setConfirm(true)
                resetForm();
                console.log(newValues);
            }
            if (values.password !== values.repeat) {
                setConfirm(false);
            }
            // if (values.password === values.repeat) {
            //     alert('read terms & conditions');
            // }
        },

    });

    return (
        <div className={styles.privacy}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <h5 className={styles.privacy__title}>Login</h5>
                <p className={styles.privacy__subTitle}>You can change your login. To do this, write a new email</p>
                <div className={`${styles.textField}`}>
                    <label className={styles.label} htmlFor={`title`}>Email</label>
                    <input
                        className={styles.textInput}
                        type="email"
                        name="email"
                        id='email'
                        onChange={handleChange}
                        value={values.email}
                    />
                    <div className={styles.passwordWrapper}>
                        <div className={styles.passwordInfo}>
                            <h5 className={styles.privacy__title}>Password</h5>
                            <p className={styles.privacy__subTitle}>You can change your password. To do this, enter at least 6 characters</p>
                        </div>
                        <label className={styles.label} htmlFor={`password`}>New Password</label>
                        <div className={styles.password}>
                            <input
                                className={styles.textInput}
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                value={values.password}
                            />
                            <button type={'button'} className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>
                                <img src={showPassword ? eyeSlash : eye} alt="show"/>
                            </button>
                        </div>
                        <label className={styles.label} htmlFor={`repeat`}>Repeat password</label>
                        <div className={styles.password}>
                            <input
                                className={confirm ? styles.textInput : styles.textInputError}
                                id="repeat"
                                name="repeat"
                                type={showRepeatPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                value={values.repeat}
                            />
                            <button type={'button'} className={styles.togglePassword} onClick={() => setRepeatShowPassword(!showRepeatPassword)}>
                                <img src={showRepeatPassword ? eyeSlash : eye} alt="show"/>
                            </button>
                        </div>
                        <a className={styles.link} href="#">Forgot your password</a>
                    </div>
                </div>
                <div className={styles.privacy__switchBox}>
                    <SwitchBox
                        isConnect={isConnect}
                        setIsConnect={setIsConnect}
                        title={'Two-factor authentication'}
                        text={'Protect your profile with additional verification'}
                    />
                </div>
                <div className={styles.actionsPrivacy}>
                    <button type='submit'>Save</button>
                </div>
            </form>
        </div>
    )
}

export default Privacy;