import React, {useState} from "react";
import AddAccount from "../Company/AddAccount/AddAccount";
import RightNavBar from "./RightNavBar";


const RightNavWrapper = () => {
    const [addAccountPopup, setAddAccountPopup] = useState(false);

    addAccountPopup ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    return (
        <div>
            <RightNavBar setAddAccountPopup={setAddAccountPopup} />
            {addAccountPopup && (
                <AddAccount setAddAccountPopup={setAddAccountPopup}/>
            )}
        </div>
    )
};

export default RightNavWrapper;