import React from "react";
import styles from './ContentBoxes.module.scss'
import Box from "./Box/Box";


const ContentBoxes = () => {

    const boxes = [
        {
            title: '1. All together',
            text: 'Our service combines all the social networks you need in a single account. ' +
                'Forget about dozens of open tabs in the browser. One tab — all social networks at hand.'
        },
        {
            title: '2. Without limits',
            text: 'Publishing through BePosted allows you to bypass many restrictions from other services: ' +
                'the volume of video files, the size of images, etc. Don\'t worry about the little ' +
                'things post everything you need.'
        },
        {
            title: '3. Saving sessions',
            text: 'To connect the desired social network account, only standard login data is enough. ' +
                'BePosted will save your session and eliminate the need for constant verification.'
        },
        {
            title: '4. Freedom of choice',
            text: 'Use only those social networks in which you work. We do not charge for inactive components. ' +
                'And if you need more, it\'s always easy to add. The more social networks, the better!'
        },
    ]
    return (
        <div className={styles.boxes}>
            {boxes && (
                boxes.map((item, index) => <Box
                    title={item.title}
                    key={index}
                    text={item.text} />)
            )}
        </div>
    )
}

export default ContentBoxes;