import React from "react";
import styles from "../Create.module.scss";
import previewEmpty from "../../../../assets/img/previewEmpty.jpg";


const EmptyPreview = () => {
    return (
        <div className={styles.preview__empty}>
            <p className={styles.preview__emptyText}>Here it will be shown how your publication will
                be displayed in the selected social networks</p>
            <div className={styles.preview__pictureBox}>
                <img className={styles.preview__picture} src={previewEmpty} alt=""/>
            </div>
        </div>
    )
}

export default EmptyPreview;