import styles from "./Create.module.scss";
import close from "../../../assets/img/close.svg";
import loader from "../../../assets/img/loadFiles.svg";
import FilesList from "./FilesList";
import React, {useState} from "react";

interface FilePopupProps {
    closerFilePopup: () => void;
    showFilePopup: boolean;
    setFilesValue: (files: any, filesData: any) => void;
}

const FilePopup = ({closerFilePopup, showFilePopup, setFilesValue}: FilePopupProps) => {
    const [files, setFiles] = useState<any[]>([]);
    const [filesData, setFilesData] = useState<any[]>([]);

    const closePopup = () => {
        closerFilePopup();
        setFiles([]);
        setFilesData([]);
    }

    const applyFiles = () => {
        closerFilePopup();
        setFilesValue(files, filesData)
    }

    const formatBytes = async (bytes: number, decimals = 2) => {
        if (!+bytes) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const getFiles = (e: any) => {
        if (e.target.files.length && e.target.files.length < 11) {
            let fileArray: any = [];
            let files: any = [];
            for (let i = 0; i < e.target.files.length; i++) {
                formatBytes(e.target.files[i].size).then(response => {
                    files.push({name: e.target.files[i].name, size: response});
                    setFilesData([...files]);
                });
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    fileArray.push(reader.result);
                    setFiles([...fileArray]);
                });
                reader.readAsDataURL(e.target.files[i]);
            }
            return e.target.files;
        }
    }

    const removeFile = (index: number) => {
        let namesArr = filesData;
        let filesArr = files;
        namesArr.splice(index, 1);
        filesArr.splice(index, 1);
        setFilesData([...namesArr]);
        setFiles([...filesArr]);
    }

    return (
        <div
            className={showFilePopup ? `${styles.fileUploader__wrapperActive} hide-scroll` : styles.fileUploader__wrapper}>
            <div className={styles.fileUploader__box}>
                <button className={styles.fileUploader__close} onClick={() => closePopup()}>
                    <img src={close} alt="close"/>
                </button>
                <h4 className={styles.fileUploader__title}>Let's attach the file to your post</h4>
                <p className={styles.fileUploader__text}>Choose or drag & drop the file you want to add to the
                    publication</p>
                <div className={styles.fileUploader__loaderBox}>
                    <label className={styles.fileUploader__label} htmlFor={`file`}>Choose or drag & drop the
                        file</label>
                    <div className={styles.fileUploader__inputWrapper}>
                        <input className={styles.fileUploader__input}
                               multiple
                               type="file"
                               name='file'
                               id='file'
                               onChange={(e) => {getFiles(e)}}
                        />
                        <div className={styles.fileUploader__customInput}>
                            <img src={loader} alt="load"/>
                            <p>Drag & Drop or <span>Choose file</span> to upload</p>
                        </div>
                    </div>
                </div>
                {filesData.length ? <FilesList filesData={filesData} removeFile={removeFile} /> : null}
                <div className={styles.fileUploader__actions}>
                    <button type='button'
                            onClick={() => applyFiles()}
                            className={files.length ? styles.fileUploader__acceptActive : styles.fileUploader__accept}>
                        Attach
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FilePopup;