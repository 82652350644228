import React, {useState} from "react";
import styles from '../CompanyInfo.module.scss';
import emptyPicture from "../../../../../assets/img/emptyPicture.svg";
import Picture from "../../../Create/Picture";
import {useFormik} from "formik";
import CompanyEditInfo from "./CompanyEditInfo";

interface CompanyInfoProps {
    about: string,
    name: string,
    logo: string,
    networkCount: number
    accountsCount: number
    teamCount: number
}

const CompanyEdit = ({about, name, logo, networkCount, accountsCount, teamCount}: CompanyInfoProps) => {
    const [picture, setPicture] = useState<any>(logo === '' ? null : logo);
    const [imgData, setImgData] = useState<any>(logo === '' ? null : logo);

    const {handleSubmit, handleChange, isSubmitting, values, resetForm} = useFormik({
        initialValues: {
            description: about,
            title: name,
        },
        onSubmit: (values) => {
            const newValues = {...values, picture,}
            console.log(newValues);
            resetForm();
        },

    });
    return (
        <div className={`${styles.companyEdit} companyEdit`}>
            <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.picture}>
                <img className={styles.picture__img} src={imgData ? imgData : emptyPicture} alt=""/>
                <Picture picture={picture} setPicture={setPicture} setImgData={setImgData} label={'Logo'} />
            </div>
            <div className={styles.textField}>
                <label className={styles.label} htmlFor={`title`}>Company Name</label>
                <input
                    className={styles.textInput}
                    type="text"
                    name="title"
                    id='title'
                    onChange={handleChange}
                    value={values.title}
                />
            </div>
            <div>
                <label className={styles.label} htmlFor={`description`}>Description</label>
                <div className={styles.textareaBox}>
                        <textarea
                            className={styles.textTextarea}
                            name="description"
                            id='description'
                            onChange={handleChange}
                            value={values.description}
                        />
                </div>
            </div>
            <CompanyEditInfo networkCount={networkCount} accountsCount={accountsCount} teamCount={teamCount} />
            <div className={styles.companyEdit__actions}>
                <button type='button' className={styles.companyEdit__close}>Close</button>
                <button disabled={isSubmitting} type='submit' className={styles.companyEdit__save}>Save</button>
            </div>
            </form>
        </div>
    )
}

export default CompanyEdit;