import fb from '../assets/img/socialIcons/facebook.jpg';
import twitter from '../assets/img/socialIcons/twitter.jpg';
import youtube from '../assets/img/socialIcons/youtube.jpg';
import tik_tok from '../assets/img/socialIcons/tik_tok.jpg';
import reddit from '../assets/img/socialIcons/reddit.jpg';
import pinterest from '../assets/img/socialIcons/pinterest.jpg';
import linkedin from '../assets/img/socialIcons/linkedin.jpg';
import discord from '../assets/img/socialIcons/discord.jpg';
import slack from '../assets/img/socialIcons/slack.svg';
import telegram from '../assets/img/socialIcons/telegram.jpg';
import inst from '../assets/img/socialIcons/instagram.jpg';
import facebookIcon from '../assets/img/Facebook.svg';
import instagramIcon from '../assets/img/Instagram.svg';
import twitterIcon from '../assets/img/Twitter.svg';
import telegramIcon from '../assets/img/Telegram.svg';
import user1 from '../assets/img/user1.jpg';
import user2 from '../assets/img/user2.jpg';
import aviviIcon from '../assets/img/avivi.png'
import {Networks} from './main-slice.types';
import {SocialNetwork} from './main-slice.types';

import {createSlice} from '@reduxjs/toolkit';

import {Navigation} from './main-slice.types';

const initialState: Navigation = {
    size: false,
    userId: null,
    isAuth: false,
    notification: true,
    twoFactorAuthentication: false,
    connectedNetworks: [],
    stats: [
        {title: 'Connected accounts', count: 0},
        {title: 'Connected social networks', count: 0},
        {title: 'Published posts', count: 0},
        {title: 'Planned posts', count: 0},
    ],
    navigation: [
        {id: 1, title: 'About service', url: '/#about'},
        {id: 2, title: 'BePosted features', url: '/#features'},
        {id: 3, title: 'Integration', url: '/#integration'},
        {id: 4, title: 'Tariffs', url: '/#tariff'},
        {id: 5, title: 'Reviews', url: '/#reviews'},
        {id: 6, title: 'Blog', url: '/blogs/'},
        {id: 7, title: 'Support', url: '/#support'},
    ],
    timeZones: [
        {name: 'GMT+0200 (Eastern European Standard Time)', value: '+02:00'},
        {name: 'GMT+0400 (Europe Georgia)', value: '+04:00'},
        {name: 'GMT+0500 (India New-Deli)', value: '+05:00'},
    ],
    companies: [
        {
            name: 'Avivi',
            icon: aviviIcon,
            connectedNetworks: ['Instagram', 'X', 'Discord'],
            about: 'IT company. We are develop different projects',
            team: [
                {photo: user1, name: 'Martin Siphron', role: 'Owner'},
                {photo: user2, name: 'Ryan Vaccaro', role: 'Client'},
            ],
            socialAccounts: [
                {
                    socialNetworkIcon: inst,
                    name: 'Instagram',
                    accountIcon: aviviIcon,
                    account: 'avivi.insta',
                    posts: 183,
                    followers: 24126
                },
                {
                    socialNetworkIcon: twitter,
                    name: 'X',
                    accountIcon: aviviIcon,
                    account: 'avivi.twitter',
                    posts: 183,
                    followers: 24126
                },
                {
                    socialNetworkIcon: discord,
                    name: 'Discord',
                    accountIcon: aviviIcon,
                    account: 'aviviInfo',
                    posts: 222,
                    followers: 29926
                },
            ]
        },
        // {
        //     icon: '',
        //     name: 'MBocharova',
        //     connectedNetworks: ['Instagram', 'X'],
        //     about: '',
        //     team: [
        //         {photo: user1, name: 'Martin Siphron', role: 'Owner'},
        //         {photo: user2, name: 'Ryan Vaccaro', role: 'Client'},
        //         {photo: user1, name: 'Martin Siphron', role: 'Owner'},
        //         {photo: user2, name: 'Ryan Vaccaro', role: 'Client'},
        //     ],
        //     socialAccounts: [
        //         {socialNetworkIcon: inst, name: 'Instagram', accountIcon: aviviIcon, account: 'MBocharova.insta', posts: 183, followers: 24126},
        //         {socialNetworkIcon: twitter, name: 'X', accountIcon: aviviIcon, account: 'MBocharovaPro', posts: 183, followers: 24126},
        //         {socialNetworkIcon: inst, name: 'Instagram', accountIcon: aviviIcon, account: 'avivi.insta', posts: 183, followers: 24126},
        //         {socialNetworkIcon: twitter, name: 'X', accountIcon: aviviIcon, account: 'MBocharova.Twit', posts: 183, followers: 24126},
        //         {socialNetworkIcon: inst, name: 'Instagram', accountIcon: aviviIcon, account: 'MBocharova', posts: 183, followers: 24126},
        //     ]
        // }
    ],
    socialLinks: [
        {icon: facebookIcon, title: 'facebook', url: 'https://www.facebook.com/'},
        {icon: twitterIcon, title: 'twitter', url: 'https://www.instagram.com/'},
        {icon: instagramIcon, title: 'instagram', url: 'https://twitter.com/'},
        {icon: telegramIcon, title: 'telegram', url: 'https://web.telegram.org/'},
    ],
    socialNetworks: [
        {icon: inst, title: 'Instagram', text: 'Post, Reals, Story'},
        {icon: fb, title: 'Facebook', text: 'Post, Story'},
        {icon: twitter, title: 'X', text: 'Twit'},
        {icon: youtube, title: 'YouTube', text: 'Video, Shorts'},
        {icon: tik_tok, title: 'TikTok', text: 'Post'},
        {icon: reddit, title: 'Reddit', text: 'Post'},
        {icon: pinterest, title: 'Pinterest', text: 'Pin'},
        {icon: linkedin, title: 'LinkedIn', text: 'Post'},
        {icon: discord, title: 'Discord', text: 'Post'},
        {icon: telegram, title: 'Telegram', text: 'Pin'},
        {icon: slack, title: 'Slack', text: 'Post'},
    ],
    mySocialNetworks: [
        // {icon: inst, title: 'Instagram', text: 'Post, Reals, Story'},
        // {icon: telegram, title: 'Telegram', text: 'Pin'},
        // {icon: twitter, title: 'X', text: 'Twit'},
        // {icon: discord, title: 'Discord', text: 'Post'},
        // {icon: slack, title: 'Slack', text: 'Post'},
    ],
    tariffs: [
        {
            title: 'Basic package',
            text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
            price: 9.99,
            profiles: 3,
            users: 1,
            posts: '90',
            analytics: false,
            socialInbox: false,
            monitoring: false,
        },
        {
            title: 'Professional package',
            text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
            price: 26.99,
            profiles: 6,
            users: 5,
            posts: 'Unlimited',
            analytics: true,
            socialInbox: true,
            monitoring: false,
        },
        {
            title: 'Premium package',
            text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
            price: 99.99,
            profiles: 12,
            users: 10,
            posts: 'Unlimited',
            analytics: true,
            socialInbox: true,
            monitoring: true,
        }
    ],
    reviews: [
        {
            photo: user1,
            name: 'Nikki Force',
            date: '23.10.2023',
            text: 'Using BePosted freed me from unbearable  "slavery" in daily work. Now I don\'t have to reach for different devices to make a post - everything is gathered in one place and always at hand.'
        },
        {
            photo: user2,
            name: 'Nikki Force',
            date: '24.10.2023',
            text: 'Do you know the most effective communication channel with your customers? We didn\'t know the most effective communication channel with your customers? We didn\'tknow the most effective communication channel with your customers? We didn\'t know either until we started using BePosted. Analytics of each post in each social network helps our agency to better understand the mood of our target audience.'
        },
        {
            photo: user1,
            name: 'Nikki Force',
            date: '23.10.2023',
            text: 'Using BePosted freed me from unbearable  "slavery" in daily work. Now I don\'t have to reach for different devices to make a post - everything is gathered in one place and always at hand.'
        },
        {
            photo: user2,
            name: 'Nikki Force',
            date: '24.10.2023',
            text: 'Do you know the most effective communication channel with your customers? We didn\'t know either until we started using BePosted. Analytics of each post in each social network helps our agency to better understand the mood of our target audience.'
        },
    ],
    postsDay: [],
    postsWeek: [],
    postsMonth: [],
    postsYear: [],
    socRatingPosts: [],
};

export const mainSlice = createSlice({
    name: 'mainPage',
    initialState,
    reducers: {
        setSocialNetworks(state, action) {
            state.connectedNetworks = action.payload;
            const newArr: SocialNetwork[] = [];
            if (action.payload && action.payload.length > 0) {
                action.payload.map((item: Networks) => {
                    if (item.is_active && !item.is_deleted) {
                        newArr.push({
                            title: item.socialName,
                            icon: item.socialName === 'Twitter' ? twitter
                                : item.socialName === 'Discord' ? discord
                                    : item.socialName === 'Slack' ? slack
                                        : item.socialName === 'Instagram' ? inst
                                            : item.socialName === 'Telegram' ? telegram : '',
                            text: '',
                        })
                    }
                })
                state.mySocialNetworks = newArr
            }
        },
        setSize(state, action) {
            state.size = action.payload;
        },
        setAuth(state, action) {
            state.isAuth = action.payload;
        },
        setUser(state, action) {
            state.userId = action.payload.id;
            state.isAuth = true;
        },
        resetUser(state, {payload}) {
            state.userId = payload;
            state.isAuth = false;
        },
        setStatistic(state, {payload}) {
            if (payload && payload.count_connect_accounts) {
                state.stats = [
                    {title: 'Connected accounts', count: payload.count_connect_accounts},
                    {title: 'Connected social networks', count: payload.count_connect_social_networks},
                    {title: 'Published posts', count: payload.total_count_posts_published},
                    {title: 'Planned posts', count: payload.total_count_posts_planned},
                ];

                state.postsDay = payload.hourly_post_counts_last_24_hours;
                state.postsWeek = payload.post_counts_last_month.slice(payload.post_counts_last_month.length - 7, payload.post_counts_last_month.length);
                state.postsMonth = payload.post_counts_last_month;
                state.postsYear = payload.monthly_post_counts_last_year;
                state.socRatingPosts = payload.network_post_counts;
            }
        }
    },
});

export default mainSlice.reducer;
export const {
    setSocialNetworks,
    setSize,
    setAuth,
    setUser,
    resetUser,
    setStatistic,
} = mainSlice.actions;
