import React from "react";
import styles from './PreviewSocials.module.scss';
import emptyPhoto from '../../../../assets/img/defaultPhoto.png';
import twitterChat from '../../../../assets/img/twitterChat.svg';
import refreshTwitter from '../../../../assets/img/refreshTwitter.svg';
import likeTwitter from '../../../../assets/img/likeTwitter.svg';
import favoriteTwitter from '../../../../assets/img/favoriteTwitter.svg';
import loadTwitter from '../../../../assets/img/loadTwitter.svg';

const Twitter = ({description}:{description: string}) => {
    const user = {photo: '', name: 'Maggie', userLink: '@maggie12',}
    return (
        <>
            {description.length ? <div className={styles.twitter}>
                <div className={styles.twitter__content}>
                    <img className={styles.twitter__contentPhoto} src={emptyPhoto} alt=""/>
                    <div className={styles.twitter__contentInfo}>
                        <div className={styles.twitter__userBox}>
                            <div className={styles.twitter__user}>
                                <h5 className={styles.twitter__userName}>{user.name}</h5>
                                <span className={styles.twitter__userLink}>{user.userLink}</span>
                                <span className={styles.twitter__userDot} />
                                <span className={styles.twitter__userDate}>now</span>
                            </div>
                            <div className={styles.twitter__userSettings}>
                                <span className={styles.twitter__userDot} />
                                <span className={styles.twitter__userDot} />
                                <span className={styles.twitter__userDot} />
                            </div>
                        </div>
                        <p className={styles.twitter__text}>{description}</p>
                    </div>
                </div>
                <div className={styles.twitter__iconsWrapper}>
                    <img src={twitterChat} alt=""/>
                    <img src={refreshTwitter} alt=""/>
                    <img src={likeTwitter} alt=""/>
                    <div className={styles.twitter__iconsBox}>
                        <img src={favoriteTwitter} alt=""/>
                        <img src={loadTwitter} alt=""/>
                    </div>
                </div>
            </div> : null}
        </>

    )
}

export default Twitter;