import React from "react";
import styles from './BePosted.module.scss'
import BePostedContent from "./BePostedContent/BePostedContent";


const BePosted = () => {
    return (
        <div id='features' className={styles.wrapper}>
            <p className={styles.subtitle}>What will you can do</p>
            <h2 className={styles.title}>BePosted features</h2>
            <BePostedContent />
        </div>
    )
}

export default BePosted;