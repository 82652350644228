import styles from './ConnectsAccounts.module.scss'
import React, {useEffect, useState} from "react";
import close from "../../../../assets/img/close.svg";
import {useFormik} from "formik";
import {connectDiscord, connectSlack, getConnectedNetworks, getSlackConnectBtn} from "../../../../api/api";
import {getAccessToken} from "../../../../api/localStorage";
import {useAppDispatch} from "../../../../hooks/redux";
import {setSocialNetworks} from '../../../../store/main-slice';

interface TwitterConnectProps {
    setIsCreate: (setIsCreate: boolean) => void,
    setConnectedNetwork: (setConnectedNetwork: string | null) => void,
    setSocialsSelected: (setSocialsSelected: string) => void,
}

const SlackConnect = ({setConnectedNetwork, setIsCreate, setSocialsSelected}:TwitterConnectProps) => {
    const dispatch = useAppDispatch();
    const [connectButton, setConnectButton] = useState(null);

    useEffect(() => {
        if (!connectButton) {
            getAccessToken().then((accessToken) => {
                if (accessToken) {
                    getSlackConnectBtn(accessToken).then(r => console.log(r))
                }
            })
        }
    })

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            name: '',
            token: '',
        },

        onSubmit: (values) => {
            getAccessToken().then((accessToken) => {
                if (accessToken) {
                    connectSlack(accessToken, values.name, values.token).then((result) => {
                        if (result && result.created_date) {
                            getConnectedNetworks(accessToken).then((response) => {
                                dispatch(setSocialNetworks(response));
                                setSocialsSelected('');
                            })
                        }
                    })
                }
            })
        }
    });

    return (
        <div className={styles.twitterConnect}>
            <button type='button' className={styles.closer}
                    onClick={() => setIsCreate(false)}>
                <img src={close} alt=""/>
            </button>
            <h2 className={styles.twitterConnect__title}>Let's connect your profile</h2>
            <p className={styles.twitterConnect__subtitle}>Some text for connect in Slack</p>
            <form className={styles.twitterConnect__form} onSubmit={handleSubmit}>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`name`}>Name</label>
                    <input
                        className={styles.field__input}
                        id="name"
                        name="name"
                        type="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder={'Name'}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.field__label} htmlFor={`consumer_key`}>Token</label>
                    <input
                        className={styles.field__input}
                        id="token"
                        name="token"
                        type="token"
                        onChange={handleChange}
                        value={values.token}
                        placeholder={'Token'}
                    />
                </div>
                <span>Or</span>
                <div>
                    <a href="https://slack.com/oauth/v2/authorize?client_id=5906591157333.6860668766208&scope=app_mentions:read,bookmarks:read,bookmarks:write,calls:read,calls:write,canvases:read,canvases:write,channels:history,channels:join,channels:manage,channels:read,channels:write.invites,channels:write.topic,chat:write,chat:write.customize,chat:write.public,commands,conversations.connect:manage,conversations.connect:read,conversations.connect:write,dnd:read,emoji:read,files:read,files:write,groups:history,groups:read,groups:write,groups:write.invites,groups:write.topic,im:history,im:read,im:write,im:write.invites,im:write.topic,links.embed:write,links:read,links:write,metadata.message:read,mpim:history,mpim:read,mpim:write,mpim:write.invites,mpim:write.topic,pins:read,pins:write,reactions:read,reactions:write,reminders:read,reminders:write,remote_files:read,remote_files:share,remote_files:write,team.billing:read,team.preferences:read,team:read,usergroups:read,usergroups:write,users.profile:read,users:read,users:read.email,users:write,workflow.steps:execute&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" /></a>
                </div>
                <div className={styles.actions}>
                    <button className={styles.continue} type="submit" >
                        <span>Continue</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SlackConnect;