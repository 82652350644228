import React, {useEffect, useState} from "react";
import styles from './Blog.module.scss'
import BlogItem from "./BlogItem";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getBlogs} from "../../../store/selectors";
import {getAllBlogs, getMoreBlogs} from "../../../api/api";
import {setBlogs, addBlogs} from '../../../store/blogs-slice'
import {useLocation} from "react-router";


const BlogList = () => {
    const [blogsCount, setBlogsCount] = useState(0);
    const blogs = useAppSelector(getBlogs);
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const content = document.getElementById('content');
        if (content) {
            content.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }

    }, [pathname]);

    useEffect(() => {
        getAllBlogs().then((response) => {
            dispatch(setBlogs(response.results));
            setBlogsCount(response.count)
        })
    }, [])

    const loadMore = () => {
        getMoreBlogs(blogs.length).then(r => dispatch(addBlogs(r.results)))
    }

    return (
        <>
            <div className={styles.blogList}>
                {blogs && (
                    blogs.map((blog, index) =>
                        <BlogItem
                            code={blog.code}
                            preview_image={blog.preview_image}
                            title={blog.title}
                            short_description={blog.short_description}
                            created_at={blog.created_at}
                            key={index}/>
                    ))
                }
            </div>
            {blogs.length !== blogsCount && blogs.length !== 0 && (
                <div className={styles.blogList__buttonWrapper}>
                    <button onClick={() => loadMore()} className={styles.blogList__button}>
                        Load more
                    </button>
                </div>
            )}
        </>
    )
}

export default BlogList;