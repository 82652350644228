import React, {useState} from "react";
import styles from './createCompany.module.scss';
import Steps from "./Steps";
import CreateCompanyInfo from "./CreateCompanyInfo/CreateCompanyInfo";
import ConnectSocial from "./ConnectSocial/ConnectSocial";
import ChooseTariff from "./ChooseTariff/ChooseTariff";
import ReviewSlider from "./RewiewSlider/ReviewSlider";

const CreateCompany = () => {
    const [step, setStep] = useState(1)
    return (
        <div className={styles.createCompanyWrapper}>
            <div className={styles.createCompany}>
                <div className={styles.leftSection}>
                    <Steps step={step}/>
                    <div className={styles.content}>
                        {step === 1 && (
                            <CreateCompanyInfo setStep={setStep} />
                        )}
                        {step === 2 && (
                            <ConnectSocial setStep={setStep} />
                        )}
                        {step === 3 && (
                            <ChooseTariff setStep={setStep} />
                        )}
                    </div>
                </div>
                <ReviewSlider />
            </div>
        </div>
    )
}

export default CreateCompany;