import React from "react";
import styles from '../FAQ.module.scss';

const PrivacyPolicy = () => {
    return (
        <div className={styles.privacyPolicy}>
            <h3>Privacy Policy</h3>
            <p>
                Protecting your private information is our priority. This Statement of Privacy applies to Avivi Inc and
                governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all
                references to Avivi Inc. By using the Avivi website, you consent to the data practices described in this
                statement.
            </p>
            <h3>1. Collection of your Personal Information</h3>
            <p>
                In order to better provide you with products and services offered on our Site, Avivi may collect
                personally identifiable information, such as your:<br/>
                First and Last Name;<br/>
                E-mail Address;<br/>
                Contact phone number.
            </p>
            <p>If you purchase Avivi’s Products and Services, we collect billing and credit card information. This
                information is used to complete the purchase transaction.
                Avivi may also collect anonymous demographic information, which is not unique to you, such as your:<br/>
                Age;<br/>
                Gender;<br/>
                Location;<br/>
                Job title.
            </p>
            <p>
                Please keep in mind that if you directly disclose personally identifiable information or personally
                sensitive data through Avivi’s public message boards, this information may be collected and used by
                others.<br/>
                We do not collect any personal information about you unless you voluntarily provide it to us. However,
                you may be required to provide certain personal information to us when you elect to use certain products
                or services available on the Site. These may include:<br/>
                (a) registering for an account on our Site;<br/>
                (b) entering a event or contest sponsored by us or one of our partners;<br/>
                (c) signing up for special offers from selected third parties;<br/>
                (d) sending us an email message;<br/>
                (e) submitting your credit card or other payment information when ordering and purchasing products and
                services on our Site.
            </p>
            <p>
                To wit, we will use your information for, but not limited to, communicating with you in relation to
                services and/or products you have requested from us. We also may gather additional personal or
                non-personal information in the future.
            </p>
            <h3>2. Use of your Personal Information</h3>
            <p>
                Avivi collects and uses your personal information to operate its website(s) and deliver the services you
                have requested.
                Avivi may also use your personally identifiable information to inform you of other products or services
                available from Avivi and its affiliates.
            </p>
        </div>
    )
}

export default PrivacyPolicy;