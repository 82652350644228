import React from "react";
import {useAppSelector} from "../../../../hooks/redux";
import {getPostById} from "../../../../store/selectors";
import ViewPopup from "../../History/ViewPopup";

interface EventViewPopupProps {
    id: number,
    hidePopup: (hidePopup: string) => void
}


const EventViewPopup = ({id, hidePopup} : EventViewPopupProps) => {
    const post = useAppSelector((state) => getPostById(state, id));

    return (
        <>
            {post && (
                <ViewPopup hidePopup={hidePopup} post={post}/>
            )}
        </>
    )
}

export default EventViewPopup;