import React from "react";
import styles from './Review.module.scss'
import {review} from '../../../../../store/main-slice.types'
import image from '../../../../../assets/img/DoubleQuotes.svg'

const Review: React.FC<review> = ({photo, name, date, text}) => {

    return (
        <div className={styles.review}>
            <div className={styles.review__user}>
                <img src={photo} alt=""/>
                <div>
                    <p className={styles.review__name}>{name}</p>
                    <p className={styles.review__date}>{date}</p>
                </div>
            </div>
            <p className={styles.review__text}><img className={styles.image} src={image} alt=""/>{text}</p>
        </div>
    )
}
export default Review;