import React, {useState} from "react";
import styles from './LoginRegister.module.scss';
import {useFormik} from "formik";
import google from '../../assets/img/googleIcon.svg';
import {useNavigate} from "react-router";
import eye from '../../assets/img/eye.svg';
import eyeSlash from '../../assets/img/eye-slash.svg';
import { register } from "../../api/api";
import {useAppDispatch} from "../../hooks/redux";
import { setUser } from '../../store/main-slice';
import {setAllTokens} from "../../api/localStorage";

const SignUp = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setRepeatShowPassword] = useState(false);

    const { handleSubmit, handleChange, values, resetForm } = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            toggle: false,
            repeat: '',
        },

        onSubmit: (values) => {
            if (values.password === values.repeat && values.toggle) {
                setConfirm(true);
                register(values.name, values.email, values.password).then((response) => {
                    if (response && response.access_token) {
                        setAllTokens(response.access_token, response.refresh_token);

                        setTimeout(() => {
                            dispatch(setUser(response.id));
                        }, 200)
                        navigate('/');
                    }
                })

                resetForm();
                // navigate('/create_company');
            }
            if (values.password !== values.repeat && values.toggle) {
                setConfirm(false);
            }

            if (values.password === values.repeat && !values.toggle) {
                alert('read terms & conditions');
            }
        },
    });

    return (
        <form className={styles.authForm} onSubmit={handleSubmit}>
            <h2 className={styles.authForm__title}>Create account</h2>
            <label className={styles.authForm__label} htmlFor={`name`}>Name</label>
            <input
                className={styles.authForm__input}
                id="name"
                name="name"
                type="name"
                onChange={handleChange}
                value={values.name}
                placeholder={'Your name'}
            />
            <label className={styles.authForm__label} htmlFor={`name`}>Email address</label>
            <input
                className={styles.authForm__input}
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                placeholder={'Your email'}
            />
            <label className={styles.authForm__label} htmlFor={`email`}>Password</label>
            <div className={styles.password}>
                <input
                    className={styles.authForm__input}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    value={values.password}
                    placeholder={'Your password'}
                />
                <button type={'button'} className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>
                    <img src={showPassword ? eyeSlash : eye} alt="show"/>
                </button>
            </div>
            <label className={styles.authForm__label} htmlFor={`email`}>Repeat password</label>
            <div className={styles.password}>
            <input
                className={confirm ? styles.authForm__input : styles.authForm__inputError}
                id="repeat"
                name="repeat"
                type={showRepeatPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={values.repeat}
                placeholder={'Repeat your password'}
            />
                <button type={'button'} className={styles.togglePassword} onClick={() => setRepeatShowPassword(!showRepeatPassword)}>
                    <img src={showRepeatPassword ? eyeSlash : eye} alt="show"/>
                </button>
            </div>
            <div className={styles.remember}>
                <div>
                    <input
                        id="toggle"
                        name="toggle"
                        onChange={handleChange}
                        type="checkbox"
                        value={`${values.toggle}`}
                    />
                    <label className={styles.remember__label} htmlFor={`agree`}>
                        I agree to <a className={styles.link} href="#">terms & conditions</a>
                    </label>
                </div>

            </div>
            <div className={styles.send}>
                <button
                    className={styles.send__buttonGoogle}
                    type={'button'}
                >
                    <span className={styles.send__textGoogle}>
                        <img className={styles.send__icon} src={google} alt="google"/>Sign up with Google
                    </span>
                </button>
                <button
                    className={styles.send__buttonLogin}
                    type="submit"
                    disabled={!values.toggle}
                >
                    <span className={styles.send__textRegister}>Register Account</span>
                </button>
            </div>
            <div className={styles.register}>
                <p className={styles.register__text}>
                    Already have an account?
                    <button type={'button'} onClick={() => navigate('/login')} className={styles.register__link}>
                        Log In
                    </button>
                </p>
            </div>
        </form>
    )
}

export default SignUp;