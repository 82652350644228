import React from "react";
import styles from './AdvertisingIcons.module.scss';
import discord from '../../../../assets/img/LogRegIcons/discord.svg'
import facebook from '../../../../assets/img/LogRegIcons/facebook.svg'
import instagram from '../../../../assets/img/LogRegIcons/instagram.svg'
import linkedin from '../../../../assets/img/LogRegIcons/linkedin.svg'
import pinterest from '../../../../assets/img/LogRegIcons/pinterest.svg'
import telegram from '../../../../assets/img/LogRegIcons/telegram.svg'
import tik_tok from '../../../../assets/img/LogRegIcons/tik_tok.svg'
import twitter from '../../../../assets/img/LogRegIcons/twitter.svg'
import youtube from '../../../../assets/img/LogRegIcons/youtube.svg'

const AdvertisingIcons = () => {
    return (
        <div className={styles.AdvertisingIcons}>
            <img src={discord} alt="discord"/>
            <img src={youtube} alt="youtube"/>
            <img src={twitter} alt="twitter"/>
            <img src={instagram} alt="instagram"/>
            <img src={linkedin} alt="linkedin"/>
            <img src={facebook} alt="facebook"/>
            <img src={telegram} alt="telegram"/>
            <img src={tik_tok} alt="tik_tok"/>
            <img src={pinterest} alt="pinterest"/>
        </div>
    )
}

export default AdvertisingIcons;