import {createSlice} from '@reduxjs/toolkit';
import {calendar} from "./calendar.types";
import discord from '../assets/img/socialIcons/discord.jpg';
import telegram from '../assets/img/socialIcons/telegram.jpg';
import inst from '../assets/img/socialIcons/instagram.jpg';
import twitter from '../assets/img/socialIcons/twitter.jpg';

const initialState: calendar = {
    calendar: [
        {
            day: 27,
            current: false,
            date: '2023-11-27',
            posts: [
                {id: 9, socialIcon: twitter, title: 'Some title', status: 'Draft', author: 'Jaylon Vaccaro', time: '19:30'},
            ]
        },
        {
            day: 28,
            current: false,
            date: '2023-11-28',
            posts: []
        },
        {
            day: 29,
            current: true,
            date: '2023-11-29',
            posts: []
        },
        {
            day: 30,
            current: true,
            date: '2023-11-30',
            posts: []
        },
        {
            day: 1,
            current: true,
            date: '2023-12-01',
            posts: []
        },
        {
            day: 2,
            current: true,
            date: '2023-12-02',
            posts: []
        },
        {
            day: 3,
            current: true,
            date: '2023-12-03',
            posts: [
                {id: 1, socialIcon: telegram, title: 'Some title', status: 'Published', author: 'Jaylon Vaccaro', time: '10:00'},
                {id: 2, socialIcon: twitter, title: 'Some title', status: 'Postponed', author: 'Jaylon Vaccaro', time: '12:00'},
                {id: 3, socialIcon: discord, title: 'Some name for the database', status: 'Published', author: 'Jaylon Vaccaro', time: '12:20'},
            ]
        },
        {
            day: 4,
            current: true,
            date: '2023-12-04',
            posts: []
        },
        {
            day: 5,
            current: true,
            date: '2023-12-05',
            posts: []
        },
        {
            day: 6,
            current: true,
            date: '2023-12-06',
            posts: []
        },
        {
            day: 7,
            current: true,
            date: '2023-12-07',
            posts: []
        },
        {
            day: 8,
            current: true,
            date: '2023-12-08',
            posts: []
        },
        {
            day: 9,
            current: true,
            date: '2023-12-09',
            posts: [
                {id: 4, socialIcon: inst, title: 'Some title', status: 'Draft', author: 'Bill Bon', time: '13:30'},
                {id: 5, socialIcon: twitter, title: 'Some name for the database', status: 'Published', author: 'Bill Bon', time: '18:00'},
            ]
        },
        {
            day: 10,
            current: true,
            date: '2023-12-10',
            posts: []
        },
        {
            day: 11,
            current: true,
            date: '2023-12-11',
            posts: []
        },
        {
            day: 12,
            current: true,
            date: '2023-12-12',
            posts: []
        },
        {
            day: 13,
            current: true,
            date: '2023-12-13',
            posts: []
        },
        {
            day: 14,
            current: true,
            date: '2023-12-14',
            posts: []
        },
        {
            day: 15,
            current: true,
            date: '2023-12-15',
            posts: []
        },
        {
            day: 16,
            current: true,
            date: '2023-12-16',
            posts: [
                {id: 8, socialIcon: discord, title: 'Some title', status: 'Published', author: 'Bill Bon', time: '15:30'},
            ]
        },
        {
            day: 17,
            current: true,
            date: '2023-12-17',
            posts: []
        },
        {
            day: 18,
            current: true,
            date: '2023-12-18',
            posts: []
        },
        {
            day: 19,
            current: true,
            date: '2023-12-19',
            posts: []
        },
        {
            day: 20,
            current: true,
            date: '2023-12-20',
            posts: []
        },
        {
            day: 21,
            current: true,
            date: '2023-12-21',
            posts: []
        },
        {
            day: 22,
            current: true,
            date: '2023-12-22',
            posts: []
        },
        {
            day: 23,
            current: true,
            date: '2023-12-23',
            posts: []
        },
        {
            day: 24,
            current: true,
            date: '2023-12-24',
            posts: []
        },
        {
            day: 25,
            current: true,
            date: '2023-12-25',
            posts: []
        },
        {
            day: 26,
            current: true,
            date: '2023-12-26',
            posts: []
        },
        {
            day: 27,
            current: true,
            date: '2023-12-27',
            posts: []
        },
        {
            day: 28,
            current: true,
            date: '2023-12-28',
            posts: []
        },
        {
            day: 29,
            current: true,
            date: '2023-12-29',
            posts: []
        },
        {
            day: 30,
            current: true,
            date: '2023-12-30',
            posts: [
                {id: 10, socialIcon: twitter, title: 'Some title', status: 'Draft', author: 'Jaylon Vaccaro', time: '12:00'},
                {id: 11, socialIcon: twitter, title: 'Some title', status: 'Draft', author: 'Jaylon Vaccaro', time: '16:00'},
            ]
        },
        {
            day: 31,
            current: true,
            date: '2023-12-31',
            posts: []
        },
    ],
    date: 'December 2023',
    dateForPost: '',
};

export const calendarSlice = createSlice({
    name: 'calendarPage',
    initialState,
    reducers: {
        setDateForPost(state, action) {
            state.dateForPost = action.payload;
        },
    },
});

export default calendarSlice.reducer;
export const {
    setDateForPost
} = calendarSlice.actions;
