import React, {useState} from "react";
import styles from './LeftNavBar.module.scss';
import logo from '../../../assets/img/Logo.svg';
import resize from '../../../assets/img/resize.svg';
import LeftBarNavigation from "./LeftBarNavigation/LeftBarNavigation";
import {useAppDispatch} from "../../../hooks/redux";
import {setSize} from '../../../store/main-slice'
import {useNavigate} from "react-router";


const LeftNavBar = () => {
    const [barSize, setBarSize] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const toHome = () => {
        const tab = document.getElementById('home');

        if (tab) {
            tab.click();
            navigate('/')
        }
    }


    const toggleSize = () => {
        setBarSize(!barSize);
        dispatch(setSize(!barSize));
    }

    return (
        <div className={barSize ? `${styles.navBarWrapperSize} navBar` : `${styles.navBarWrapper} navBar`}>
            <div className={barSize ? styles.navBarSize__header : styles.navBar__header}>
                <button className={barSize ? styles.navBarSize__headerLogo : styles.navBar__headerLogo} onClick={() => toHome()}>
                    <img src={logo} alt="logo"/>
                    <p className={barSize ? styles.navBarSize__headerText : styles.navBar__headerText}>
                        Media <br/> Posting
                    </p>
                </button>
                <button onClick={() => toggleSize()}
                        className={barSize ? styles.navBarSize__headerButton : styles.navBar__headerButton}>
                    <img src={resize} alt="resize"/>
                </button>
            </div>
            <LeftBarNavigation size={barSize} />
        </div>
    )
}

export default LeftNavBar
