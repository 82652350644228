import React, {useEffect, useState} from "react";
import styles from "./Profile.module.scss";
import IconEdit from "../../../assets/img/edit.svg";
import IconCheck from "../../../assets/img/check.svg";

interface ContactBlockProps {
    userEmail?: string,
    number?: string,
    setNumber: (value: string) => void,
    saveContactInfo: () => void,
    country?: string,
    setCountry: (value: string) => void
}

const ContactBlock = ({userEmail, number, setNumber, saveContactInfo, country, setCountry}: ContactBlockProps) => {
    const [editMode, setEditMode] = useState(false);

    const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (/^[0-9+\s]*$/.test(value) || value === "") {
            setNumber(value);
        }
    }

    const handleSave = () => {
        setEditMode(false);
        saveContactInfo();
    }

    return (
        <div className={styles.block}>
            <div className={styles.titleRow}>
                <h3>Contact information</h3>
                {editMode ? <button className={styles.saveBtnBlock} onClick={handleSave}>
                        <span>Save</span>
                        <img src={IconCheck} alt=""/>
                    </button> :
                    <button onClick={() => setEditMode(true)}>
                        <img src={IconEdit} alt=""/>
                    </button>}
            </div>
            <h4>Email</h4>
            <p>{userEmail}</p>
            <h4>Phone number</h4>
            {
                editMode ?
                    <input type="text" className={styles.textInput} placeholder="+380"
                           value={number} maxLength={15}
                           onChange={handleChangeNumber}/> :
                    <p>{number}</p>
            }
            <h4>Country</h4>
            {
                editMode ? <input type="text" className={styles.textInput} placeholder=""
                                  value={country} maxLength={15}
                                  onChange={(e) => setCountry(e.target.value)}/> :
                    <p>{country}</p>
            }
        </div>
    )
}

export default ContactBlock;