import React from "react";
import styles from './Blog.module.scss';
import {blog} from "../../../store/blogs-slice.types";
import { Link } from 'react-router-dom';

const BlogItem = ({code, preview_image, title, short_description, created_at}: blog) => {
    return (
        <div className={styles.blog}>
            <Link to={`${code}`} className={styles.blog__link}>
                <img src={preview_image} alt=""/>
            </Link>
            <div className={styles.blog__content}>
                <Link to={`${code}`}>
                    <h4>{title}</h4>
                </Link>

                <p>{short_description}</p>
                <span>Date of publication: {created_at.slice(6, created_at.length)}</span>
            </div>
        </div>
    )
}

export default BlogItem;