import styles from "./History.module.scss";
import emptyPic from "../../../assets/img/emptyPic.png";
import React, {useEffect, useState} from "react";
import {Post} from "../../../store/posts-slice.types";
import ViewPopup from "./ViewPopup";
import PostTableItem from "./PostTableItem";
import Editor from "./Editor";

interface PostTableProps {
    posts: Post[],
    setPosts: (setPosts: Post[]) => void,
}

const PostTable = ({posts, setPosts}: PostTableProps) => {
    const [toggleMenu, setToggleMenu] = useState(0);
    const [post, setPost] = useState<Post | null>(null);
    const [showEditor, setShowEditor] = useState(false);
    const [editPost, setEditPost] = useState<Post | null>(null);

    useEffect(() => {
        post ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
        editPost ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
    }, [post, editPost])

    const showActions = (item: Post) => {
        setToggleMenu(item.id);
    }

    const hideMenu = () => {
        setToggleMenu(0);
    }

    const hidePopup = () => {
        setPost(null);
    }

    return (
        <>
            <table>
                <thead>
                <tr className={styles.post__head}>
                    <th>Actions</th>
                    <th className={styles.post__picture}>Picture</th>
                    <th>Title</th>
                    <th className={styles.post__social}>Social network</th>
                    <th className={styles.post__status}>Status</th>
                    <th className={styles.post__author}>Author</th>
                    <th className={styles.post__date}>Date</th>
                </tr>
                </thead>
                <tbody>
                {posts.map((item, index) =>
                    <PostTableItem
                        showActions={showActions}
                        key={index}
                        item={item}
                        toggleMenu={toggleMenu}
                        setPost={setPost}
                        setShowEditor={setShowEditor}
                        setEditPost={setEditPost}
                        setToggleMenu={setToggleMenu}
                        setPosts={setPosts}
                    />
                    )}
                </tbody>
            </table>
            {post && (
                <ViewPopup hidePopup={hidePopup} post={post}/>
            )}
            {showEditor && editPost && (
                <Editor setShowEditor={setShowEditor} editPost={editPost}/>
            )}
            <button className={toggleMenu === 0 ? styles.post__close : styles.post__closeActive} onClick={hideMenu}/>
        </>

    )
}

export default PostTable;