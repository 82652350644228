import React from "react";
import styles from './TariffsContent.module.scss'
import {useAppSelector} from "../../../../hooks/redux";
import {getTariffs} from "../../../../store/selectors";
import Tariff from "./Tariff/Tariff";


const TariffsContent = () => {

    const tariffs = useAppSelector(getTariffs);

    return (
        <div className={styles.tariffsWrapper}>
            {tariffs && (
                tariffs.map((item, index) => <Tariff
                    key={index}
                    title={item.title}
                    text={item.text}
                    price={item.price}
                    profiles={item.profiles}
                    users={item.users}
                    posts={item.posts}
                    analytics={item.analytics}
                    socialInbox={item.socialInbox}
                    monitoring={item.monitoring}
                />)
            )}
        </div>
    )
}

export default TariffsContent;