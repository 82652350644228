import React, {useEffect, useState} from "react";
import styles from './CompanyInfo.module.scss';
import close from '../../../../assets/img/close.svg'
import fb from '../../../../assets/img/socialIcons/facebook.jpg';
import twitter from '../../../../assets/img/socialIcons/twitter.jpg';
import youtube from '../../../../assets/img/socialIcons/youtube.jpg';
import tik_tok from '../../../../assets/img/socialIcons/tik_tok.jpg';
import reddit from '../../../../assets/img/socialIcons/reddit.jpg';
import pinterest from '../../../../assets/img/socialIcons/pinterest.jpg';
import linkedin from '../../../../assets/img/socialIcons/linkedin.jpg';
import discord from '../../../../assets/img/socialIcons/discord.jpg';
import slack from '../../../../assets/img/socialIcons/slack.svg';
import telegram from '../../../../assets/img/socialIcons/telegram.jpg';
import inst from '../../../../assets/img/socialIcons/instagram.jpg';
import {getAccessToken} from "../../../../api/localStorage";
import {getConnectedNetworks, removeTwitterAccount} from "../../../../api/api";
import {setSocialNetworks} from "../../../../store/main-slice";
import {useAppDispatch} from "../../../../hooks/redux";

interface AccountBoxProps {
    socialName?: string,
    posts?: number | null,
    followers?: number | null,
    accountName?: string,
    accountIcon?: string | null,
    accountId: number,
}

const AccountBox = ({ socialName, posts, followers, accountName, accountIcon, accountId }: AccountBoxProps) => {
    const [icon, setIcon] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        switch (socialName) {
            case 'Facebook' :
                setIcon(fb);
                break;
            case 'Twitter' :
                setIcon(twitter);
                break;
            case 'Discord' :
                setIcon(discord);
                break;
            case 'TikTok' :
                setIcon(tik_tok);
                break;
            case 'LinkedIn' :
                setIcon(linkedin);
                break;
            case 'Telegram' :
                setIcon(telegram);
                break;
            case 'Instagram' :
                setIcon(inst);
                break;
            case 'Reddit' :
                setIcon(reddit);
                break;
            case 'Pinterest' :
                setIcon(pinterest);
                break;
            case 'YouTube' :
                setIcon(youtube);
                break;
            case 'Slack' :
                setIcon(slack);
                break;
            default:
                setIcon('');
                break;
        }
    },[socialName])

    const onRemove = (id: number) => {
        getAccessToken().then((token) => {
            if (token && id) {
                removeTwitterAccount(token, id).then(() => {
                    getConnectedNetworks(token).then((response) => {
                        dispatch(setSocialNetworks(response))
                    })
                })
            }
        })
    }

    return (
        <div className={styles.account}>
            <button className={styles.account__close} onClick={() => onRemove(accountId)}>
                <img src={close} alt=""/>
            </button>
            <div className={styles.account__header}>
                <img src={icon} alt=""/>
                <h5>{socialName}</h5>
            </div>
            <div className={styles.account__info}>
                {accountIcon && (
                    <img src={accountIcon} alt=""/>
                )}
                <h6>{accountName}</h6>
            </div>
            <div className={styles.account__footer}>
                {posts && (
                    <span>{posts} posts</span>
                )}
                {followers && (
                    <span>{followers} followers</span>
                )}
            </div>
        </div>
    )
}

export default AccountBox;