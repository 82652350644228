import React, {useEffect} from "react";
import styles from './Events.module.scss';
import 'swiper/css';
import Event from "./Event";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {getPostBlogs} from "../../../../store/selectors";
import {getAllBlogs} from "../../../../api/api";
import {setBlogs} from "../../../../store/blogs-slice";

const Events = () => {
    const blogs = useAppSelector(getPostBlogs);
    const dispatch = useAppDispatch();

    useEffect(() => {
        getAllBlogs().then((response) => {
            if (response && response.results) {
                dispatch(setBlogs(response.results));
            }
        })
    },[])

    return (
        <div className={styles.eventsWrapper}>
            <div className={styles.eventsHeader}>
                <h3>Be aware of new events</h3>
                <Link className={styles.eventsWrapper__link} to='/blogs/'>See all events</Link>
            </div>
            <div className={styles.eventsWrapper__boxes}>
                {blogs.map((item, index) =>
                    <Event
                        key={index}
                        code={item.code}
                        title={item.title}
                        preview_image={item.preview_image}
                        short_description={item.short_description}
                        created_at={item.created_at}
                    />)}
            </div>
        </div>
    )
}

export default Events;