import React, {useEffect, useState} from "react";
import styles from './HomePage.module.scss'
import Banner from "./HomeBanner/Banner";
import About from "./HomeAboutService/About";
import BePosted from "./HomeBePostedFeatures/BePosted";
import Integrations from "./HomeIntegration/Integration";
import Tariffs from "./HomeTariffs/Tariffs";
import Reviews from "./HomeReviews/Reviews";
import Blog from "./HomeBlog/Blog";
import FeetBack from "./HomeFeedback/Feedback";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import {useLocation} from "react-router";

const HomePage = () => {
    const [value, setValue] = useState('');
    const {hash} = useLocation();

    useEffect(() => {
        if (hash) {
            setValue(hash)
        }
    },[hash])

    if (value && hash) {
        const element = document.querySelector(value);

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }


    return (
        <>
            <Header/>
            <div className={styles.homePageWrapper}>
                <Banner/>
                <About/>
                <BePosted/>
                <Integrations/>
                <Tariffs/>
                <Reviews/>
                <Blog/>
                <FeetBack/>
            </div>
            <Footer/>
        </>

    )
}

export default HomePage;